import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
	Flex,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	useColorModeValue
} from '@chakra-ui/react';
import { Link, useHistory } from 'react-router-dom';
import useAuth from 'auth/useAuth'
import { navigatorSource } from '@utils/navigator'

import { setUserInfo } from 'store/action/userInfos.js'
import { setLocation } from 'store/action/location.js'

import '@/assets/css/navbar.scss'

export default function HeaderLinks(props) {
	const { secondary } = props;
	const {logout} = useAuth();
	const dispatch = useDispatch();
	const userInfo = useSelector((state) => state.userInfos)
  const locationInfo = useSelector((state) => state.locationInfo)

	let menuBg = useColorModeValue('white', 'navy.800');
	const shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
	);
	const history = useHistory();
	const signOut = () => {
		logout().then(() => {
			dispatch(setUserInfo({}))
			window.localStorage.removeItem('isSignIn');
			window.localStorage.removeItem('token');
			window.localStorage.removeItem('userRole');
			window.localStorage.removeItem('account');
			window.localStorage.removeItem('user_item');
			window.localStorage.removeItem('general_phone');
			window.localStorage.removeItem('get_donor_type');
			// window.localStorage.removeItem('account_email');
			console.log(navigatorSource() === 'PC')
			if(navigatorSource() === 'PC') {
				history.push('/polymo/home');
			} else {
				history.push('/polymo/sign-in');
			}
			window.location.reload();
		})
	};
	const whiteList = ['/polymo/sign-in', '/polymo/sign-up'];
	const urlRoute = history.location.pathname;
	
	const onGoSetting = (val) => {
		history.push(`/polymo/setting`, { val: val });
	}
	const onGoPayment = () => {
		history.push(`/polymo/payment`);
	}
	const onGoSmsPayment = () => {
		history.push(`/polymo/sms-payment`);
	}

	const onSelectDonors = (type) => {
		dispatch(setLocation({get_donor_type: type}))
	}
	useEffect(() => {
		// getTeamList();
	}, []);
	
	return (
		<Flex
			alignItems="center"
			flexDirection="row"
			flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
			p="0px"
			borderRadius="5px"
		>
			{
				(!userInfo.email || !window.localStorage.getItem('isSignIn'))
					?
					whiteList.includes(urlRoute) ? null : <div className="operate-in-up">
						<Link className="sign" to="/polymo/sign-in">Sign In</Link>
						<Link className="sign" to="/polymo/sign-up">Sign Up</Link>
					</div>
					: null
			}
			{
				window.localStorage.getItem('isSignIn') && window.localStorage.getItem('token') && userInfo.email ?
				<Menu>
				<MenuButton p="0px">
					<div className="user-name-default">
						{userInfo.first_name ? userInfo.first_name.substr(0, 1) : 'f'}
						{userInfo.last_name ? userInfo.last_name.substr(0, 1) : 'l'}
					</div>
				</MenuButton>
				<MenuList boxShadow={shadow} p="0px" bg={menuBg} border="none" >
					<Flex borderTop="4px solid rgb(252, 163, 17)" flexDirection="column" p="14px 10px 0px 10px" display={{ sm: 'none', lg: 'block' }}>
						<MenuItem onClick={() => onGoSetting('general')} _hover={{ bg: 'rgba(233, 233, 233, 0.5)', color:'rgb(252, 163, 17)' }} _focus={{ bg: 'none' }} px="14px">
							<Text h="31px" lineHeight="31px" fontSize="sm">Change name</Text>
						</MenuItem>
						<MenuItem onClick={() => onGoSetting('change-password')} _hover={{ bg: 'rgba(233, 233, 233, 0.5)', color:'rgb(252, 163, 17)' }} _focus={{ bg: 'none' }} px="14px">
							<Text h="31px" lineHeight="31px" fontSize="sm">Change password</Text>
						</MenuItem>
						<MenuItem onClick={() => onGoSetting('')} _hover={{ bg: 'rgba(233, 233, 233, 0.5)', color:'rgb(252, 163, 17)' }} _focus={{ bg: 'none' }} px="14px">
							<Text h="31px" lineHeight="31px" fontSize="sm">Settings</Text>
						</MenuItem>
						<MenuItem onClick={() => onGoSetting('invitation')} _hover={{ bg: 'rgba(233, 233, 233, 0.5)', color:'rgb(252, 163, 17)' }} _focus={{ bg: 'none' }} px="14px">
							<Text h="31px" lineHeight="31px" fontSize="sm">Invitation</Text>
						</MenuItem>
						{
							userInfo.account_role >= 252 ?
							<MenuItem onClick={onGoPayment} _hover={{ bg: 'rgba(233, 233, 233, 0.5)', color:'rgb(252, 163, 17)' }} _focus={{ bg: 'none' }} px="14px">
								<Text h="31px" lineHeight="31px" fontSize="sm">Payment</Text>
							</MenuItem> : null
						}
						{
							userInfo.account_role >= 252 ?
							<MenuItem onClick={onGoSmsPayment} _hover={{ bg: 'rgba(233, 233, 233, 0.5)', color:'rgb(252, 163, 17)' }} _focus={{ bg: 'none' }} px="14px">
								<Text h="31px" lineHeight="31px" fontSize="sm">Sms payment</Text>
							</MenuItem> : null
						}
					</Flex>
					{/* <Flex borderTop="4px solid rgb(252, 163, 17)" flexDirection="column" p="14px 10px 0px 10px" display={{ sm: 'block', lg: 'none' }}>
						<MenuItem color={(locationInfo.get_donor_type==="all" || !locationInfo.get_donor_type) ? 'rgb(255, 188, 10)' : ''} onClick={() => onSelectDonors('all')} _hover={{ bg: 'rgba(233, 233, 233, 0.5)', color:'rgb(252, 163, 17)' }} _focus={{ bg: 'none' }} px="14px">
							<Text h="31px" lineHeight="31px" fontSize="sm">All donors</Text>
						</MenuItem>
						<MenuItem color={locationInfo.get_donor_type==="only" ? 'rgb(255, 188, 10)' : ''} onClick={() => onSelectDonors('only')} _hover={{ bg: 'rgba(233, 233, 233, 0.5)', color:'rgb(252, 163, 17)' }} _focus={{ bg: 'none' }} px="14px">
							<Text h="31px" lineHeight="31px" fontSize="sm">Uncalled only</Text>
						</MenuItem>
					</Flex> */}
					<Flex>
						<MenuItem
							_hover={{ bg: 'none' }}
							_focus={{ bg: 'none' }}
							borderTop={{ sm: 'none', lg: '1px solid rgb(217, 217, 217)' }}
							mt={{ sm: '0px', lg: '14px' }}
							p="12px 0"
							>
							<Text h="19px" fontFamily="Inter" fontSize="16px" lineHeight="19px" width="100%" textAlign="center" fontSize="sm" onClick={signOut}>Sign out</Text>
						</MenuItem>
					</Flex>
				</MenuList>
			</Menu> : null
			}
		</Flex>
	);
}