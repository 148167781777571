import React, { forwardRef, useEffect, useState } from 'react';
import { Select } from 'antd';
import './index.scss'

const SelectSearch = forwardRef((props, ref) => {
  const {onChange, data, classN, value, propChildren={
    label: 'label',
    value: 'value'
  }} = props;
  const [list, setList] = useState([]);

  const onChangeValue = (value) => {
    onChange(value)
  };
  const onSearchValue = (value) => {
    onChange(value)
  };
  useEffect(() => {
    const datas = [
      // {
      //   label: '',
      //   value: ''
      // }
    ]
    data.forEach((item) => {
      datas.push({
        label: item[propChildren.label],
        value: item[propChildren.value]
      })
    })
    setList(datas);
  }, [])

  const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  return <div className={`polymofund-select-search ${classN ? 'voter' : ''}`}>
    <Select
      value={value}
      className="select-search"
      showSearch
      placeholder="Select"
      optionFilterProp="children"
      onChange={onChangeValue}
      onSearch={onSearchValue}
      filterOption={filterOption}
      options={list}
      // allowClear
    >
    </Select>
  </div>
})

export default SelectSearch