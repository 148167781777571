import React, { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { setUserInfo } from 'store/action/userInfos.js'
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import { Field, Form, Formik } from 'formik';
import { warningMessage } from '@utils/message'
import { navigatorSource } from '@utils/navigator'
import useAuth from '../../../auth/useAuth'
import { getUserList } from 'api/index'

import './index.scss'

function SignIn(props) {
  const { login } = useAuth();
  const dispatch = useDispatch();
  // const myVal = useSelector((state) => state)
  const formRef = React.useRef();

  const validateEmail = (value) => {
    let error
    if (!value) {
      error = 'Email is required'
    } else {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(value)) {
        error = 'Please enter the correct email address'
      }
    }
    return error
  }
  const validatePassword = (value) => {
    let error
    if (!value) {
      error = 'Password is required'
    }
    return error
  }

  const history = useHistory();
  const onCancle = () => {
    if(navigatorSource() === 'PC') {
      history.push('/polymo/home');
    }
  }
  const toSignUp = () => {
    history.push('/polymo/sign-up');
  }
  const [errorMsg, setErrorMsg] = useState('');
  const [errorEmailMsg, setErrorEmailMsg] = useState('');
  const onClickE = () => {
    setErrorEmailMsg('');
  }
  const onClickP = () => {
    setErrorMsg('');
  }
  
  return (
    <Flex className="sign-in-page">
      <div className="sign-in-card">
        <div className="title">Sign In</div>
        <Formik
          initialValues={{ email: '', password: '' }}
          onSubmit={(values, actions) => {
            try {
              setErrorMsg('');
              setErrorEmailMsg('');
              login?.(values.email, values.password)
                .then((res) => {
                  if (res && res.user) {
                    window.localStorage.setItem('token', res.user.accessToken || '');
                    getUserList('/app/fb/user').then((res) => {
                      if(!res.data.email_verified) {
                        warningMessage('Please verify your email first');
                        actions.setSubmitting(false);
                        return;
                      }
                      if (res.data.account_role !== 255) {
                        window.localStorage.removeItem('general_phone');
                        window.localStorage.setItem('isSignIn', '1');
                        window.localStorage.setItem('account', res.data.account_id || '');
                        window.localStorage.setItem('account_email', res.data.email || '');
                        window.localStorage.setItem('userRole', res.data.account_role || '');
                        dispatch(setUserInfo(res.data));
                        if (window.localStorage.getItem('nowUrl')) {
                          history.push(window.localStorage.getItem('nowUrl'));
                          window.localStorage.removeItem('nowUrl');
                          return;
                        }
                        values.email = '';
                        values.password = '';
                        actions.setSubmitting(false);
                        if(navigatorSource() === 'PC') {
                          history.push('/polymo/home');
                        } else {
                          history.push('/polymo/all-teams');
                        }
                      } else {
                        warningMessage('Not app user');
                        actions.setSubmitting(false);
                      }
                    })
                  } else {
                    actions.setSubmitting(false);
                  }
                }).catch((err) => {
                  if (err.code?.split('/')[1] === 'user-not-found') {
                    setErrorEmailMsg('EMAIL NOT FOUND')
                    // message.error('EMAIL_NOT_FOUND', 4);
                  } else if (err.code?.split('/')[1] === 'too-many-requests') {
                    setErrorMsg('MANY ATTEMPTS TRY LATER : Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.')
                    // message.error('MANY_ATTEMPTS_TRY_LATER : Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.', 4);
                  } else if (err.code?.split('/')[1] === 'wrong-password') {
                    // message.error('INVALID_PASSWORD', 4);
                    setErrorMsg('INVALID PASSWORD')
                  }
                  actions.setSubmitting(false);
                })
            } catch (error) {
              actions.setSubmitting(false);
            } finally {
            }
          }}
        >
          {(props) => {
            const {
              values,
              handleSubmit,
            } = props;
            return (<Form onSubmit={handleSubmit}  onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit()
              }
            }} className="sing-in-form">
              <Field name='email' validate={validateEmail}>
                {({ field, form }) => (
                  <FormControl isRequired isInvalid={form.errors.email && form.touched.email}>
                    <FormLabel className="name">Email</FormLabel>
                    <Input onClick={onClickE} className="input-h48" focusBorderColor='#3D5A80' errorBorderColor='#E53E3E' form="novalidateform" {...field} placeholder='Email' />
                    {/* <FormErrorMessage>{form.errors.email}</FormErrorMessage> */}
                    {
                      errorEmailMsg ? <div className="error-text">{errorEmailMsg}</div> : <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                    }
                  </FormControl>
                )}
              </Field>
              <Field className="name" name='password' validate={validatePassword}>
                {({ field, form }) => (
                  <FormControl isRequired isInvalid={form.errors.password && form.touched.password}>
                    <FormLabel className="name">Password</FormLabel>
                    <Input onClick={onClickP} type="password" autoComplete="new-password" focusBorderColor='#3D5A80' errorBorderColor='#E53E3E' className="input-h48" form="novalidateform" {...field} placeholder='Password' />
                    {
                      errorMsg ? <div className="error-text">{errorMsg}</div> : <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                    }
                  </FormControl>
                )}
              </Field>
              <Field>
                {({ field, form }) => (
                  <div className="sign-operate">
                    <Button
                      type="button"
                      _hover={{
                        background: '#fff',
                        border: '2px solid #5BA4FA'
                      }}
                      background="#fff"
                      color="rgb(40, 58, 83)"
                      border="1px solid rgb(40, 58, 83)"
                      borderRadius="6px"
                      w='109px'
                      marginRight="24px"
                      onClick={onCancle}
                      fontFamily="Inter"
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={!form.values.email || !form.values.password || form.isSubmitting}
                      borderRadius="6px"
                      _hover={{
                        background: 'rgb(40, 58, 83);',
                        border: '2px solid #5BA4FA'
                      }}
                      _click={{
                        background: 'rgb(40, 58, 83);',
                      }}
                      background="rgb(40, 58, 83);"
                      color="#fff"
                      isLoading={props.isSubmitting}
                      type='submit'
                      w="109px"
                      fontFamily="Inter"
                    >
                      Sign In
                    </Button>
                  </div>
                )}
              </Field>
            </Form>
            )}}
        </Formik>
        <Flex
          className="forget-password"
          justifyContent='flex-end'
          w='100%'
          mt='0px'>
          <Text display="inline-block" color="#000" fontWeight='400' fontSize='14px'>
            <NavLink to='/polymo/forget-password'>
              Forget Password?
            </NavLink>
          </Text>
        </Flex>
      </div>
      <p className="to-sign-up">
        Don't have an account? <span className="sign-up" onClick={toSignUp}>Sign up</span>
      </p>
    </Flex>
  );
}

export default SignIn;
