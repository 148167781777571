
import React, {useState, useEffect} from "react";

import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';

import CustomTable from "components/table";
import Pagination from "components/pagination";

import Card from "components/card/Card";

import Dialog from "./components/add-or-edit";
import DeleteDialog from "./components/delete";
import DetailDialog from "./components/detail";

import { getMethod, postMethod } from 'api/index';

import {
  columns,
} from "./config";

import {
  Flex,
  Box,
  // Input,
  // Text,
  // Button,
  SimpleGrid
} from "@chakra-ui/react";
export default function Settings() {
  const history = useHistory();
  const userInfo = useSelector((state) => state);

  const [status, setStatus] = useState(true)

  /**
   * list 数据
   */
  const [dataList, setDataList] = React.useState([]);
  
  const [total, setTotal] = useState(0)
  const [query, setVal] = React.useState({
    username: '',
    phone: ''
  });

  const [hasNextPage, setHasNextPage] = useState(false);
  const [nextPageToken, setNextPageToken] = useState('')
  const [pagination, setPage] = React.useState({
    currentPage:1,
    pageSize: 10,
  }); 
  const borderRadius= "8px";
  useEffect(() => {
    getUserList(pagination)
  }, [pagination.currentPage])
  const getMore = () => {
    setStatus(true)
    setPage({
      ...pagination,
      currentPage: pagination.currentPage+1
    })
  }
  const getUserList = (pagination) => {
    let params = {}
    if(hasNextPage) {
      params = {
        page_token: nextPageToken,
        max_results: 20
      }
    } else {
      params = {
        max_results: 20
      }
    }
    // /cms/user/page
    postMethod(`/manage/user/page`, params).then((res) => {
      if(res && res.code=== 200) {
        if(status) {
          const data = dataList.concat(res.data.list)
          setDataList(data);
        } else {
          setDataList(res.data.list)
        }
        // setTotal(res.data.total_count);
        setHasNextPage(res.data.has_next_page);
        setNextPageToken(res.data.next_page_token)
      }
    })
  }
  
  const dialogRef = React.useRef();
  const deleteDialogRef = React.useRef();
  const detailDialogRef = React.useRef();
  const handleInputChange = (e, key) => {
    setVal({
      ...query,
      [key]: e.target.value
    })
  }
  
  const queryList = (searchQuery = {}) => {
    setPage({
      ...pagination,
      ...searchQuery
    })
    // console.log('asdasd', {...pagination, ...searchQuery, ...query})
    
  }
  const emitSelectAll = (list) => {
    // console.log(list)
  }
  
  const sureDelete = (row) => {
    let params = {
      uid: row.uid
    }
    postMethod('/manage/user/delete', params).then((res) => {
      if(res && res.code === 200) {
        getUserList(pagination)
      }
    })
  }

  const onSureChangeStatus = (row) => {
    let params = {
      uid: row.uid
    }
    const url = !row.disabled ? '/manage/user/disable' : '/manage/user/active'
    postMethod(url, params).then((res) => {
      if(res && res.code === 200) {
        getUserList(pagination)
      }
    })
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* <Box textAlign="right" mb="12px">
        <Text h="32px" display="inline-block" lineHeight="32px">aaa</Text><Input w="120px" ml="12px" value={query.username} onChange={(e) => handleInputChange(e, 'username')} />
        <Text h="32px" display="inline-block" lineHeight="32px" ml="12px">phone</Text><Input w="120px" ml="12px" value={query.phone} onChange={(e) => handleInputChange(e, 'phone')} />
        <Button borderRadius={borderRadius} ml="8px" onClick={() => {
          queryList()
        }}>查询</Button>
        <Button borderRadius={borderRadius} ml="8px" onClick={() => {
          setVal({
            username: '',
            phone: ''
          })
        }}>重置</Button>

      </Box> */}
      
        <SimpleGrid
          mb='0px'
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: "20px", xl: "20px" }}>
          <Card
            mt="12px"
            borderRadius={borderRadius}
            direction='column'
            w='100%'
            px='0px'
            p="0px"
            overflowX={{ sm: "scroll", lg: "scroll" }}>
              <CustomTable
                isChecked={false}
                columns={columns({
                  openDialog: (row) => {
                    dialogRef.current.setRow(row);
                  },
                  deleteDialog: (row) => {
                    setHasNextPage(false)
                    setStatus(false)
                    deleteDialogRef.current.delete(row);
                  },
                  detailDialog: (row) => {
                    setHasNextPage(false)
                    setStatus(false)
                    detailDialogRef.current.detail(row);
                  },
                  userInfo
                })}
                data={dataList}
              />
              <Flex mt="32px" mb="32px" justifyContent="center">
                {/* <Pagination data={dataList} total={total} 
                onChange={(pagination) => {
                  // // console.log(pagination)
                  setPage(pagination)
                  getUserList(pagination)
                }}
                ></Pagination> */}
                {
                  hasNextPage ? 
                  <Box w="60px" textAlign="center" cursor="pointer" className="getMore" onClick={getMore}>
                    more
                  </Box>
                  : null
                }
              </Flex>
            </Card>
        </SimpleGrid>
      <Dialog ref={dialogRef} queryList={queryList}></Dialog>
      <DeleteDialog ref={deleteDialogRef} onDelete={sureDelete} ></DeleteDialog>
      <DetailDialog ref={detailDialogRef} onChangeStatus={onSureChangeStatus}></DetailDialog>
    </Box>
  );
}
