/* eslint-disable */
import {
  // Flex,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

// import { useHistory } from "react-router-dom";
// import { useSelector } from 'react-redux';

import React, { forwardRef, useImperativeHandle, useState } from "react";
// import { postMethod } from 'api/index';
import DefaultButton from 'components/button'

const DeleteTeamDialog = forwardRef((props, ref) => {
  // const history = useHistory();
  // const userInfo = useSelector((state) => state)
  const { onDeleteTeam } = props;
  const [visiable, setVisiable] = useState(false);
  const cancelRef = React.useRef();
  const [name, setName] = useState('')
  useImperativeHandle(ref, () => ({
    delete: (val) => {
      setName(val)
      setVisiable(true);
    }
  }))
  const onClose = () => {
    setVisiable(false);
  }
  const onSureDetele = () => {
    // 调取删除接口然后刷新列表
    setVisiable(false);
    onDeleteTeam();
  }

  return (
    <Modal size='xl' isOpen={visiable} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete {name}</ModalHeader>
        <ModalCloseButton _focus={{
          boxShadow: "none",
          border: "none"
        }} />
        <ModalBody>
          <Box>
            Are you sure to delete your team? You can’t undo this action afterwards.
          </Box>
        </ModalBody>
        <ModalFooter>
          {/* <Button ref={cancelRef} onClick={onClose}
            type="button"
            _hover={{
              background: '#EDF2F7',
            }}
            background="#EDF2F7"
            color="#1A202C"
            borderRadius="6px"
            w='109px'
            marginRight="24px">
            Cancel
          </Button> */}
          {/* <Button onClick={onSureDetele} type='submit' 
          borderRadius="6px"
          _hover= {{
            background: '#E53E3E',
          }}
          _click= {{
            background: '#E53E3E',
          }}
          background="#E53E3E"
          color="#fff"
          isLoading={props.isSubmitting}
          >Delete</Button> */}
          <DefaultButton
            onClick={onClose}
            name='Cancel'
            width="109px"
            color="#000"
            background="#EDF2F7"
            marginLeft="12px"
          ></DefaultButton>
          <DefaultButton
            onClick={onSureDetele}
            name='Delete'
            type='submit'
            width="109px"
            background='#E53E3E'
            hoverbackground= "#D71D1D"
            color="#fff"
            marginLeft="12px"
          ></DefaultButton>
        </ModalFooter>
      </ModalContent>
    </Modal>

  );
})

export default DeleteTeamDialog;
