import React from "react";

import {
  Flex,
  Text,
} from "@chakra-ui/react";
export const columns = (props) => {
  const {
    // detailDialog=() => {},
    openDialog=() => {},
    deleteDialog=() => {}
  } = props ?? {}

  return [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Last active date",
      dataIndex: "last_donation",
    },
    {
      title: "Frequency",
      dataIndex: "frequency",
    },
    {
      title: "Total amount",
      dataIndex: "total_donation",
    },
    {
      title: "Race",
      dataIndex: "race",
    },
    {
      title: "Phone number",
      dataIndex: "phone_number",
    },
    {
      title: "Party dem",
      dataIndex: "party_dem",
      render: (val, item, index) => {
        return <span>{val}</span>;
      }
    },
    {
      title: "Party rep",
      dataIndex: "party_rep",
      render: (val, item, index) => {
        return <span>{val}</span>;
      }
    },
    {
      title: "Party una",
      dataIndex: "party_una",
      render: (val, item, index) => {
        return <span>{val}</span>;
      }
    },
    // {
    //   title: "Operation",
    //   render (val, item, index) {
    //     return <>
    //       <Flex mw="180px">
    //         <Text cursor="pointer" onClick={() => detailDialog(item)}  display="inline-block" fontSize='sm' fontWeight='700'>
    //           Detail
    //         </Text>
    //         {
    //           item.deleted ? null :
    //           <Text cursor="pointer" onClick={() => openDialog(item)} display="inline-block" ml="8px" fontSize='sm' fontWeight='700'>
    //             Edit
    //           </Text>
    //         }
    //         <Text cursor="pointer" onClick={() => deleteDialog(item)} display="inline-block" ml="8px" fontSize='sm' fontWeight='700'>
    //           Delete
    //         </Text>
    //       </Flex>
    //     </>
    //   }
    // },
  ];
  
}