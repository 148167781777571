import React, { forwardRef, useImperativeHandle, useState } from "react";
import {
  // Flex,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  InputGroup,
  InputLeftElement,
  PhoneIcon,
  Input,
} from "@chakra-ui/react";
import { useSelector } from 'react-redux';
import { committeeFilterUser, addCampaignTeam } from 'api/team';
import DefaultButton from 'components/button'

import { postMethod } from 'api/index';
import { successMessage } from '@utils/message'
import '../index.scss'

const AccountDeleteDialog = forwardRef((props, ref) => {
  const {onAddTeam} = props
  const userInfo = useSelector((state) => state.userInfos)

  // const { on } = props;
  const [visiable, setVisiable] = useState(false);
  const [visiableAdd, setVisiableAdd] = useState(false);

  const cancelRef = React.useRef();
  // const [rowData, setData] = useState({});
  const [btnStatus, setBtnStatus] = useState(false);
  useImperativeHandle(ref, () => ({
    add: (record) => {
      // setData(record)
      setVisiable(true);
    }
  }))
  const onClose = () => {
    setTeamList([]);
    setToSearch(false);
    setVisiable(false);
  }

  const [searchVal, setSearchVal] = useState('')
  const onDoubleClick= () => {
    let isClick = false;
    let clickNum = 0;
    return function ({ singleClick, doubleClick, params }) {
      // 如果没有绑定双击函数，直接执行单击程序
      if (!doubleClick) {
        return singleClick && singleClick(params);
      }
    
      clickNum++;
      // 毫秒内点击过后阻止执行定时器
      if (isClick) {
        return;
      }
      isClick = true;
      setTimeout(() => {
        // 超过1次都属于双击
        if (clickNum > 1) {
          doubleClick && doubleClick(params);
        } else {
          singleClick && singleClick(params);
        }
        clickNum = 0;
        isClick = false;
      }, 300);
    }
  }

  const onDoubleClickFn = onDoubleClick();
  const onSearch = () => {
    if(!searchVal) {
      return
    }
    committeeFilterUser(`/app/committee/filter/${searchVal}`).then((res) =>{
      if(res && res.code === 200) {
        setTeamList(res.data || []);
        setToSearch(true);
      }
    })
  }
  
  const onSureAdd = () => {
    setBtnStatus(true);
    const params = {
      committee_id: clickItem.committee_sboe_id,
      account_id: window.localStorage.getItem('account') || '',
      uid: userInfo.uid
    }
    addCampaignTeam('/app/team', params).then((res) => {
      if(res && res.code === 200) {
        setBtnStatus(false);
        setVisiableAdd(false);
        setVisiable(false);
        setToSearch(false);
        setTeamList([]);
        onAddTeam();
      }
    })
  }

  // try again 重新查询
  const onTryAgain = () => {
    setToSearch(false)
  }
  // Not Find Committee 
  const onNotFindCommittee = () => {
    let params = {
      committee_name: searchVal,
      email: userInfo.email,
      phone_number: userInfo.phone_number || ''
    }
    postMethod(`/app/committee/lose/${userInfo.uid}`, params).then((res) => {
      if(res && res.code === 200) {
        successMessage('send success')
      }
    })
  }

  const [toSearch, setToSearch] = useState(false); // 是否点击查询
  const [teamlist, setTeamList] = useState([]); // 查询数据list

  const [clickItem, setClickItem] = useState({})
  const onCloseAdd = () => {
    setVisiableAdd(false);
  }
  return (
    <div>
      <Modal size='2xl' isOpen={visiable} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add new team</ModalHeader>
          <ModalCloseButton _focus={{
            boxShadow: "none",
            border: "none"
          }} />
          <ModalBody className="add-team-dialog-body">
            {/* 有数据 */}
            {
              toSearch && teamlist.length ? 
              <div className="search-team-content">
                <div className="search-team-result">
                  result({teamlist.length})
                  {/* <span className="tooltip">Double-click on a commiittee's name to create a campaign team</span> */}
                </div>
                <div className="search-team-list">
                  {teamlist?.map((item, index) => {
                    return <div className="item" key={index} 
                    onClick={
                      () => onDoubleClickFn({
                                singleClick: (e) => {
                                  setClickItem(item)
                                  setVisiableAdd(true);
                                },
                                doubleClick: (e) => {
                                  if(!btnStatus) {
                                    // setClickItem(item)
                                    // onClickItem(e, item)
                                  }
                                },
                                params: 123,
                             })
                      }
                    >{item.name}, <span style={{width: '20px', display:'inline-block'}}></span>{item.committee_sboe_id}</div>
                  })}
                </div>
              </div> 
              : null
            }
            {/* 无数据 */}
            {
              toSearch && !teamlist.length ? 
              <div className="search-team-empty">
                <div className="no-team">Oh no, we can’t find your team. Please <span className="team-empty-btn" onClick={onTryAgain}>try again. </span></div>
                <div>If we still can’t find it, please click <span className="team-empty-btn" onClick={onNotFindCommittee}>can’t not find committee. </span>We will contact you shortly.</div>
              </div> 
              : null
            }
            {/* 未查询 */}
            {
              !toSearch ? 
              <div className="add-team-dialog-content">
              <InputGroup className="input-h48">
                <InputLeftElement pointerEvents='none' children='🔍' />
                <Input onChange={(e) => setSearchVal(e.target.value)} type='tel' placeholder='Search by committee name or SBoE ID' />
              </InputGroup>
              </div> : null
            }
            
          </ModalBody>
          <ModalFooter>
            <DefaultButton
              onClick={onClose}
              name='Cancel'
              width="109px"
              color="#000"
              background="#EDF2F7"
              marginLeft="12px"
            ></DefaultButton>
            {
              !toSearch ? 
              <DefaultButton
                onClick={onSearch}
                name='Search team'
                width="140px"
                marginLeft="12px"
              ></DefaultButton> 
              : null
            }
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal size='xl' isOpen={visiableAdd} onClose={onCloseAdd}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add</ModalHeader>
        <ModalCloseButton _focus={{
          boxShadow: "none",
          border: "none"
        }} />
        <ModalBody>
          <Box>
            Are you sure add "{clickItem.name}"?
          </Box>
        </ModalBody>
        <ModalFooter>
          <DefaultButton
            onClick={onCloseAdd}
            name='Cancel'
            width="109px"
            color="#000"
            background="#EDF2F7"
            marginLeft="12px"
          ></DefaultButton>
          <DefaultButton
            onClick={onSureAdd}
            name='sure'
            width="109px"
            marginLeft="12px"
          ></DefaultButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
    </div>

  );
})

export default AccountDeleteDialog;
