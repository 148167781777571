import React from "react";

import {
  Flex,
  Text,
} from "@chakra-ui/react";
export const columns = (props) => {
  const {
    readMes=() => {},
    openCmsDialog=() => {},
    openEmailDialog=() => {}
  } = props ?? {}

  return [
    {
      title: "First name",
      dataIndex: "first_name",
    },
    {
      title: "Last name",
      dataIndex: "last_name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "Message",
      dataIndex: "message",
    },
    {
      title: "Service plan",
      dataIndex: "service_name",
    },
    {
      title: "Create time",
      dataIndex: "create_time",
    },
    {
      title: "Mark",
      dataIndex: "has_read",
      render (val, item, index) {
        return item.has_read === '1' ? null : <Text cursor="pointer" onClick={() => readMes(item)} display="inline-block" fontSize='sm' fontWeight='700'>
          mark to read
        </Text> 
      }
    },
    {
      title: "Contact",
      render (val, item, index) {
        return <>
          <Flex mw="180px">
            
            <Text cursor="pointer" onClick={() => openEmailDialog(item)}  display="inline-block" ml="8px" fontSize='sm' fontWeight='700'>
              email
            </Text>
            <Text cursor="pointer" onClick={() => openCmsDialog(item)} display="inline-block" ml="8px" fontSize='sm' fontWeight='700'>
              sms
            </Text>
            {/* <Text cursor="pointer" onClick={() => deleteDialog(item)} display="inline-block" ml="8px" fontSize='sm' fontWeight='700'>
              Delete
            </Text> */}
          </Flex>
        </>
      }
    },
  ];
  
}