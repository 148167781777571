import React, { useState, forwardRef } from "react"
import dateJs from 'dayjs'
import { DatePicker, Space } from 'antd';
import './index.scss'


const { RangePicker } = DatePicker;

const CustomDataRange = forwardRef((props, ref) => {
  const { value, onChange, valueType } = props;
  // const [value, setValue] = useState(['2023-01-01', '2023-01-02']);
  const onChangeData = (date, dateString) => {
    if(date?.length) {
      if( valueType === 'unix') {
        onChange([+dateJs(date[0]),+dateJs(date[1])])
      } else if( valueType === 'string') {
        onChange([dateJs(date[0])?.format('YYYY-MM-DD'), dateJs(date[1])?.format('YYYY-MM-DD')])
      }
    } else {
      onChange([])
    }
  };

  const formatDate = () => {
    if(value?.length) {
      return [dateJs(value[0]), dateJs(value[1])]
    }
    return []
  }
  return <div className="polymofund-datarange">
    <RangePicker  onChange={onChangeData} value={formatDate()} />
  </div>
})

export default CustomDataRange