/* eslint-disable */
import {
  Flex,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage
} from "@chakra-ui/react";
import { Field, Form, Formik } from 'formik';

import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import React, { forwardRef, useImperativeHandle, useState } from "react";
import DefaultButton from 'components/button'
import { postMethod } from 'api/index';

const InviteDialog = forwardRef((props, ref) => {
  // const { onDelete } = props;
  const history = useHistory();
  const userInfo = useSelector((state) => state.userInfos);
  const [visiable, setVisiable] = useState(false);
  const cancelRef = React.useRef();
  const [toInvite, setToInvite] = useState(false); // 是否点击查询
  const [inviteForm, setInviteForm] = useState({
    recipient: ''
  })

  const [teamInfo, setTeamInfo] = useState({})
  useImperativeHandle(ref, () => ({
    invite: (info) => {
      setTeamInfo(info)
      // setData()
      setVisiable(true);
    }
  }))

  // const onChangeVal = (e, key) => {
  //   setInviteForm({
  //     ...inviteForm,
  //     [key]: e.target.value
  //   })
  // }
  const onClose = () => {
    setInviteForm({
      ...inviteForm,
      recipient: ''
    })
    setToInvite(false);
    setVisiable(false);
  }
 
  const validateEmail = (value) => {
    let error
    if (!value) {
      error = 'Email is required'
    } else {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(value)) {
        error = 'Please enter the correct email address'
      }
    }
    return error
  }
  return (
    <Modal size='xl' isOpen={visiable} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Invite user</ModalHeader>
        <ModalCloseButton _focus={{
          boxShadow: "none",
          border: "none"
        }} />
        <ModalBody>
          <div>
            {
              toInvite ?
                <div>
                  <div>Invite send.</div>
                  <Flex mt="20px" justifyContent="flex-end">
                    <DefaultButton
                      onClick={onClose}
                      name='Close'
                      width="109px"
                      color="#000"
                      background="#EDF2F7"
                      marginLeft="12px"
                    ></DefaultButton>
                  </Flex>
                </div>
                :
                // <InputGroup className="input-h48">
                //   <InputLeftElement pointerEvents='none' children='🔍' />
                //   <Input focusBorderColor='#3D5A80' value={inviteForm.recipient} onChange={(e) => onChangeVal(e, 'recipient')} type='tel' placeholder='Email' />
                // </InputGroup>
                <Formik
                initialValues={{ recipient: '' }}
                onSubmit={(values, actions) => {
                  try {
                    const params = {
                      ...values,
                      account_name: userInfo.account_name,
                      campaign_team_name: teamInfo.campaign_team_name,
                      uid: userInfo.uid
                    }
                    // 调取邀请接口
                    postMethod('/app/team/invitation', params).then((res) => {
                      if (res && res.code === 200) {
                        setToInvite(true);
                      }
                    })
                    actions.setSubmitting(false)
                  } catch (error) {
                    // console.log(error, '22')
                  } finally {
                  }
  
                }}
              >
                {(props) => (
                  <Form className="sing-in-form">
                    <Field name='recipient' validate={validateEmail}>
                      {({ field, form }) => (
                        <FormControl isRequired isInvalid={form.errors.recipient && form.touched.recipient}>
                          <FormLabel className="name">Email</FormLabel>
                          <Input className="input-h48" focusBorderColor='#3D5A80' errorBorderColor='#E53E3E' form="novalidateform" {...field} placeholder='Email' />
                          <FormErrorMessage>{form.errors.recipient}</FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Flex mt="20px" justifyContent="flex-end">
                      <DefaultButton
                        onClick={onClose}
                        name='Cancel'
                        width="109px"
                        color="#000"
                        background="#EDF2F7"
                        marginLeft="12px"
                      ></DefaultButton>
                      {
                        toInvite ? null :
                        <DefaultButton
                          name='Invite'
                          type='submit'
                          width="109px"
                          marginLeft="12px"
                        ></DefaultButton>
                      }
                    </Flex>
                  </Form>
                )}
              </Formik>
            }
            
          </div>
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
      </ModalContent>
    </Modal>

  );
})

export default InviteDialog;
