/* eslint-disable */
import {
  Flex,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Select,
  Tooltip,
  FormErrorMessage,
} from "@chakra-ui/react";

import React, { forwardRef, useImperativeHandle, useState } from "react";
// import { DatePicker, Select as SelectAntd } from 'antd';
import { QuestionOutlineIcon } from '@chakra-ui/icons'

import { Field, Form, Formik } from 'formik';
import { successMessage } from '@utils/message'

// import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import DefaultButton from 'components/button'

import { getMethod, postMethod } from 'api/index';

const AddActblueDialog = forwardRef((props, ref) => {
  const { onRefresh } = props;
  // const history = useHistory();
  const userInfo = useSelector((state) => state.userInfos)
  const [visiable, setVisiable] = useState(false);
  const [teamInfo, setTeamInfo] = useState({});
  const [actblueInfo, setActblueInfoInfo] = useState({})
  const [type, setTpye] = useState('Add')

  useImperativeHandle(ref, () => ({
    add: (info, actblueInfo, type) => {
      setTeamInfo(info);
      setActblueInfoInfo(actblueInfo);
      setTpye(type);
      setVisiable(true);
    }
  }))

  const onClose = () => {
    setVisiable(false);
  }
  const validateClientUuid = (value) => {
    let error
    if (!value) {
      error = 'Client uuid is required'
    }
    return error
  }
  const validateClientSecret = (value) => {
    let error
    if (!value) {
      error = 'Client secret is required'
    }
    return error
  }
  
  return (
    <Modal size='2xl' isOpen={visiable} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{type} artblue 
          <div className="tooltip-content">
            <QuestionOutlineIcon className="tooltip-icon" ml="12px"  />
            <div className="tooltip-des">
              <div>Please follow the steps below to obtain your client UUID and client secret: </div>
              <div>1. Log in to your ActBlue account.</div>
              <div>2. Navigate to "SETTINGS" on the sidebar on the left and click on "API Credentials".</div>
              <div>3. Click on "Create New Credential" to generate your client UUID and client secret.</div>
            </div>
          </div>
        </ModalHeader>
        <ModalCloseButton _focus={{
          boxShadow: "none",
          border: "none"
        }} />
        <ModalBody>
          <Formik
            initialValues={{ client_uuid: actblueInfo.client_uuid || '', client_secret: actblueInfo.client_secret || '' }}
            onSubmit={(values, actions) => {
              try {
                // console.log(values)
                let params = {
                  ...values,
                  account_id: userInfo.account_id || '',
                  committee_id: teamInfo.committee_sboe_id || '',
                }
                if(type === 'Add') {
                  postMethod('/app/actblue/add', params).then((res) => {
                    if (res && res.code === 200) {
                      successMessage('success');
                      setVisiable(false);
                      onRefresh();
                    }
                  })
                } else if(type === 'Edit') {
                  postMethod('/app/actblue/edit', params).then((res) => {
                    if (res && res.code === 200) {
                      successMessage('success');
                      setVisiable(false);
                      onRefresh();
                    }
                  })
                }
                actions.setSubmitting(false)
              } catch (error) {
                // console.log(error, '22')
              } finally {
              }
            }}
          >
            {(props) => (
              <Form className="form-flex">
                <Flex>
                  <Field name='client_uuid' validate={validateClientUuid}>
                    {({ field, form }) => (
                      <FormControl className="form-control" isRequired isInvalid={form.errors.client_uuid && form.touched.client_uuid} mr="111px">
                        <div className="control">
                          <FormLabel className="label">Client uuid</FormLabel>
                          <Input className="input-h32" focusBorderColor='#3D5A80' errorBorderColor='#E53E3E' form="novalidateform" {...field} placeholder='Client uuid' />
                        </div>
                        <FormErrorMessage className="error-mes">{form.errors.client_uuid}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Flex>
                <Flex>
                  <Field name='client_secret' validate={validateClientSecret}>
                    {({ field, form }) => (
                      <FormControl className="form-control" isRequired isInvalid={form.errors.client_secret && form.touched.client_secret} mr="111px">
                        <div className="control">
                          <FormLabel className="label">Client secret</FormLabel>
                          <Input className="input-h32" focusBorderColor='#3D5A80' errorBorderColor='#E53E3E' form="novalidateform" {...field} placeholder='Client secret' />
                        </div>
                        <FormErrorMessage className="error-mes">{form.errors.client_secret}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Flex>
                
                <Flex justifyContent="flex-end" mt="20px">
                  {
                    <div>
                      <DefaultButton
                        onClick={onClose}
                        type="button"
                        name='Cancel'
                        width="109px"
                        color="#000"
                        background="#EDF2F7"
                        marginLeft="12px"
                      ></DefaultButton>
                      <DefaultButton
                        name='Sure'
                        type='submit'
                        width="109px"
                        marginLeft="12px"
                      ></DefaultButton>
                    </div>
                  }
                </Flex>
              </Form>
            )}
          </Formik>
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
      </ModalContent>
    </Modal>

  );
})

export default AddActblueDialog;
