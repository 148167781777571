import React, { forwardRef, useState, useCallback, useEffect } from "react";
import {
  Box,
  Flex,
  Input,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";

// import CustomSearch from "@/components/inputSearch/index.jsx"
import CustomDataRange from "@/components/dataRange/index.jsx"

import '../index.scss'
const queryParams = {
  donor_name: '',
  data: [],
  date_occured_start: '',
  date_occured_end: '',
}
const DonorDataDialog = forwardRef((props, ref) => {
  const { onSearch } = props;
  
  const [formQuery, setFormQuery] = useState({ ...queryParams })
  const onSearchClear = () => {
    setFormQuery({ ...queryParams });
    onSearch({}, 'clear')
  }
  const onChangeValue = (value, key, type) => {
    setFormQuery({
      ...formQuery,
      [key]: type === 'number' ? Number(value) : value
    })
  }
  
  const onSearchTable = () => {
    onSearch(formQuery)
  }

  return (
    <div>
      <Accordion className="query-content" allowMultiple>
        <AccordionItem>
          <h2>
            <AccordionButton _focus={{
              border: 'none'
            }}>
              <Box as="span" flex='1' textAlign='left'>
                Donor name
                      </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            
            <Input size='small' value={formQuery.donor_name} onChange={(e) => {
              onChangeValue(e.target.value, 'donor_name')
            }} />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton _focus={{
              border: 'none'
            }}>
              <Box as="span" flex='1' textAlign='left'>
                Last active date
                      </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <CustomDataRange
              value={formQuery.data}
              valueType={'string'}
              onChange={(value) => {
                onChangeValue(value, 'data')
              }}
            ></CustomDataRange>
          </AccordionPanel>
        </AccordionItem>
        

      </Accordion>
      <div className="search-operate">
        <span className="search-btn search-btn-clear" onClick={onSearchClear}>Clear all</span>
        <span className="search-btn search-btn-apply" onClick={onSearchTable}>Apply</span>
      </div>
    </div>
  );
})

export default DonorDataDialog;
