import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
// import { createLogger } from 'redux-logger';
import rootReducer from './reducers/index.js';

// const loggerMiddleware = createLogger();
let store = null;
if (process.env.NODE_ENV === 'development') {
  store = (initialState) => {
    return createStore(
      rootReducer,
      initialState,
      applyMiddleware(
        thunkMiddleware,
        // loggerMiddleware
      )
    );
  };
} else {
  store = (initialState) => {
    return createStore(
      rootReducer,
      initialState,
      applyMiddleware(
        thunkMiddleware
      )
    );
  };
}

export default store;