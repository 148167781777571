import React, { useState } from "react";
import { useHistory } from 'react-router-dom';

import DefaultButton from 'components/button'
import './index.scss'


const PolymofundServicePlan = () => {
  const history = useHistory();
  const list = [
    // {
    //   title: 'Basic plan',
    //   content: 'AT',
    //   money: '100',
    //   per: 'Per month',
    //   des: '',
    //   desD: [
    //     '1 campaign team',
    //     '100 SMS/month',
    //     'Extra SMS add on available for purchase'
    //   ],
    //   bill: 'plus 2.9% credit cardprocessing fee'
    // },
    // {
    //   title: 'Plus plan',
    //   content: 'AT',
    //   money: '200',
    //   per: 'Per month',
    //   des: '',
    //   desD: [
    //     '3 campaign teams',
    //     '200 SMS/month',
    //     'Extra SMS add on available for purchase',
    //     'ActBlue integration',
    //     'Automatic disclosure file generation',
    //   ],
    //   bill: 'plus 2.9% credit cardprocessing fee'
    // },
    {
      title: 'Pay As You Go',
      content: 'pay while use',
      money: '',
      per: '',
      des: '',
      desD: [
        '1 campaign team',
        'None free SMS',
        'Extra SMS add on available for purchase',
        // 'ActBlue integration',
        // 'Automatic disclosure file generation',
      ],
      bill: ''
    },
    {
      title: 'Ultimate plan',
      content: 'Rate varies. Please contact us.',
      money: '',
      per: '',
      des: '',
      desD: [
        '20 campaign teams',
        '1000 SMS/month',
        'Extra SMS add on available for purchase',
        'ActBlue integration',
        'Automatic disclosure file generation',
      ],
      bill: 'plus 2.9% credit cardprocessing fee'
    }
  ]

  const smsList = [
    {
      title: '50 Messages',
      content: '',
      money: '10',
      per: '',
      des: 'Never expire',
      desD: [
      ],
      bill: 'plus 2.9% credit card'
    },
    {
      title: '100 Messages',
      content: '',
      money: '16',
      per: '',
      des: 'Never expire',
      desD: [
      ],
      bill: 'plus 2.9% credit card'
    },
  ]

  const onGoServicePlan = () => {
    history.push('/polymo/setting', {val: 'service-plan'});
  }
  const onGoPurchase = () => {
    history.push('/polymo/sms-payment');
  }
  return (
    <div className="polyfund-pricing-page">
      <div className="bg-line"></div>
      <div className="polyfund-pricing-main">
        <div className="polyfund-pricing-content">
          <div className="content-title">
            <div className="name">Polymo<span style={{ color: '#FFBC0A' }}>Fund</span></div>
            <div className="des"></div>
          </div>
          <div className="service-plan-title">
            Service Plan Pricing
          </div>
          <div className="pricing-content">
            {
              list?.map((item, index) => {
                return <div className="contect-item" key={index}>
                  <div className="title">{item.title}</div>
                  <div className="content">
                    <div className="starting-at">{item.content}</div>
                    {/* <div><span className="money">{item.money}</span></div> */}
                    {/* <div className="per">{item.per}</div> */}
                  </div>
                  <div className="des">
                    <div className="des-c">{item.des}</div>
                    <div className="des-d">
                      {
                        item.desD.map((item, index) => {
                          return <div className="item" key={index}>- {item}</div>
                        })
                      }
                    </div>
                  </div>
                  <div className="bill">{item.bill}</div>
                  <div className="go-pricing">
                    <DefaultButton
                      onClick={onGoServicePlan}
                      name='subscribe'
                      width="120px"
                    ></DefaultButton>
                  </div>
                </div>
              })
            }
          </div>
          <div className="service-plan-title sms-title">
            SMS add on pricing
          </div>
          <div className="pricing-content">
            {
              smsList?.map((item, index) => {
                return <div className="contect-item" key={index}>
                  <div className="title">{item.title}</div>
                  <div className="content">
                    <div className="starting-at"></div>
                    <div>$<span className="money">{item.money}</span></div>
                    <div className="per">{item.per}</div>
                  </div>
                  <div className="des-t">
                    <div className="des-c">{item.des}</div>
                  </div>
                  <div className="bill">{item.bill}</div>
                  <div className="go-pricing">
                    <DefaultButton
                      onClick={onGoPurchase}
                      name='purchase'
                      width="120px"
                    ></DefaultButton>
                  </div>
                </div>
              })
            }
          </div>
          {/* <div class="foot-c">
            <div>basic 1 team **plus3team * *</div>
            <div>ultimate 999team **$</div>
          </div> */}
        </div>

      </div>
    </div>
  );
}
export default PolymofundServicePlan
