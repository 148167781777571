import {get, post} from '../utils/request'

// committee 名字模糊搜索
export function committeeFilterUser(url,data) {
  return get({
      url,
      data,
      cancel: true
  })
}

// 新增campaign team
export function addCampaignTeam(url,data) {
  return post({
      url,
      data,
      cancel: true
  })
}
