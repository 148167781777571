import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setUserCmsInfo } from 'store/action/userCmsInfos.js'
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  // useColorModeValue,
} from "@chakra-ui/react";
// import { message } from 'antd';
import { errorMessage } from '@utils/message'
import { Field, Form, Formik } from 'formik';
import useAuth from '../../../auth/useAuth'
import {getUserList} from 'api/index'
import './index.scss'

function SignIn(props) {
  const { login } = useAuth();
  const dispatch = useDispatch();
  // const myVal = useSelector((state) => state)
  // console.log(myVal)
  // // console.log()
  const validateEmail = (value) => {
    let error
    if (!value) {
      error = 'Email is required'
    } else {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(value)) {
        error = 'Please enter the correct email address'
      }
    }
    return error
  }
  const validatePassword = (value) => {
    let error
    if (!value) {
      error = 'Password is required'
    }
    return error
  }

  const history = useHistory();
  const onCancle = () => {
    history.push('/polymo/home');
  }
  const toSignUp = () => {
    history.push('/polymo/sign-up');
  }

  return (
    <Flex className="sign-in-cms-page">
      <div className="sign-in-cms-card">
        <div className="title">Sign In</div>
        <Formik
          initialValues={{ email: '', password: '' }}
          onSubmit={(values, actions) => {
            try {
              login?.(values.email, values.password)
                .then((res) => {
                  if (res && res.user) {
                    window.localStorage.setItem('cmstoken', res.user.accessToken || '');
                    getUserList('/app/fb/user').then((res) => {
                      if(res.data.account_role === 255) {
                        dispatch(setUserCmsInfo(res.data));
                        window.localStorage.setItem('account_cms_email', res.data.email || '');
                        if(window.localStorage.getItem('nowUrl')) {
                          history.push(window.localStorage.getItem('nowUrl'));
                          window.localStorage.removeItem('nowUrl');
                          return;
                        }
                        actions.setSubmitting(false)
                        history.push('/cms/user-management');
                      } else {
                        actions.setSubmitting(false)
                        errorMessage('No permission temporarily');
                      }
                    })
                  } else {
                    actions.setSubmitting(false)
                  }
                }).catch((err) => {
                  // console.log(err)
                  if (err.code?.split('/')[1] === 'user-not-found') {
                    errorMessage('EMAIL NOT FOUND')
                    // message.error('EMAIL_NOT_FOUND', 4);
                  } else if (err.code?.split('/')[1] === 'too-many-requests') {
                    errorMessage('MANY ATTEMPTS TRY LATER : Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.')
                    // message.error('MANY_ATTEMPTS_TRY_LATER : Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.', 4);
                  } else if (err.code?.split('/')[1] === 'wrong-password') {
                    errorMessage('INVALID PASSWORD')
                    // message.error('INVALID_PASSWORD', 4);
                  }
                  actions.setSubmitting(false)
                })
            } catch (error) {
              actions.setSubmitting(false)
            } finally {
            }
          }}
        >
          {(props) => {
            const {
              values,
              handleSubmit,
            } = props;
            return (<Form onSubmit={handleSubmit}  onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit()
              }
            }} className="sing-in-form">
              <Field name='email' validate={validateEmail}>
                {({ field, form }) => (
                  <FormControl isRequired isInvalid={form.errors.email && form.touched.email}>
                    <FormLabel className="name">Email</FormLabel>
                    <Input className="input-h48" focusBorderColor='#3D5A80'  errorBorderColor='#E53E3E' form="novalidateform" {...field} placeholder='Email' />
                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field className="name" name='password' validate={validatePassword}>
                {({ field, form }) => (
                  <FormControl isRequired isInvalid={form.errors.password && form.touched.password}>
                    <FormLabel className="name">Password</FormLabel>
                    <Input type="password" focusBorderColor='#3D5A80'  errorBorderColor='#E53E3E' className="input-h48" form="novalidateform" {...field} placeholder='Password' />
                    <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field>
                {({ field, form }) => (
                  <div className="sign-operate">
                    <Button
                      type="button"
                      _hover={{
                        background: '#fff',
                        border: '2px solid #5BA4FA'
                      }}
                      background="#fff"
                      color="rgb(40, 58, 83)"
                      border="1px solid rgb(40, 58, 83)"
                      borderRadius="6px"
                      w='109px'
                      marginRight="24px"
                      onClick={onCancle}
                      fontFamily="Inter"
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={!form.values.email || !form.values.password || form.isSubmitting}
                      borderRadius="6px"
                      _hover={{
                        background: 'rgb(40, 58, 83);',
                        border: '2px solid #5BA4FA'
                      }}
                      _click={{
                        background: 'rgb(40, 58, 83);',
                      }}
                      background="rgb(40, 58, 83);"
                      color="#fff"
                      isLoading={props.isSubmitting}
                      type='submit'
                      w="109px"
                      fontFamily="Inter"
                    >
                      Sign In
                    </Button>
                  </div>
                )}
              </Field>
            </Form>
            )}}
        </Formik>
      </div>
    </Flex>
  );

}

export default SignIn;
