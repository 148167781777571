import React from "react";

const yesOrNoMap = {
  1: 'YES',
  0: 'NO'
}

export const columns = (props) => {
  return [
    {
      title: "NAME",
      // dataIndex: "name",
      render: (val, item, index) => {
        return <span>{item.name}</span>;
      }
    },
    {
      title: "DONOR ID",
      // dataIndex: "name",
      render: (val, item, index) => {
        return <span>{item.id}</span>;
      }
    },
    {
      title: "LAST ACTIVE DATE",
      dataIndex: "last_donation",
      isSort: true,
      // render: (val, item, index) => {
      //   return <span>{item.last_donation}</span>;
      // }
    },
    {
      title: "FREQUENCY",
      dataIndex: "frequency",
      isSort: true,
    },
    {
      title: "TOTAL AMOUNT",
      dataIndex: "total_donation",
      isSort: true,
    },
    {
      title: "RACE",
      dataIndex: "race",
    },
    {
      title: "PRECIENT",
      dataIndex: "precinct_desc",
    },
    {
      title: "MUNICIPALITY",
      dataIndex: "municipality_desc",
    },
    {
      title: "CONGRESS DIST",
      dataIndex: "cong_dist_abbrv",
    },
    {
      title: "NC HOUSE ABBRV",
      dataIndex: "nc_house_abbrv",
    },
    {
      title: "NC SENATE ABBRV",
      dataIndex: "nc_senate_abbrv",
    },
    {
      title: "PARTY DEM",
      dataIndex: "party_dem",
      isSort: true,
      render: (val, item, index) => {
        return <span>{(val && val !== 1) ? val.toFixed(2): val}</span>;
      }
    },
    {
      title: "PARTY REP",
      dataIndex: "party_rep",
      isSort: true,
      render: (val, item, index) => {
        return <span>{(val && val !== 1) ? val.toFixed(2): val}</span>;
      }
    },
    {
      title: " PARTY UNA",
      dataIndex: "party_una",
      isSort: true,
      render: (val, item, index) => {
        return <span>{(val && val !== 1) ? val.toFixed(2): val}</span>;
      }
    },
    {
      title: "COURT",
      dataIndex: "cat_court",
      isSort: true,
      render: (val, item, index) => {
        return <span>{(val && val !== 1) ? val.toFixed(2): val}</span>;
      }
    },
    {
      title: "EDUCATION",
      dataIndex: "cat_education",
      isSort: true,
      render: (val, item, index) => {
        return <span>{(val && val !== 1) ? val.toFixed(2): val}</span>;
      }
    },
    {
      title: "GOVERNOR",
      dataIndex: "cat_governor",
      isSort: true,
      render: (val, item, index) => {
        return <span>{(val && val !== 1) ? val.toFixed(2): val}</span>;
      }
    },
    {
      title: "MUNICIPAL",
      dataIndex: "cat_municipal",
      isSort: true,
      render: (val, item, index) => {
        return <span>{(val && val !== 1) ? val.toFixed(2): val}</span>;
      }
    },
    {
      title: "NC HOUSE",
      dataIndex: "cat_nc_house",
      isSort: true,
      render: (val, item, index) => {
        return <span>{(val && val !== 1) ? val.toFixed(2): val}</span>;
      }
    },
    {
      title: "CAT OTHER",
      dataIndex: "cat_other",
      isSort: true,
      render: (val, item, index) => {
        return <span>{(val && val !== 1) ? val.toFixed(2): val}</span>;
      }
    },
    {
      title: "SHERIFF",
      dataIndex: "cat_sheriff",
      isSort: true,
      render: (val, item, index) => {
        return <span>{(val && val !== 1) ? val.toFixed(2): val}</span>;
      }
    },
    {
      title: "NC SENATE",
      dataIndex: "cat_nc_senate",
      isSort: true,
      render: (val, item, index) => {
        return <span>{(val && val !== 1) ? val.toFixed(2): val}</span>;
      }
    },
    // {
    //   title: "Polarity",
    //   dataIndex: "Polarity",
    //   render: (val, item, index) => {
    //     let str = '';
    //     if(item.party_dem) {
    //       str = 'dem'
    //     }
    //     if(item.party_rep) {
    //       str = 'rep'
    //     }
    //     if(item.party_una) {
    //       str = 'una'
    //     }
    //     return <span className="tag-item">{str}</span>;
    //   }
    // },
    // {
    //   title: "cat_commissioner",
    //   dataIndex: "cat_commissioner",
    //   // render: (val, item, index) => {
    //   //   return <span className="tag-item">{yesOrNoMap[val]}</span>;
    //   // }
    // },
    // {
    //   title: "Category 2",
    //   dataIndex: "Polarity",
    //   render: (val, item, index) => {
    //     return <span className="tag-item">{yesOrNoMap[val]}</span>;
    //   }
    // },
    // {
    //   title: "Category 3",
    //   dataIndex: "Polarity",
    //   render: (val, item, index) => {
    //     return <span className="tag-item">{yesOrNoMap[val]}</span>;
    //   }
    // },
    
  ];
  
}