
import React from "react";
import {
  Button,
} from "@chakra-ui/react";

export default function defaultButton(props) {
  const {
    border, 
    color, 
    fontSize, 
    borderRadius, 
    colorScheme, 
    fontWeight, 
    marginLeft, 
    width, 
    height, 
    variant, 
    name, 
    disabled,
    background, 
    hoverbackground, 
    type,
    className,
    onClick,
  } = props;
  const radius= "6px";
  const cs="blue";
  const w = "109px";
  const h = "40px"
  const v = "solid"
  const bg = "rgb(40, 58, 83);"
  const d = false;
  const t = 'button';
  const fw = '600';
  const ml = '0px';
  const fz = "16px"
  const c = '#fff';
  const b = "1px solid trans";
  const cn= "polymo-btn"
  const onClickBtn = () => {
    if(onClick) {
      onClick()
    }
  }
  return (
    <Button
      cursor="pointer"
      disabled={disabled ? disabled : d}
      width={width ? width : w}
      height={height ? height: h}
      colorScheme={colorScheme ? colorScheme : cs}
      variant={variant ? variant : v}
      borderRadius={borderRadius? borderRadius : radius}
      _hover={{
        background: hoverbackground ? hoverbackground : background ? background : bg,
        border: !hoverbackground ? '2px solid #5BA4FA' : ''
      }}
      _click={{
        background: background ? background : bg,
      }}
      _active={{
        background: background ? background : bg,
      }}
      background={background ? background : bg}
      color={color ? color : c}
      fontFamily="Inter"
      type={type ? type : t}
      fontWeight={fontWeight ? fontWeight : fw}
      onClick={onClickBtn}
      fontSize={fontSize ? fontSize : fz}
      ml={marginLeft ? marginLeft : ml}
      border={border ? border : ''}
      className={className ? className : cn}
    >
      {name}
    </Button>
  );
}
