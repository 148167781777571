import React, {useState} from "react";
import './index.scss'
import phone from 'assets/img/contact-us/phone.svg'
import email from 'assets/img/contact-us/email.svg'


const PolymofundContactUs = () => {
  const list = [
    // {
    //   pic: phone,
    //   title: 'Call us at',
    //   method: '(205)-999-9999'
    // },
    {
      pic: email,
      title: 'Email us at',
      method: 'info@polymoai.com'
    }
  ]
  return (
    <div className="polyfund-contact-us-page">
      <div className="bg-line"></div>
      <div className="polyfund-contact-us-main">
        <div className="polyfund-contact-us-content">
          <div className="content-title">
            <span className="name">Contact Polymo<span className="text">AI</span></span>
          </div>
          <div className="contact-us-content">
            {
              list?.map((item, index) => {
                return <div className="contect-item" key={index}>
                  <p className="pic"><img src={item.pic} alt=""/></p>
                  <p className="title">{item.title}</p>
                  <p className="method">{item.method}</p>
                </div>
              })
            }
            
          </div>
        </div>

      </div>
    </div>
  );
}
export default PolymofundContactUs
