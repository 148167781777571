// import React from "react";

export const columns = (props) => {
  return [
    {
      title: "DONOR NAME",
      dataIndex: "donor_name",
    },
    {
      title: "AMOUNT",
      dataIndex: "amount",
    },
    {
      title: "LAST ACTIVE DATE",
      dataIndex: "date_occured",
    },
    {
      title: "FROM OF PAYMENT",
      dataIndex: "form_of_payment",
    },
    {
      title: "CITY",
      dataIndex: "city",
    },
    {
      title: "STATE",
      dataIndex: "state",
    },
    {
      title: "ZIP CODE",
      dataIndex: "zip_code",
    },
    {
      title: "SOURCE",
      dataIndex: "source",
    },
  ];
  
}
