import * as type from '../type.js';
const initState = {};

const locationInfos = (state = initState, action = {}) => {
    if(action.type === type.LOCATION) {
        if(Object.keys(action.infos).length) {
            return Object.assign({}, state, action.infos);
        } else {
            return Object.assign({}, action.infos);
        }
    } 
    return state
};
export default locationInfos;