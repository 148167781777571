import { combineReducers } from 'redux';
import userInfos from './userInfos.js';
import userCmsInfos from './userCmsInfos.js'
import fetchLoading from './loading.js'
import locationInfo from './location.js'


const reducers = combineReducers({
    userInfos,
    userCmsInfos,
    fetchLoading,
    locationInfo
});

export default reducers;