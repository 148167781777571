/* eslint-disable */
import {
  // Flex,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import DefaultButton from 'components/button'

const ChangeAuthDialog = forwardRef((props, ref) => {
  const { onChange } = props;
  const [visiable, setVisiable] = useState(false);
  const cancelRef = React.useRef();
  const [rowData, setData] = useState({})
  const [auth, setAuth] = useState({})

  useImperativeHandle(ref, () => ({
    change: (record, value) => {
      setData(record)
      setAuth(value)
      setVisiable(true);
    }
  }))
  const onClose = () => {
    setVisiable(false);
  }
  const onSure = () => {
    setVisiable(false);
    // 调取删除接口然后刷新列表
    onChange(rowData, auth);
  }

  return (
    <Modal size='xl' isOpen={visiable} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Change user role</ModalHeader>
        <ModalCloseButton _focus={{
          boxShadow: "none",
          border: "none"
        }} />
        <ModalBody>
          <Box>
          Are you sure to change user role? 
          </Box>
        </ModalBody>
        <ModalFooter>
          {/* <Button ref={cancelRef} onClick={onClose}
            type="button"
            _hover={{
              background: '#EDF2F7',
            }}
            background="#EDF2F7"
            color="#1A202C"
            borderRadius="6px"
            w='109px'
            marginRight="24px"
            fontFamily="Inter"
          >
            Cancel
          </Button> */}
          {/* <Button onClick={onSure} type='submit' 
            borderRadius="6px"
            _hover= {{
              background: 'rgb(40, 58, 83);',
            }}
            _click= {{
              background: 'rgb(40, 58, 83);',
            }}
            background="rgb(40, 58, 83);"
            color="#fff"
            isLoading={props.isSubmitting}
            w='109px' 
            fontFamily="Inter"
          >Confirm</Button> */}
          <DefaultButton
            onClick={onClose}
            name='Cancel'
            width="109px"
            color="#000"
            background="#EDF2F7"
            marginLeft="12px"
          ></DefaultButton>
          <DefaultButton
            onClick={onSure}
            name='Confirm'
            type='submit'
            width="109px"
            marginLeft="12px"
          ></DefaultButton>
        </ModalFooter>
      </ModalContent>
    </Modal>

  );
})

export default ChangeAuthDialog;
