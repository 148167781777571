// chakra imports
import { Box, Flex, Stack, Accordion } from "@chakra-ui/react";
//   Custom components
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";
// import SidebarCard from "components/sidebar/components/SidebarCard";
import React from "react";

// FUNCTIONS

function SidebarContent(props) {
  const { routes } = props;
  // SIDEBAR
  return (
    <Box textAlign="center" w="100%">
      {
        props.title ? 
        <Box h="60px" textAlign="center" w="100%" lineHeight="60px" fontWeight="600" fontSize="24px">{props.title || ''}</Box> : null
      }
      <Flex direction='column' pr="16px"  borderRadius='30px'>
        <Brand />
            <Accordion allowMultiple>
              <Links routes={routes} />
            </Accordion>
        {/* <Stack direction='column' mb='auto' mt='8px'>
          <Box ps='0px' pe={{ md: "16px", "2xl": "1px" }}>
          </Box>
        </Stack> */}

        {/* <Box
          mt='60px'
          mb='40px'
          borderRadius='30px'>
          <SidebarCard />
        </Box> */}
      </Flex>

    </Box>
  );
}

export default SidebarContent;
