import React, { forwardRef, useImperativeHandle, useState } from "react";
/* eslint-disable */
import {
  Flex,
  // Text,
  // Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Select,
  FormErrorMessage
} from "@chakra-ui/react";
// import { useFormik } from 'formik';
// import * as Yup from 'yup'
import { Field, Form, Formik } from 'formik';

import { getMethod, postMethod } from 'api/index';

const DevelopmentDialog = forwardRef((props, ref) => {
  const { onRefresh } = props;
  const [visiable, setVisiable] = useState(false);
  const cancelRef = React.useRef();
  const [rowData, setData] = useState({})

  useImperativeHandle(ref, () => ({
    setRow: (record) => {
      setData(record)
      setVisiable(true);
    }
  }))

  // 关闭弹框
  const onClose = () => {
    setVisiable(false);
  }
  const validatePhone = (value) => {
    let error
    if (!value) {
      error = 'Phone is required'
    }
    return error
  }
  const validateContent = (value) => {
    let error
    if (!value) {
      error = 'sms content is required'
    } else if(value.length > 50 || value.length < 5) {
      error = 'sms content length must between 5, 50'
    }
    return error
  }
  
  return (
    <Modal size='xl' isOpen={visiable} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Send Message</ModalHeader>
        <ModalCloseButton _focus={{
          boxShadow: "none",
          border: "none"
        }} />
        <ModalBody>
          <Formik
            initialValues={{ phone: rowData.phone || '', content: '' }}
            onSubmit={(values, actions) => {
              try {
                let params = {
                  to: values.phone,
                  body: values.content,
                }
                postMethod('/manage/subscription/resp/message', params).then((res) => {
                  if (res && res.code === 200) {
                    setVisiable(false);
                    onRefresh()
                  }
                })
                actions.setSubmitting(false)
              } catch (error) {
                // console.log(error, '22')
              } finally {
              }

            }}
          >
            {(props) => (
              <Form className="sing-in-form">
                <Field name='phone' validate={validatePhone}>
                  {({ field, form }) => (
                    <FormControl isRequired isInvalid={form.errors.phone && form.touched.phone}>
                      <FormLabel className="name">Phone</FormLabel>
                      <Input disabled className="input-h48" focusBorderColor='#3D5A80' errorBorderColor='#E53E3E' form="novalidateform" {...field} placeholder='Phone' />
                      <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="content" validate={validateContent} mt="10px">
                  {({ field, form }) => (
                    <FormControl isRequired isInvalid={form.errors.content && form.touched.content}>
                      <FormLabel className="name">Content</FormLabel>
                      <Input className="input-h48" focusBorderColor='#3D5A80' errorBorderColor='#E53E3E' form="novalidateform" {...field} placeholder='Content' />
                      <FormErrorMessage>{form.errors.content}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                
                <Flex mt="20px" justifyContent="flex-end" className="sign-operate">
                  <Button
                    type="button"
                    _hover={{
                      background: '#fff',
                      border: '2px solid #5BA4FA'
                    }}
                    background="#fff"
                    color="rgb(40, 58, 83)"
                    border="1px solid rgb(40, 58, 83)"
                    borderRadius="6px"
                    isLoading={props.isSubmitting}
                    w='109px'
                    marginRight="24px"
                    onClick={onClose}
                    fontFamily="Inter"
                  >
                    Cancel
                  </Button>
                  <Button
                    borderRadius="6px"
                    _hover={{
                      background: 'rgb(40, 58, 83);',
                      border: '2px solid #5BA4FA'
                    }}
                    _click={{
                      background: 'rgb(40, 58, 83);',
                    }}
                    background="rgb(40, 58, 83);"
                    color="#fff"
                    isLoading={props.isSubmitting}
                    type='submit'
                    w='109px'
                    fontFamily="Inter"
                  >
                    sure
                  </Button>
                </Flex>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
})

export default DevelopmentDialog;
