/* eslint-disable */
import {
  Flex,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  FormErrorMessage,
} from "@chakra-ui/react";

import React, { forwardRef, useImperativeHandle, useState } from "react";
// import { DatePicker, Select as SelectAntd } from 'antd';
import { QuestionOutlineIcon } from '@chakra-ui/icons'

import { Field, Form, Formik } from 'formik';
import { successMessage } from '@utils/message'

// import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import DefaultButton from 'components/button'

import { getMethod, postMethod } from 'api/index';

const AddMessageDialog = forwardRef((props, ref) => {
  const { onRefresh } = props;
  // const history = useHistory();
  const userInfo = useSelector((state) => state.userInfos)
  const [visiable, setVisiable] = useState(false);
  const [teamInfo, setTeamInfo] = useState({});
  const [messageInfo, setMessageInfo] = useState({})
  const [type, setTpye] = useState('Add')

  useImperativeHandle(ref, () => ({
    add: (info, type, mesInfo) => {
      setTeamInfo(info);
      setMessageInfo(mesInfo);
      setTpye(type);
      setVisiable(true);
    }
  }))

  const onClose = () => {
    setVisiable(false);
  }
  const validateTemplateName = (value) => {
    let error
    if (!value) {
      error = 'Template name is required'
    }
    return error
  }
  const validateTemplate = (value) => {
    let error
    if (!value) {
      error = 'Template content is required'
    } else if(value.length > 50 || value.length < 5) {
      error = 'Template content length must between 5, 50'
    } else if(value.length > 500) {
      error = 'Max length is 500'
    }
    return error
  }
  
  return (
    <Modal size='2xl' isOpen={visiable} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{type} template 

          <div className="tooltip-content">
            <QuestionOutlineIcon className="tooltip-icon" ml="12px"  />
            <div className="tooltip-des">
              <div>Place {'"{name}"'} in the desired position of the template,and it will be automatically replaced with the recipient's first name when sending a text message.</div>
            </div>
          </div>
        </ModalHeader>
        <ModalCloseButton _focus={{
          boxShadow: "none",
          border: "none"
        }} />
        <ModalBody>
          <Formik
            initialValues={{ sms_template_content: messageInfo?.sms_template_content || '', sms_template_name: messageInfo?.sms_template_name || '' }}
            onSubmit={(values, actions) => {
              try {
                let params = {
                  ...values,
                  account_id: userInfo.account_id || '',
                  campaign_team_id: teamInfo.campaign_team_id || '',
                }
                if(type === 'Add') {
                  postMethod('/app/sms/plan/template/add', params).then((res) => {
                    if (res && res.code === 200) {
                      successMessage('success');
                      setVisiable(false);
                      onRefresh();
                    }
                  })
                } else if(type === 'Edit') {
                  postMethod(`/app/sms/plan/template/edit/${messageInfo?.id}`, params).then((res) => {
                    if (res && res.code === 200) {
                      successMessage('success');
                      setVisiable(false);
                      onRefresh();
                    }
                  })
                }
                actions.setSubmitting(false)
              } catch (error) {
                // console.log(error, '22')
              } finally {
              }
            }}
          >
            {(props) => (
              <Form className="form-flex">
                <Flex>
                  <Field name='sms_template_name' validate={validateTemplateName}>
                    {({ field, form }) => (
                      <FormControl className="form-control" isRequired isInvalid={form.errors.sms_template_name && form.touched.sms_template_name} mr="111px">
                        <div className="control">
                          <FormLabel className="label">Name</FormLabel>
                          <Input className="input-h32" focusBorderColor='#3D5A80' errorBorderColor='#E53E3E' form="novalidateform" {...field} placeholder='Template name' />
                        </div>
                        <FormErrorMessage className="error-mes">{form.errors.sms_template_name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Flex>
                <Flex>
                  <Field name='sms_template_content' validate={validateTemplate}>
                    {({ field, form }) => (
                      <FormControl className="form-control" isRequired isInvalid={form.errors.sms_template_content && form.touched.sms_template_content} mr="111px">
                        <div className="control">
                          <FormLabel className="label">Content</FormLabel>
                          <Textarea focusBorderColor='#3D5A80' errorBorderColor='#E53E3E' form="novalidateform" {...field} placeholder='Template content' />
                        </div>
                        <FormErrorMessage className="error-mes">{form.errors.sms_template_content}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Flex>
                
                <Flex justifyContent="flex-end" mt="20px">
                  {
                    <div>
                      <DefaultButton
                        onClick={onClose}
                        type="button"
                        name='Cancel'
                        width="109px"
                        color="#000"
                        background="#EDF2F7"
                        marginLeft="12px"
                      ></DefaultButton>
                      <DefaultButton
                        name='Sure'
                        type='submit'
                        width="109px"
                        marginLeft="12px"
                      ></DefaultButton>
                    </div>
                  }
                </Flex>
              </Form>
            )}
          </Formik>
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
      </ModalContent>
    </Modal>

  );
})

export default AddMessageDialog;
