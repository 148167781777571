import React from "react";

import {
  Flex,
  Text,
} from "@chakra-ui/react";

const map = {
  true: 'shi',
  false: 'fou'
}
export const columns = (props) => {
  const {
    detailDialog = () => { },
    openDialog = () => { },
    deleteDialog = () => { },
    userInfo
  } = props ?? {}
  return [
    {
      title: "E-mail",
      dataIndex: "email",
    },
    {
      title: "Display_Name",
      dataIndex: "display_name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },

    {
      title: "Phone_Number",
      dataIndex: "phone_number",
    },
    {
      title: "Registration_Time",
      dataIndex: "creation_time",
    },
    {
      title: "Operation",
      render(val, item, index) {
        return <>
          <Flex mw="180px">
            {
              item.disabled 
                ?
                <Text cursor="pointer" onClick={() => detailDialog(item)} display="inline-block" fontSize='sm' fontWeight='700'>
                  active
                </Text>
                :
                <Text cursor="pointer" onClick={() => detailDialog(item)} display="inline-block" fontSize='sm' fontWeight='700'>
                  disable
                </Text>
            }

            {
              userInfo.userCmsInfos.email === item.email ? null : 
              <Text cursor="pointer" onClick={() => deleteDialog(item)} display="inline-block" ml="8px" fontSize='sm' fontWeight='700'>
                Delete
              </Text>
            }
          </Flex>
        </>
      }
    },
  ];

}