
export const columnsDetail = (props) => {
  const {
    teamInfo
  } = props ?? {}
  return [
    {
      title: "Donated to",
      dataIndex: "committee_name",
      render: (val, item, index) => {
        if(teamInfo.committee_sboe_id !== item.committee_sboe_id) {
          return <>
            <span>{val}</span>
          </>
        } else {
          if(item.committee_party === 'DEM') {
            return <>
              <span style={{color:'#5096FF'}}>{val}</span>
            </>
          } else if(item.committee_party === 'REP') {
            return <>
              <span style={{color:'#FF7D85'}}>{val}</span>
            </>
          } else if(item.committee_party === 'UNA') {
            return <>
              <span style={{color:'#9B8BFF'}}>{val}</span>
            </>
          }
        }
      }
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (val, item, index) => {
        if(teamInfo.committee_sboe_id !== item.committee_sboe_id) {
          return <>
            <span>{parseInt(val)}</span>
          </>
        } else {
          if(item.committee_party === 'DEM') {
            return <>
              <span style={{color:'#5096FF'}}>{parseInt(val)}</span>
            </>
          } else if(item.committee_party === 'REP') {
            return <>
              <span style={{color:'#FF7D85'}}>{parseInt(val)}</span>
            </>
          } else if(item.committee_party === 'UNA') {
            return <>
              <span style={{color:'#9B8BFF'}}>{parseInt(val)}</span>
            </>
          }
        }
      }
    },
    {
      title: "Party",
      dataIndex: "committee_party",
      render: (val, item, index) => {
        if(teamInfo.committee_sboe_id !== item.committee_sboe_id) {
          return <>
            <span>{val}</span>
          </>
        } else {
          if(item.committee_party === 'DEM') {
            return <>
              <span style={{color:'#5096FF'}}>{val}</span>
            </>
          } else if(item.committee_party === 'REP') {
            return <>
              <span style={{color:'#FF7D85'}}>{val}</span>
            </>
          } else if(item.committee_party === 'UNA') {
            return <>
              <span style={{color:'#9B8BFF'}}>{val}</span>
            </>
          }
        }
      }
    },
    {
      title: "Date",
      dataIndex: "date_occured",
      render: (val, item, index) => {
        if(teamInfo.committee_sboe_id !== item.committee_sboe_id) {
          return <>
            <span>{val}</span>
          </>
        } else {
          if(item.committee_party === 'DEM') {
            return <>
              <span style={{color:'#5096FF'}}>{val}</span>
            </>
          } else if(item.committee_party === 'REP') {
            return <>
              <span style={{color:'#FF7D85'}}>{val}</span>
            </>
          } else if(item.committee_party === 'UNA') {
            return <>
              <span style={{color:'#9B8BFF'}}>{val}</span>
            </>
          }
        }
      }
    },
  ]
}