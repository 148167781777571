/* eslint-disable */
import {
  Flex,
  Text,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup'

const DevelopmentDialog = forwardRef((props, ref) => {
  const {queryList} = props;
  const [visiable, setVisiable] = useState(false);
  const cancelRef = React.useRef();
  const [rowData, setData] = useState({})
  useImperativeHandle(ref, () => ({
    setRow: (record) => {
      setData(record)
      setVisiable(true);
      resetForm();
      if(rowData.id) {
        formik.setFieldValue('campaignTeam', 111);
      }
    }
  }))
  // 关闭弹框
  const onClose = () => {
    setVisiable(false);
  }
  const formik = useFormik({
    initialValues: { campaignTeam: '', account: '' },
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: Yup.object({
      campaignTeam: Yup.string().required('Campaign Team is Required'),
      account: Yup.string().required('Account is Required'),
    }),
    onSubmit: values => {
      // console.log(values);
      setVisiable(false);
      queryList();
    },
  });
  const resetForm = () => {
    formik.setFieldValue('campaignTeam', '');
    formik.setFieldValue('account', '');
  }
  const { setFieldTouched, setFieldValue } = formik;
  const handleNameChange = e => {
    setFieldValue('campaignTeam', e.target.value);
    setFieldTouched('campaignTeam');
    formik.validateForm();
  };
  const handleAccountChange = e => {
    setFieldValue('account', e.target.value);
    setFieldTouched('account');
    formik.validateForm();
  };
  return (
    <Modal size='xl'  isOpen={visiable}  onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{rowData.id ? '编辑' : '新增'}</ModalHeader>
        <ModalCloseButton _focus={{
          boxShadow: "none",
          border: "none"
        }} />
        <ModalBody>
          <form onSubmit={formik.handleSubmit}>
            <FormControl isRequired>
              <FormLabel>Campaign Team</FormLabel>
              <Input form="novalidateform" name="campaignTeam" value={formik.values.campaignTeam} onChange={handleNameChange} />
              <Box color="red.500">{formik.touched.campaignTeam && formik.errors.campaignTeam ? (
                <Text>{formik.errors.campaignTeam}</Text>
              ) : null}</Box>
            </FormControl>
            
            <FormControl isRequired>
              <FormLabel>Account</FormLabel>
              <Input form="novalidateform" name="account" value={formik.values.account} onChange={handleAccountChange} />
              <Box color="red.500">
                {formik.touched.account && formik.errors.account ? (
                  <Text>{formik.errors.account}</Text>
                ) : null}
              </Box>
              </FormControl>
            <Flex justifyContent="right">
              <Button ref={cancelRef} onClick={onClose} colorScheme='blue' mt={4} mr={3}>
                Close
              </Button>
              <Button
                mt={4}
                colorScheme='teal'
                isLoading={props.isSubmitting}
                type='submit'
              >
                Submit
              </Button>

            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
})

export default DevelopmentDialog;
