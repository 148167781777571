import React, { useState, useEffect, createRef, useRef } from "react";
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ArrowLeftIcon, HamburgerIcon, PhoneIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import { Switch } from 'antd';
// import { advancedSliding } from '@gystt/ysutils';

import HistoryDialog from "./components/history";
import NoteDialog from "./components/note";
import SendSmsDialog from "./components/sms";

// import Query from './components/query'
import DefaultButton from 'components/button'
import { navigatorSource } from '@utils/navigator'
import { postMethod } from 'api/index';
import './index.scss'

const PolymofundSwitchMobile = () => {
  const history = useHistory();
  const userInfo = useSelector((state) => state.userInfos);
  // const locationInfo = useSelector((state) => state.locationInfo)
  const historyDialogRef = React.useRef();
  const noteDialogRef = React.useRef();
  const smsDialogRef = React.useRef();
  const [visiable, setVisiable] = useState(false);

  // const donorTableRef = React.useRef();
  const [teamInfo, setTeamInfo] = useState({})
  // const [page, setPage] = React.useState({
  //   currentPage: 1,
  //   pageSize: 1
  // })
  const shadow = useColorModeValue(
    '2px 2px 2px 1px rgba(40, 58, 83, .2)',
    '2px 2px 2px 1px rgba(40, 58, 83, .1)'
  );

  const newPage = useRef(
    {
      currentPage: 1,
      pageSize: 1
    }
  )
  const [donorDetail, setDonorDetail] = useState({});
  const [source, setSource] = useState(false);
  const [noDonor, setNoDonor] = useState(false);

  const [data, setData] = useState({});
  const [total, setTotal] = useState(0)
  const [change, setChange] = useState(false);
  const [hasChange, setHasChange] = useState(false);
  const donorType = useRef('');
  const [pieLabels, setPieLabels] = useState(['Court', 'Education', 'Governor', 'Municipal', 'NC House', 'NC Senate', 'Other', 'Sheriff'])
  const [pieData, setPieData] = useState([])

  const hoverRef = createRef(null)
  const pieMap = {
    'Court': 'cat_court_proportion',
    'Education': 'cat_education_proportion',
    'Governor': 'cat_governor_proportion',
    'Municipal': 'cat_municipal_proportion',
    'NC House': 'cat_nc_house_proportion',
    'NC Senate': 'cat_nc_senate_proportion',
    'Other': 'cat_other_proportion',
    'Sheriff': 'cat_sheriff_proportion'
  }
  // const [type, setType] = useState(() => localStorage.getItem('get_donor_type')); // 初始化时从本地缓存获取值
  useEffect(() => {
    if (!history.location.state) {
      history.push('/polymo/account-management')
    }
    if (window.localStorage.getItem('user_item')) {
      setTeamInfo(JSON.parse(window.localStorage.getItem('user_item')))
    }
    if (userInfo.account_id && teamInfo.campaign_team_id) {
      getWordList(newPage.current);
      // advancedSliding(hoverRef, { left: left, right: right })
    }
  }, [userInfo.account_id, teamInfo.campaign_team_id, newPage])

  // useEffect(() => {
  //   if (locationInfo.get_donor_type === 'all') {
  //     setDonorType('1')
  //     getWordList({
  //       currentPage: 1,
  //       pageSize: 1
  //     }, '1')
  //   } else if (locationInfo.get_donor_type === 'only') {
  //     setDonorType('2')
  //     getWordList({
  //       currentPage: 1,
  //       pageSize: 1
  //     }, '2')
  //   }
  // }, [locationInfo]);
  useEffect(() => {
    if (hasChange) {
      changeDetail();
    }
  }, [change])

  const left = () => {
    if (newPage.current.currentPage !== total) {
      newPage.current.currentPage = newPage.current.currentPage + 1
      getWordList(newPage.current, donorType.current);
    }
  }
  const right = () => {
    if (newPage.current.currentPage !== 1) {
      newPage.current.currentPage = newPage.current.currentPage - 1
      getWordList(newPage.current, donorType.current);
    }
  }
  const onSelectDonors = (type) => {
    newPage.current.currentPage = 1
    if (type === 'all') {
      donorType.current = '';
      getWordList({
        currentPage: 1,
        pageSize: 1
      }, '')
    } else if (type === 'only') {
      donorType.current = '1';
      getWordList({
        currentPage: 1,
        pageSize: 1
      }, '1')
    }
  }
  const changeDetail = () => {
    const params = {
      "account_id": userInfo.account_id,
      "campaign_team_id": history.location.state.item.campaign_team_id,
      "did": donorDetail.id,
      "donated": donorDetail.donated ? 1 : -1,
      "pledge": donorDetail.pledge ? 1 : -1,
      "called": donorDetail.called ? 1 : -1,
      "do_not_call": donorDetail.do_not_call ? 1 : -1,
      "follow_up": donorDetail.follow_up ? 1 : -1,
    }
    postMethod('/app/workbook/donor/detail/edit', params).then((res) => {
      if (res && res.code === 200) {
      }
    })
  }
  const getWordList = (pagination, type) => {
    let param = {}
    if (type) {
      param = {
        uncalled_only: type,
        account_id: userInfo.account_id,
        campaign_id: teamInfo.campaign_team_id,
        uid: userInfo.uid
      }
    } else {
      param = {
        account_id: userInfo.account_id,
        campaign_id: teamInfo.campaign_team_id,
        uid: userInfo.uid
      }
    }
    postMethod(`/app/workbook/list/${pagination.currentPage}/${pagination.pageSize}`, param).then((res) => {
      if (res && res.code === 200) {
        if (res.data.list[0]) {
          setData(res.data.list[0]);
          setSource(res.data.list[0].source === 'polymo');
          getDonorDetail(res.data.list[0]);
        } else {
          setNoDonor(true)
        }
        setTotal(res.data.total_count);
      }
    })
  }
  const compare = (property) => {
    return function (a, b) {
      var value1 = a[property];
      var value2 = b[property];
      return value2 - value1;
    }
  }
  const getDonorDetail = (rowData) => {
    const params = {
      did: rowData.id,
      account_id: userInfo.account_id,
      campaign_team_id: teamInfo.campaign_team_id
    }
    postMethod(`/app/workbook/donor/detail`, params).then((res) => {
      if (res && res.code === 200) {
        res.data.donated = res.data.donated === 1;
        res.data.pledge = res.data.pledge === 1
        setDonorDetail(res.data);

        if (res.data.category_proportion_dict) {
          let list = []
          pieLabels?.forEach((item) => {
            list.push(res.data.category_proportion_dict[pieMap[item]])
          })
          let datas = []
          pieLabels.forEach((item, index) => {
            datas.push({
              name: item,
              value: Number(list[index])
            })
          })
          const sortDatas = datas.sort(compare("value"))
          let newKeys = [];
          let newValues = [];
          sortDatas.forEach((item) => {
            newKeys.push(item.name);
            if (item.value != 0) {
              let num = 0;
              if (item.value === 1) {
                num = item.value * 100;
              } else {
                num = item.value.toFixed(2).split('.')[1];
              }
              newValues.push(Number(num))
            } else {
              newValues.push(item.value)
            }
          })
          setPieLabels(newKeys)
          setPieData(newValues)
        }
      }
    })
  }

  const onChangeValue = (value, key) => {
    setHasChange(true)
    setChange(!change)
    setDonorDetail({
      ...donorDetail,
      [key]: value,
    })
  }

  const showHistory = () => {
    historyDialogRef.current.show(data)
  }

  const showNote = () => {
    noteDialogRef.current.show(data)
  }

  // const previous = () => {
  //   setPage({
  //     ...page,
  //     currentPage: page.currentPage - 1
  //   })
  //   getWordList({
  //     currentPage: page.currentPage - 1,
  //     pageSize: 1
  //   }, donorType);
  // }

  // const next = () => {
  //   setPage({
  //     ...page,
  //     currentPage: page.currentPage + 1
  //   })
  //   getWordList({
  //     currentPage: page.currentPage + 1,
  //     pageSize: 1
  //   }, donorType);
  // }

  const clickPhone = () => {
    setVisiable(true);
  }

  const onSms = () => {
    smsDialogRef.current.setRow(donorDetail, teamInfo);
  }

  const handleCall = (phone) => {
    window.open(`tel:${phone}`, '_self');
  };
  // 返回
  const onGoTeam = () => {
    if (navigatorSource() === 'PC') {
      history.push('/polymo/account-management', { item: teamInfo });
    } else {
      history.push('/polymo/all-teams');
    }
  }
  const onClose = () => {
    setVisiable(false);
  }
  return (
    <div className="polyfund-switch-campaign-mobile-page" ref={hoverRef}>
      <div className="bg-line"></div>
      <div className="polyfund-switch-campaign-mobile-main">
        <div className="header">
          <div>
            <ArrowLeftIcon onClick={onGoTeam} mr="8px" cursor="pointer" w={4} h={4} />
            {history.location.state?.item.campaign_team_name}'s workbook
          </div>
          {
            !noDonor ?
              <div>
                <Menu>
                  <MenuButton p="0px">
                    <HamburgerIcon w={4} h={4} />
                  </MenuButton>
                  <MenuList w="100px" p="0px" boxShadow={shadow} border="1px solid rgba(40, 58, 83, 0.9)" >
                    <Flex flexDirection="column" p="4px 10px 0px 10px">
                      <MenuItem _click={{
                        background: 'none',
                      }} color={(!donorType.current) ? 'rgb(255, 188, 10)' : ''} onClick={() => onSelectDonors('all')} px="14px">
                        <Text h="20px" lineHeight="20px" fontSize="sm">All donors</Text>
                      </MenuItem>
                      <MenuItem _click={{
                        background: 'none',
                      }} color={donorType.current === "1" ? 'rgb(255, 188, 10)' : ''} onClick={() => onSelectDonors('only')} px="14px">
                        <Text h="20px" lineHeight="20px" fontSize="sm">Uncalled only</Text>
                      </MenuItem>
                    </Flex>
                  </MenuList>
                </Menu>
              </div> : null
          }
        </div>
        <div className="donor-detail-name">
          <p>{donorDetail.name ? donorDetail.name : donorDetail.first_name ? `${donorDetail.first_name} ${donorDetail.last_name}` : ''}</p>
        </div>
        <div className="detail-content">
          {
            source ?
              <div className="detail-data card">
                {/* {pieData}{pieLabels} */}
                <p><span className="info-tit">Total Donation: </span><span>{donorDetail.total_donation}</span></p>
                <p><span className="info-tit">Donation Frequency:</span> <span>{donorDetail.frequency}</span></p>
                <p><span className="info-tit">Average Donation:</span> <span>{donorDetail.mean_donation ? Math.round(donorDetail.mean_donation) : ''}</span></p>
                {/* <p>Email: <span>{donorDetail.email}</span></p> */}
                <p><span className="info-tit">Last Donation:</span> <span>{donorDetail.last_donation || ''}</span></p>
                <Box mb="8px"><span className="info-tit">Top 3 Donation Categories:</span>
                  <Flex mt="8px" w="100%" p="2px">
                    <table rules='all' border="1" width="100%">
                      <thead>
                        <tr>
                          {
                            pieData?.map((item, index) => {
                              return index < 3 ? <th style={{ border: '1px solid #ccc', padding: '4px 0', fontWeight: "400" }} key={index}>{`${pieLabels[index]} - ${item}%`}</th> : null
                            })
                          }
                        </tr>
                      </thead>
                    </table>
                  </Flex>
                </Box>
                <p>
                  <span className="info-tit">Address:</span>
                  <span>
                    {donorDetail.street1}
                    {donorDetail.street2 ? `, ${donorDetail.street2}` : ''}
                    {donorDetail.city ? `, ${donorDetail.city}` : ''}
                    {donorDetail.state ? `, ${donorDetail.state}` : ''}
                    {donorDetail.zip_code ? ` ${donorDetail.zip_code}` : ''}
                  </span>
                </p>
                <p>
                  <span className="info-tit">Phone:</span>
                  {
                    donorDetail.phone_number ? <span className="info-phone" onClick={() => clickPhone()}><PhoneIcon mr="4px" />{donorDetail.phone_number}</span> : null
                  }
                </p>
              </div> : noDonor ? <div className="no-team-mobile">
                <div className="des">You don’t have any donor. </div>
                <div>Please use our desktop site (https://polymoai.com) to setup your donor.</div>
              </div> :
                <div className="detail-data card">
                  <div>
                    <p><span className="info-tit">Address:</span>
                      <span>
                        {donorDetail.street1}
                        {donorDetail.street2 ? `, ${donorDetail.street2}` : ''}
                        {donorDetail.city ? `, ${donorDetail.city}` : ''}
                        {donorDetail.state ? `, ${donorDetail.state}` : ''}
                        {donorDetail.zip_code ? ` ${donorDetail.zip_code}` : ''}
                      </span>
                    </p>
                    <p><span className="info-tit">Phone:</span>
                      {
                        donorDetail.phone_number ? <span className="info-phone" onClick={() => clickPhone()}><PhoneIcon mr="4px" />{donorDetail.phone_number}</span> : null
                      }
                    </p>
                  </div>
                </div>
          }
          {
            source ?
              <div className="detail-operate custon-switch card">
                <div className="item">
                  <span className="custon-switch-name">Donated:</span>
                  <Switch size="md" checked={donorDetail.donated} onChange={(e) => onChangeValue(e, 'donated')} />
                </div>
                <div className="item">
                  <span className="custon-switch-name">Pledge:</span>
                  <Switch size="md" checked={donorDetail.pledge} onChange={(e) => onChangeValue(e, 'pledge')} />
                </div>
                <div className="item">
                  <span className="custon-switch-name">Called:</span>
                  <Switch size="md" checked={donorDetail.called !== -1 && donorDetail.called} onChange={(e) => onChangeValue(e, 'called')} />
                </div>
                <div className="item">
                  <span className="custon-switch-name">Follow up:</span>
                  <Switch size="md" checked={donorDetail.follow_up !== -1 && donorDetail.follow_up} onChange={(e) => onChangeValue(e, 'follow_up')} />
                </div>
                <div className="item">
                  <span className="custon-switch-name" style={{ color: (donorDetail.do_not_call !== -1 && donorDetail.do_not_call) ? 'red' : '' }}>Do not call:</span>
                  <Switch size="md" checked={donorDetail.do_not_call !== -1 && donorDetail.do_not_call} onChange={(e) => onChangeValue(e, 'do_not_call')} />
                </div>
              </div> : null
          }
          <div>
            {
              source ?
                <Flex justifyContent="space-around" className="operate-show-btn">
                  <DefaultButton
                    onClick={showHistory}
                    name='History'
                    width="100px"
                    color="#fff"
                  ></DefaultButton>
                  <DefaultButton
                    onClick={showNote}
                    name='Note'
                    width="100px"
                    ml="20px"
                    color="#fff"
                  ></DefaultButton>
                </Flex> : null
            }
            {
              !noDonor ? <Flex justifyContent="space-around" className="operate-show-btn">
                <DefaultButton
                  onClick={right}
                  name='previous'
                  width="100px"
                  color="#fff"
                  className="previous-btn"
                  disabled={newPage.current.currentPage === 1}
                ></DefaultButton>
                <DefaultButton
                  onClick={left}
                  name='next'
                  width="100px"
                  color="#fff"
                  className="next-btn"
                  disabled={newPage.current.currentPage === total}
                ></DefaultButton>
              </Flex> : null
            }
          </div>
        </div>
      </div>
      <Modal size='3xl' isOpen={visiable} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Actions</ModalHeader>
          <ModalCloseButton _focus={{
            boxShadow: "none",
            border: "none"
          }} />
          <ModalBody>
            <div className="">

            </div>
          </ModalBody>
          <ModalFooter>
            <DefaultButton
              onClick={onSms}
              name='Send sms'
              width="109px"
              color="#fff"
            ></DefaultButton>
            <DefaultButton
              onClick={() => handleCall(donorDetail.phone_number)}
              name='Call'
              width="109px"
              marginLeft="10px"
              color="#fff"
              disabled={donorDetail.do_not_call !== -1 && donorDetail.do_not_call}
            ></DefaultButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <HistoryDialog ref={historyDialogRef}></HistoryDialog>
      <NoteDialog ref={noteDialogRef}></NoteDialog>
      <SendSmsDialog ref={smsDialogRef}></SendSmsDialog>
    </div>
  );
}
export default PolymofundSwitchMobile
