import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import DefaultButton from 'components/button'
import {
  DatabaseOutlined,
  EyeOutlined,
  AlertOutlined,
  ReloadOutlined,
  SyncOutlined,
  InteractionOutlined,
  ToolOutlined,
  KubernetesOutlined,
  AimOutlined
} from '@ant-design/icons';
import './index.scss'
import icon1 from 'assets/img/home-icon1.svg'
import icon2 from 'assets/img/home-icon2.svg'
// import img1 from 'assets/img/1.jpg'
// import img2 from 'assets/img/2.jpg'
// import img3 from 'assets/img/3.jpg'

const PolymofundHome = () => {
  const history = useHistory();
  const userInfo = useSelector((state) => state.userInfos)
  const type = history.location.state?.type || '';
  const [fundData] = useState([
    {
      icon: 'I',
      FirstCharacter: 'E',
      title: 'xtensive Database',
      des: '200,000+ active donors in North Carolina and beyond.',
      iconFont: 'fa-address-book'
    },
    {
      icon: 'II',
      FirstCharacter: 'R',
      title: 'ich Insights',
      des: 'Donor preferences, donation history, and more.',
      iconFont: 'fa-eye'
    },
    {
      icon: 'III',
      FirstCharacter: 'A',
      title: 'lways Updated',
      des: 'Monthly database refresh for current data.',
      iconFont: 'fa-database'
    },
    {
      icon: 'IV',
      FirstCharacter: 'S',
      title: 'eamless Access',
      des: 'Web and mobile apps for easy engagement by multiple staffers simultaneously.',
      iconFont: 'fa-user'
    },
    {
      icon: 'V',
      FirstCharacter: 'D',
      title: 'ecision Support',
      des: 'Al-driven recommendations for maximum impact.',
      iconFont: 'fa-graduation-cap'
    }
  ])
  const [campaignData] = useState([
    {
      icon: 'I',
      FirstCharacter: 'R',
      title: 'evolutionary Tool',
      des: 'Efficient voter engagement at your fingertips.',
      iconFont: 'fa-bullhorn'
    },
    {
      icon: 'II',
      FirstCharacter: 'G',
      title: 'rowing Network',
      des: 'Lightning-fast message distribution focused on the most receptive audiences.',
      iconFont: 'fa-leaf'
    },
    {
      icon: 'III',
      FirstCharacter: 'C',
      title: 'onfident Outreach',
      des: 'Early insights into highest-potential voters and supporters.',
      iconFont: 'fa-thumbs-up'
    }
  ])
  const onGoSubscribe = () => {
    history.push('/polymo/setting', { val: 'service-plan' });
  }
  return (
    <div className="polyfund-home-page">
      <div className="bg-line"></div>
      <div className="polyfund-home-main">
        <div className="polyfund-home-content">
          <div className="content-title">
            <span className="name">
              <img className="icon1" src={icon1} alt="" />
              <img className="icon2" src={icon2} alt="" />
              {type === 'PolymoFund' ? 'Polymo Fund' : 'Polymo Campaign'}</span>
          </div>
          <div className={`content-des ${type === 'PolymoFund' ? 'content-fund' : 'content-campaign'}`}>
            {
              (type === 'PolymoFund' ? fundData : campaignData).map((item, index) => {
                return <div className="info" key={index}>

                  {/* <div className="icon">
                    {
                      index%2 ===0 ? <img className="icon2" src={icon2} alt="" /> : <img className="icon1" src={icon1} alt=""/>
                    }
                  </div> */}

                  <div className="info-content">
                    {
                      type === 'PolymoFund' ? <div>
                        {
                          item.icon === 'I' ? <DatabaseOutlined style={{ fontSize: '40px' }} /> : null
                        }
                        {
                          item.icon === 'II' ? <EyeOutlined style={{ fontSize: '40px' }} /> : null
                        }
                        {
                          item.icon === 'III' ? <SyncOutlined style={{ fontSize: '40px' }} /> : null
                        }
                        {
                          item.icon === 'IV' ? <InteractionOutlined style={{ fontSize: '40px' }} /> : null
                        }
                        {
                          item.icon === 'V' ? <AlertOutlined style={{ fontSize: '40px' }} /> : null
                        }
                      </div> : <div>
                        {
                          item.icon === 'I' ? <ToolOutlined style={{ fontSize: '40px' }} /> : null
                        }
                        {
                          item.icon === 'II' ? <KubernetesOutlined style={{ fontSize: '40px' }} /> : null
                        }
                        {
                          item.icon === 'III' ? <AimOutlined style={{ fontSize: '40px' }} /> : null
                        }
                      </div>
                    }

                    <div className="feature-icon" style={{ marginLeft: index * 0 }}>
                      {/* <i class={[`fas ${item.iconFont} icon`]} aria-hidden="true" ></i> */}
                    </div>
                    <div>
                      <div className={`title ${index % 2 === 0 ? 'title1' : ''}`}><span>{item.FirstCharacter}</span>{item.title}</div>
                      <div className="content">{item.des}</div>
                    </div>
                  </div>
                </div>
              })
            }

          </div>
          {/* <div className="content-des">
            <div className="info">
              <div className="pic">
                <img className="img1" src={img1} alt=""/>
              </div>
              <div className="content">PolymoFund is based our unique and comprehensive database covering 200,200+ active donors in the state of NC</div>
            </div>
            <div className="info info-reserve">
              <div className="content">Our donor data includes a wide range of donor attributes, from address and phone number to history and party interests, to help campaign teams to efficiently and precisely target the most probable potential donors.</div>
              <div className="pic">
                <img className="img1" src={img2} alt=""/>
              </div>
            </div>
            <div className="info">
              <div className="pic">
                <img className="img1" src={img3} alt=""/>
              </div>
              <div className="content">PolymoFund's internal recommendation engine leverages leading-edge artifical intelligence to generate the personalized donor list.</div>
            </div>
          </div> */}
          <div className="operate-content">
            {
              userInfo.email && type === 'PolymoFund' ?
                <DefaultButton
                  onClick={onGoSubscribe}
                  name='Go to subscribe'
                  width="244px"
                  height="77px"
                  borderRadius="20px"
                  fontSize="20px"
                ></DefaultButton>
                : null
            }
          </div>
        </div>

      </div>
    </div>
  );
}
export default PolymofundHome
