/* eslint-disable */
import {
  // Flex,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

import React, { forwardRef, useImperativeHandle, useState } from "react";
import DefaultButton from 'components/button'

const DeleteTeamDialog = forwardRef((props, ref) => {
  const { onDeleteSms } = props;
  const [visiable, setVisiable] = useState(false);
  const cancelRef = React.useRef();
  const [item, setItem] = useState('')
  useImperativeHandle(ref, () => ({
    delete: (val) => {
      setItem(val)
      setVisiable(true);
    }
  }))
  const onClose = () => {
    setVisiable(false);
  }
  const onSureDetele = () => {
    // 调取删除接口然后刷新列表
    setVisiable(false);
    onDeleteSms(item);
  }

  return (
    <Modal size='xl' isOpen={visiable} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete {item.sms_template_name}</ModalHeader>
        <ModalCloseButton _focus={{
          boxShadow: "none",
          border: "none"
        }} />
        <ModalBody>
          <Box>
            Are you sure to delete your sms template? 
          </Box>
        </ModalBody>
        <ModalFooter>
          <DefaultButton
            onClick={onClose}
            name='Cancel'
            width="109px"
            color="#000"
            background="#EDF2F7"
            marginLeft="12px"
          ></DefaultButton>
          <DefaultButton
            onClick={onSureDetele}
            name='Delete'
            type='submit'
            width="109px"
            background='#E53E3E'
            hoverbackground= "#D71D1D"
            color="#fff"
            marginLeft="12px"
          ></DefaultButton>
        </ModalFooter>
      </ModalContent>
    </Modal>

  );
})

export default DeleteTeamDialog;
