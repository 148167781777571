import React, { forwardRef, useState } from "react";

import PieChart from "components/charts/PieChart";

const PieChartCustom = forwardRef((props, ref) => {
  const {data, labels} = props
  const [pieChartOptions] = useState(
    {
      labels: labels,
      colors: ["#5096FF", "#FF7D85", "#94FF8B", "#9B8BFF", "#FFB950" , "#7DFFE0", "#FF7DDB"],
      chart: {
        width: "200px",
      },
      states: {
        hover: {
          filter: {
            type: "none",
          },
        },
      },
      legend: {
        position: 'left',
        horizontalAlign: 'top',
        show: true,
        formatter: function(seriesName, opts) {
          return [seriesName, " - ", opts.w.globals.series[opts.seriesIndex] + '%']
        }
      },
      dataLabels: {
        enabled: false,
      },
      hover: { mode: null },
      // plotOptions: {
      //   pie:{
      //     donut: {
      //       expandOnClick: false,
      //       labels: {
      //         show: true,
      //         total: {
      //           showAlways: false,
      //           show: false,
      //           label: '',
      //         },
      //       },
      //     },
      //   }
      // },
      fill: {
        colors: ["#5096FF", "#FF7D85", "#94FF8B", "#9B8BFF", "#FFB950" , "#7DFFE0", "#FF7DDB"],
      },
      tooltip: {
        enabled: true,
        theme: "dark",
        y: {
          formatter: function (y) {
            if(typeof y !== "undefined") {
              return y + "%";
            }
            return y;
          }
        }
      },
    }
  )
  
  return (
    <PieChart
      h='100%'
      w='100%'
      chartData={data}
      chartOptions={pieChartOptions}
    />
  );
})

export default PieChartCustom;
