import React, { forwardRef, useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserCustomClaims } from 'api/index';
import DefaultButton from 'components/button'

import './index.scss'

const UserReports = forwardRef((props, ref) => {
  const userInfo = useSelector((state) => state.userInfos)
  const history = useHistory();

  const [isHaveAccount, setAccount] = useState(false)
  useEffect(() => {
    if (userInfo?.account_id) {
      setAccount(true)
      // getUserCustomClaims(`/app/user/${userInfo?.userInfos.uid || ''}`).then((res) => {
      //   if(res && res.code === 200 && res.data.account_id) {
      //   }
      // })
    }
  }, [userInfo?.uid]);
  const onSignUp = () => {
    history.push('/polymo/sign-up');
  }
  const onGoSubscribe = () => {
    history.push('/polymo/setting', { val: 'service-plan' });
  }
  const onGoAccount = () => {
    history.push('/polymo/all-teams');
  }
  const onLearnMore = (type) => {
    history.push('/polymo/learn-more', { type: type });
  }
  const onDownload = () => {
    history.push('/polymo/download');
  }
  return (
    <div className="home-page">
      <div className="content">
        <div className="content-des">
          <p className="des-title">PolymoAI</p>
          <p className="des-text">
            Supercharge your campaign AND WIN! Join us in shaping the future of political engagement.
          </p>
          <p className="des-text des-text-b">

          </p>
          {
            !userInfo?.email ?
              <div className='sign-up' onClick={onSignUp}>Sign Up</div> : null
          }

        </div>
      </div>
      <div className="middle-line"></div>
      <div className="content-info">
        <div className="card-info">
          <p className="title">PolymoFund</p>
          <p className="des">Powering Donor Engagement</p>
          {
            !window.localStorage.getItem('account') ?
              <div className="sign-operate">
                {
                  userInfo.account_role === 248 ?
                    // <p className="subscribe" onClick={onGoSubscribe}>Subscribe</p> 
                    <DefaultButton
                      onClick={onGoSubscribe}
                      name='subscribe'
                      borderRadius="20px"
                    ></DefaultButton>
                    : null
                }
                <p className="learn-more" onClick={() => onLearnMore('PolymoFund')}>Learn more</p>
              </div>
              :
              <div className="sign-operate">
                {
                  isHaveAccount
                    ?
                    // <p className="subscribe" onClick={onGoAccount}>
                    //   Go to Account
                    // </p> 
                    <DefaultButton
                      onClick={onGoAccount}
                      name='Go to Account'
                      width="162px"
                      borderRadius="20px"
                    ></DefaultButton>
                    :
                    <DefaultButton
                      onClick={onGoSubscribe}
                      name='subscribe'
                      borderRadius="20px"
                    ></DefaultButton>
                  // <p className="subscribe" onClick={onGoSubscribe}>Subscribe</p>
                }
                <p className="learn-more" onClick={() => onLearnMore('PolymoFund')}>Learn more</p>
              </div>
          }

        </div>
        <div className="card-info card-info-wait">
          <p className="title">PolymoCampaign</p>
          <p className="des">Engage with Confidence</p>
          <div className="sign-operate">
            <DefaultButton
              onClick={onDownload}
              name='Download'
              width="162px"
              borderRadius="20px"
            ></DefaultButton>
            <p className="learn-more" onClick={() => onLearnMore('PolymoCampaign')}>Learn more</p>
          </div>
        </div>
      </div>
    </div>
  );
})

export default UserReports
