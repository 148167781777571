import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { getMethod } from 'api/index';
import { getUserCustomClaims } from 'api/index'
import { PayAsYouGo } from '@utils/common'
import './index.scss'

const PolymofundPurchase = () => {
  const [servicePlan, setServicePlan] = useState('');
  const userInfo = useSelector((state) => state.userInfos)
  const [dueDay, setDueDay] = useState('');
  const [servicePlanId, setServicePlanId] = useState('');

  useEffect(() => {
    if (userInfo.uid) {
      getInfo();
    }
  }, [userInfo.uid]);
  useEffect(() => {
    if (servicePlanId) {
      getPlanList();
    }
  }, [servicePlanId]);
  const getPlanList = () => {
    getMethod('/api/service/plan/list').then((res) => {
      if (res && res.code === 200) {
        const item = res.data.find((i) => {
          return i.id === servicePlanId
        })
        setServicePlan(item?.service_name)
      }
    })
  }
  const getInfo = () => {
    getUserCustomClaims(`/app/user/${userInfo?.uid || ''}`).then((res) => {
      if(res) {
        setDueDay(res.data.account_expiration_time)
        setServicePlanId(res.data.service_plan_id)
      }
    })
  }
  return (
    <div className="polyfund-service-plan-page">
      <div className="bg-line"></div>
      <div className="polyfund-service-plan-main">
        <div className="polyfund-service-plan-content">
          <div className="content-title">
            <div className="name">Polymo<span style={{ color: '#FFBC0A' }}>Fund</span></div>
          </div>
          <div className="fee">
            Due Date: {dueDay}
          </div>
          <div className="content">
            {/* {
              servicePlan === 'Plus' ?
                <div className="content-c">
                  <stripe-buy-button
                    buy-button-id="buy_btn_1OZpfGI2dSxsXaUh8b10cmMb"
                    publishable-key="pk_test_51Nw8LdI2dSxsXaUh4FQgRyrwK1sI7uf7S41j8RQFrVKW60plIykIpWQUQH0aQFB9bZrfnMSvfO8db4UZTYjIRqIH00hKm645Kk"
                  >
                  </stripe-buy-button>
                  <stripe-buy-button
                    buy-button-id="buy_btn_1OZpgsI2dSxsXaUhHxLeRhyL"
                    publishable-key="pk_test_51Nw8LdI2dSxsXaUh4FQgRyrwK1sI7uf7S41j8RQFrVKW60plIykIpWQUQH0aQFB9bZrfnMSvfO8db4UZTYjIRqIH00hKm645Kk"
                  >
                  </stripe-buy-button>
                  <stripe-buy-button
                    buy-button-id="buy_btn_1OZpiDI2dSxsXaUhwMPrmEXH"
                    publishable-key="pk_test_51Nw8LdI2dSxsXaUh4FQgRyrwK1sI7uf7S41j8RQFrVKW60plIykIpWQUQH0aQFB9bZrfnMSvfO8db4UZTYjIRqIH00hKm645Kk"
                  >
                  </stripe-buy-button>
                </div> : null
            } */}
            {/* {
              servicePlan === 'Basic' ?
                <div className="content-c">
                  <stripe-buy-button
                    buy-button-id="buy_btn_1OZlvRI2dSxsXaUhWnf5Gzw3"
                    publishable-key="pk_test_51Nw8LdI2dSxsXaUh4FQgRyrwK1sI7uf7S41j8RQFrVKW60plIykIpWQUQH0aQFB9bZrfnMSvfO8db4UZTYjIRqIH00hKm645Kk"
                  >
                  </stripe-buy-button>
                  <stripe-buy-button
                    buy-button-id="buy_btn_1OZpLII2dSxsXaUhecthi2Vz"
                    publishable-key="pk_test_51Nw8LdI2dSxsXaUh4FQgRyrwK1sI7uf7S41j8RQFrVKW60plIykIpWQUQH0aQFB9bZrfnMSvfO8db4UZTYjIRqIH00hKm645Kk"
                  >
                  </stripe-buy-button>
                  <stripe-buy-button
                    buy-button-id="buy_btn_1OZpMcI2dSxsXaUhcmFmUTro"
                    publishable-key="pk_test_51Nw8LdI2dSxsXaUh4FQgRyrwK1sI7uf7S41j8RQFrVKW60plIykIpWQUQH0aQFB9bZrfnMSvfO8db4UZTYjIRqIH00hKm645Kk"
                  >
                  </stripe-buy-button>
                </div> : null
            } */}
            {
              servicePlan === PayAsYouGo ?
                <div className="content-c">
                  "pay as you go" service plan purchase payment when adding donor data
                </div> : null
            }
            {
              servicePlan === 'Ultimate' ?
                <div className="content-c">
                  {/* normal */}
                  {/* <stripe-buy-button
                    buy-button-id="buy_btn_1OZpjiI2dSxsXaUhRIhPVjWa"
                    publishable-key="pk_test_51Nw8LdI2dSxsXaUh4FQgRyrwK1sI7uf7S41j8RQFrVKW60plIykIpWQUQH0aQFB9bZrfnMSvfO8db4UZTYjIRqIH00hKm645Kk"
                  >
                  </stripe-buy-button> */}
                  
                  {/* quarterly */}
                  {/* <stripe-buy-button
                    buy-button-id="buy_btn_1OZpl7I2dSxsXaUhp1UiCROM"
                    publishable-key="pk_test_51Nw8LdI2dSxsXaUh4FQgRyrwK1sI7uf7S41j8RQFrVKW60plIykIpWQUQH0aQFB9bZrfnMSvfO8db4UZTYjIRqIH00hKm645Kk"
                  >
                  </stripe-buy-button> */}
                  {/* manual */}
                  {/* <stripe-buy-button
                    buy-button-id="buy_btn_1OZpmxI2dSxsXaUhrD1xHrSf"
                    publishable-key="pk_test_51Nw8LdI2dSxsXaUh4FQgRyrwK1sI7uf7S41j8RQFrVKW60plIykIpWQUQH0aQFB9bZrfnMSvfO8db4UZTYjIRqIH00hKm645Kk"
                  >
                  </stripe-buy-button> */}
<stripe-pricing-table pricing-table-id="prctbl_1P1IQaI2dSxsXaUhdoB4lHRP"
publishable-key="pk_live_51Nw8LdI2dSxsXaUhpOjXB1nD2rQHEv6GjLqnjIq2L5sXez48QacWoY0dEJPixEKqfxuiIt7CwYULRnGShahw5pXx00vtfiUW1L">
</stripe-pricing-table>
<stripe-pricing-table pricing-table-id="prctbl_1P1SwWI2dSxsXaUhJ68N9ymO"
publishable-key="pk_live_51Nw8LdI2dSxsXaUhpOjXB1nD2rQHEv6GjLqnjIq2L5sXez48QacWoY0dEJPixEKqfxuiIt7CwYULRnGShahw5pXx00vtfiUW1L">
</stripe-pricing-table>
<stripe-pricing-table pricing-table-id="prctbl_1P1SoaI2dSxsXaUhdPKAIeaq"
publishable-key="pk_live_51Nw8LdI2dSxsXaUhpOjXB1nD2rQHEv6GjLqnjIq2L5sXez48QacWoY0dEJPixEKqfxuiIt7CwYULRnGShahw5pXx00vtfiUW1L">
</stripe-pricing-table>

                </div> : null
            }
            {
              !servicePlanId ?
                <div>
                  {/* <div className="content-c">
                    <stripe-buy-button
                      buy-button-id="buy_btn_1OZlvRI2dSxsXaUhWnf5Gzw3"
                      publishable-key="pk_test_51Nw8LdI2dSxsXaUh4FQgRyrwK1sI7uf7S41j8RQFrVKW60plIykIpWQUQH0aQFB9bZrfnMSvfO8db4UZTYjIRqIH00hKm645Kk"
                    >
                    </stripe-buy-button>
                    <stripe-buy-button
                      buy-button-id="buy_btn_1OZpLII2dSxsXaUhecthi2Vz"
                      publishable-key="pk_test_51Nw8LdI2dSxsXaUh4FQgRyrwK1sI7uf7S41j8RQFrVKW60plIykIpWQUQH0aQFB9bZrfnMSvfO8db4UZTYjIRqIH00hKm645Kk"
                    >
                    </stripe-buy-button>
                    <stripe-buy-button
                      buy-button-id="buy_btn_1OZpMcI2dSxsXaUhcmFmUTro"
                      publishable-key="pk_test_51Nw8LdI2dSxsXaUh4FQgRyrwK1sI7uf7S41j8RQFrVKW60plIykIpWQUQH0aQFB9bZrfnMSvfO8db4UZTYjIRqIH00hKm645Kk"
                    >
                    </stripe-buy-button>
                  </div> */}
                  {/* <div className="content-c">
                    <stripe-buy-button
                      buy-button-id="buy_btn_1OZpfGI2dSxsXaUh8b10cmMb"
                      publishable-key="pk_test_51Nw8LdI2dSxsXaUh4FQgRyrwK1sI7uf7S41j8RQFrVKW60plIykIpWQUQH0aQFB9bZrfnMSvfO8db4UZTYjIRqIH00hKm645Kk"
                    >
                    </stripe-buy-button>
                    <stripe-buy-button
                      buy-button-id="buy_btn_1OZpgsI2dSxsXaUhHxLeRhyL"
                      publishable-key="pk_test_51Nw8LdI2dSxsXaUh4FQgRyrwK1sI7uf7S41j8RQFrVKW60plIykIpWQUQH0aQFB9bZrfnMSvfO8db4UZTYjIRqIH00hKm645Kk"
                    >
                    </stripe-buy-button>
                    <stripe-buy-button
                      buy-button-id="buy_btn_1OZpiDI2dSxsXaUhwMPrmEXH"
                      publishable-key="pk_test_51Nw8LdI2dSxsXaUh4FQgRyrwK1sI7uf7S41j8RQFrVKW60plIykIpWQUQH0aQFB9bZrfnMSvfO8db4UZTYjIRqIH00hKm645Kk"
                    >
                    </stripe-buy-button>
                  </div> */}
                  <div className="content-c">
                  {/* normal */}
                  <stripe-buy-button
                    buy-button-id="buy_btn_1OZpjiI2dSxsXaUhRIhPVjWa"
                    publishable-key="pk_test_51Nw8LdI2dSxsXaUh4FQgRyrwK1sI7uf7S41j8RQFrVKW60plIykIpWQUQH0aQFB9bZrfnMSvfO8db4UZTYjIRqIH00hKm645Kk"
                  >
                  </stripe-buy-button>
                  {/* quarterly */}
                  <stripe-buy-button
                    buy-button-id="buy_btn_1OZpl7I2dSxsXaUhp1UiCROM"
                    publishable-key="pk_test_51Nw8LdI2dSxsXaUh4FQgRyrwK1sI7uf7S41j8RQFrVKW60plIykIpWQUQH0aQFB9bZrfnMSvfO8db4UZTYjIRqIH00hKm645Kk"
                  >
                  </stripe-buy-button>
                  {/* manual */}
                  <stripe-buy-button
                    buy-button-id="buy_btn_1OZpmxI2dSxsXaUhrD1xHrSf"
                    publishable-key="pk_test_51Nw8LdI2dSxsXaUh4FQgRyrwK1sI7uf7S41j8RQFrVKW60plIykIpWQUQH0aQFB9bZrfnMSvfO8db4UZTYjIRqIH00hKm645Kk"
                  >
                  </stripe-buy-button>
                </div>
                </div>
                : null
            }
          </div>
          {
            servicePlan !== PayAsYouGo ?
            <div className="fee">
              *All plus a tax fee of 2.9%
            </div> : null
          }
          
        </div>

      </div>
    </div>
  );
}
export default PolymofundPurchase
