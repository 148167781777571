import React, { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { Switch } from 'antd';

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import CustomTable from "components/table";
import Pagination from "components/pagination";

import { columnsDetail } from "../config";
import sendNote from 'assets/img/send-note.svg'
import '../index.scss'
import { postMethod } from 'api/index';

const DonorDetailDialog = forwardRef((props, ref) => {
  const { onRefresh } = props;
  // const history = useHistory();
  // const userInfo = useSelector((state) => state.userInfos)
  const [visiable, setVisiable] = useState(false);
  const [rowData, setData] = useState({})
  const [teamInfo, setTeamInfo] = useState({})
  const [page, setPage] = React.useState({
    currentPage: 1,
    pageSize: 10
  })
  const [total, setTotal] = useState(0)
  const [dataList, setDataList] = React.useState([]);

  useImperativeHandle(ref, () => ({
    show: (record) => {
      setData(record)
      if (window.localStorage.getItem('user_item')) {
        setTeamInfo(JSON.parse(window.localStorage.getItem('user_item')))
      }
      setSource(record.source === 'polymo');
      // 获取到行数据 取数据
      if (record.source === 'polymo' && teamInfo.campaign_team_id) {
        getdonorHistorylist(page, record);
      }
      setVisiable(true);
    }
  }))
  useEffect(() => {
    if (teamInfo.campaign_team_id) {
      if(rowData.source === 'polymo') {
        getdonorHistorylist(page, rowData);
      }
    }
  }, [teamInfo.campaign_team_id])
  const getdonorHistorylist = (pagination, row) => {
    let params = {
      page_number: pagination.currentPage,
      page_size: pagination.pageSize,
      campaign_id: teamInfo.campaign_team_id,
      donor_name: row.name,
      donor_id: row.id,
    }
    postMethod('/app/donor/transaction/page', params).then((res) => {
      if (res && res.code === 200) {
        setDataList(res.data.list)
        setTotal(res.data.total_count)
      }
    })
  }
  const [source, setSource] = useState(false)
  const onClose = () => {
    setVisiable(false);
  }


  return (
    <div>
      <Modal size='3xl' isOpen={visiable} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Donor history</ModalHeader>
          <ModalCloseButton _focus={{
            boxShadow: "none",
            border: "none"
          }} />
          <ModalBody>
            <div className="donor-detail-history-dialog">
              <div className="table-content">
                {
                  source ?
                    <CustomTable
                      isChecked={false}
                      wrap={true}
                      columns={columnsDetail(
                        {
                          teamInfo: teamInfo
                        }
                      )}
                      data={dataList}
                    /> :
                    <CustomTable
                      isChecked={false}
                      columns={columnsDetail()}
                      data={dataList}
                      emptytext='service unavaliable for private donor'
                    />
                }
              </div>
              <div className="donor-detail-pagination">
                {
                  source ?
                    <Pagination
                      onChange={(pagination) => {
                        setPage(pagination)
                        getdonorHistorylist(pagination, rowData)
                      }}
                      paginations={page}
                      data={dataList}
                      total={total}
                    ></Pagination>
                    : <Pagination
                      data={[]}
                      total={0}
                    ></Pagination>
                }
              </div>
            </div>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </div>

  );
})

export default DonorDetailDialog;
