import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ArrowLeftIcon } from '@chakra-ui/icons'
import {
  Flex,
} from '@chakra-ui/react';
import { Popconfirm, Button } from 'antd';
import CustomTable from "components/table";
import Pagination from "components/pagination";
import DetailDialog from "./components/detail";
import DeleteDialog from "./components/delete";

import Query from './components/query'
import DefaultButton from 'components/button'
import { navigatorSource } from '@utils/navigator'

import { columns } from "./config";
import { postMethod } from 'api/index';
import { successMessage } from '@utils/message'


import './index.scss'

const PolymofundHome = () => {
  const history = useHistory();
  const userInfo = useSelector((state) => state.userInfos)
  // // console.log(history.location.pathname.split('/').pop())
  const detailDialogRef = React.useRef();
  const deleteDialogRef = React.useRef();
  const donorTableRef = React.useRef();

  const [teamInfo, setTeamInfo] = useState({})
  const [queryForm, setQuery] = useState({})
  const [orderQuery, setOrderQuery] = useState({})

  const [sortQuery, setSortQuery] = useState({})
  useEffect(() => {
    if (!history.location.state) {
      history.push('/polymo/account-management')
    }
    if (window.localStorage.getItem('user_item')) {
      setTeamInfo(JSON.parse(window.localStorage.getItem('user_item')))
    }
    if (userInfo.account_id && teamInfo.campaign_team_id) {
      getWordList(page);
      getSortQuery()
    }
  }, [userInfo.account_id, teamInfo.campaign_team_id])

  const [total, setTotal] = useState(0)
  const [page, setPage] = React.useState({
    currentPage: 1,
    pageSize: 10
  })
  const getWordList = (pagination, params = {}) => {
    let querySearch = {}
    if (params.data) {
      querySearch = {
        ...params,
        'last_active_date_begin': params.data[0] || '',
        'last_active_date_end': params.data[1] || ''
      }
    } else {
      querySearch = {
        ...params,
      }
    }
    let newParams = {}
    if (Object.keys(querySearch)) {
      for (let i in querySearch) {
        if (typeof (querySearch[i]) === 'string' && querySearch[i]) {
          newParams[i] = querySearch[i]
        } else if (typeof (querySearch[i]) === 'object' && querySearch[i]) {
          if (querySearch[i].length) {
            newParams[i] = querySearch[i]
          }
        }
      }
    }

    let param = {
      ...newParams,
      account_id: userInfo.account_id,
      campaign_id: teamInfo.campaign_team_id,
      uid: userInfo.uid
    }
    postMethod(`/app/workbook/list/${pagination.currentPage}/${pagination.pageSize}`, param).then((res) => {
      if (res && res.code === 200) {
        setData(res.data.list)
        setTotal(res.data.total_count)
      }
    })
  }

  const getSortQuery = () => {
    let param = {
      campaign_team_id: teamInfo.campaign_team_id,
      uid: userInfo.uid
    }
    postMethod(`/app/workbook/sort/setting/detail`, param).then((res) => {
      if (res && res.code === 200) {
        const parmas = {
          [res.data.sort_column]: res.data.sort_direction
        }
        setSortQuery(parmas)
      }
    })
  }

  const onRefreshList = () => {
    getWordList(page);
  }
  // 确实删除
  const deleteUser = (row) => {
    let params = {
      did: row.id,
      account_id: userInfo.account_id,
      campaign_team_id: teamInfo.campaign_team_id
    }
    postMethod(`/app/workbook/delete`, params).then((res) => {
      if (res && res.code === 200) {
        if (dataList.length === 1 && page.currentPage > 1) {
          setPage({
            ...page,
            currentPage: page.currentPage - 1
          })
          getWordList({
            currentPage: page.currentPage - 1,
            pageSize: 10
          })
        } else {
          getWordList(page);
        }
      }
    })
  }
  const [dataList, setData] = React.useState([]);

  const emitSort = (sort) => {
    setOrderQuery(sort)
    setPage({
      currentPage: 1,
      pageSize: 10
    })
    getWordList({
      currentPage: 1,
      pageSize: 10
    }, { ...queryForm, ...sort })
  }
  const onClickRow = (item) => {
    detailDialogRef.current.detail(item);
  }

  const onSearchTable = (query, type) => {
    donorTableRef.current.clear()
    setQuery(query)
    if (type === 'clear') {
      setPage({
        currentPage: 1,
        pageSize: 10
      })
      getWordList({
        currentPage: 1,
        pageSize: 10
      }, query)
    } else {
      setPage({
        currentPage: 1,
        pageSize: 10
      })
      getWordList({
        currentPage: 1,
        pageSize: 10
      }, query)
    }
  }

  const onDeleteDonor = () => {
    let params = {
      account_id: userInfo.account_id,
      campaign_team_id: teamInfo.campaign_team_id,
    }
    postMethod(`/app/workbook/removal`, params).then((res) => {
      if (res && res.code === 200) {
        // successMessage('success')
        getWordList({
          currentPage: 1,
          pageSize: 10
        }, { ...queryForm })
      }
    })
  }

  const onAddDonor = () => {
    window.localStorage.setItem('from', 'switch')
    history.push('/polymo/create-book', { item: teamInfo })
  }
  // 保存用户排序规则
  const onSaveSortDonor = () => {
    // 如果排序了 保存  没有排序执行删除
    if (Object.keys(orderQuery)[0]) {
      let params = {
        uid: userInfo.uid,
        campaign_team_id: teamInfo.campaign_team_id,
        sort_column: orderQuery && Object.keys(orderQuery)[0] || '',
        sort_direction: orderQuery && Object.values(orderQuery)[0] || ''
      }
      postMethod(`/app/workbook/sort/setting`, params).then((res) => {
        if (res && res.code === 200) {
          successMessage('success')
        }
      })
    }
  }
  const onDeleteSort = () => {
    let params = {
      uid: userInfo.uid,
      campaign_team_id: teamInfo.campaign_team_id,
    }
    postMethod(`/app/workbook/sort/setting/delete`, params).then((res) => {
      if (res && res.code === 200) {
        successMessage('success');
        setSortQuery({});
        setOrderQuery({});
        onRefreshList();
      }
    })
  }
  // 返回
  const onGoTeam = () => {
    if (navigatorSource() === 'PC') {
      history.push('/polymo/account-management', { item: teamInfo });
    } else {
      history.push('/polymo/all-teams');
    }
  }
  return (
    <div className="polyfund-switch-campaign-page">
      <div className="bg-line"></div>
      <div className="polyfund-switch-campaign-main">
        <div className="title">
          <Flex alignItems="center">
            <ArrowLeftIcon onClick={onGoTeam} mr="8px" cursor="pointer" w={4} h={4} />
            {history.location.state?.item.campaign_team_name}'s workbook
          </Flex>
          <div className="operate-content">
            {/* <span className="operate-btn" onClick={onAddDonor}>
              Add donor
            </span> */}
            <DefaultButton
              onClick={onDeleteSort}
              name='Clear sort setting'
              width="160px"
            ></DefaultButton>
            <DefaultButton
              onClick={onSaveSortDonor}
              name='Save sort setting'
              width="160px"
              marginLeft="12px"
              disabled={!Object.keys(orderQuery)[0]}
            ></DefaultButton>
            <DefaultButton
              onClick={onAddDonor}
              name='Add donor'
              marginLeft="12px"
              width="130px"
            ></DefaultButton>
            {/* <DefaultButton
              onClick={onDeleteDonor}
              name='Delete all'
              marginLeft="12px"
              width="130px"
              background='#E53E3E'
              hoverbackground="#D71D1D"
            ></DefaultButton> */}

            <Popconfirm
              title="Delete donor"
              description="Are you sure to delete all donor data?"
              onConfirm={onDeleteDonor}
              // onCancel={onClose}
              okText="Yes"
              cancelText="No"
              okButtonProps={{
                className: 'antBtnConfirm'
              }}
            >
              <Button className="antDeleteBtn" type="primary">Delete all</Button>
            </Popconfirm>
          </div>
        </div>
        <div className="switch-content">
          <div className="search-data-query">
            <div className="query-title"></div>
            <Query
              onSearch={(query, type) => onSearchTable(query, type)}
            />
          </div>
          <div className="query-table">
            <div className="table-content">
              <CustomTable
                isChecked={false}
                columns={columns({
                  deleteDialog: (row) => {
                    deleteDialogRef.current.delete(row);
                  },
                })}
                ref={donorTableRef}
                emitSort={emitSort}
                onClickRow={onClickRow}
                data={dataList}
                sortQuery={sortQuery}
              />
            </div>
            <div className="switch-campaign-pagination">
              <Pagination
                onChange={(pagination) => {
                  setPage(pagination)
                  getWordList(pagination, { ...queryForm, ...orderQuery })
                }}
                paginations={page}
                data={dataList}
                total={total}
              ></Pagination>
            </div>
          </div>
        </div>

      </div>
      <DetailDialog ref={detailDialogRef} onRefresh={onRefreshList}></DetailDialog>
      <DeleteDialog ref={deleteDialogRef} onDelete={(row) => {
        deleteUser(row)
      }} ></DeleteDialog>
    </div>
  );
}
export default PolymofundHome
