/*eslint-disable*/
import React, {forwardRef} from "react";
import './index.scss'

const voterDetail = forwardRef((props, ref) => {
  const RACE_DIST = {
    "A": "ASIAN",
    "B": "BLACK",
    "I": "AMERICAN INDIAN",
    "M": "MORE RACES",
    "O": "OTHER",
    "P": "NATIVE HAWAIIAN",
    "U": "UNDESIGNATED",
    "W": "WHITE"
  }
  return (
    <div className="voterMain">
      {
        props.data ? <div className="voterTitle">
          {props.title}
        </div> : null
      }
      {props.data ? Object.keys(props.data).map((item, index) => {
        return <div key={index} className="voterContent">
          <span>
            {props.substr ? item.substr(1) : props.isKey ? item : item}
          </span>
          <span>
            {props.showVal ? Object.values(props.data)[index] : '***'}
          </span>
        </div>
      }) : null
      }
    </div>
  )
})

export default voterDetail
