/* eslint-disable */
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
} from "@chakra-ui/react";
import React, { forwardRef, useImperativeHandle, useState, useRef } from "react";
import { postMethod } from 'api/index';

const DevelopmentDialog = forwardRef((props, ref) => {
  const {onRefresh} = props;
  const [visiable, setVisiable] = useState(false);
  const cancelRef = React.useRef();
  const fileRef = useRef()

  const [rowData, setData] = useState({})
  const [file, setFiles] = useState({})
  useImperativeHandle(ref, () => ({
    setRow: (record) => {
      setData(record)
      setVisiable(true);
    }
  }))
  // 关闭弹框
  const onClose = () => {
    setVisiable(false);
  }
  const upload = (event) => {
    var e=window.event||event;
    // 获取当前选中的文件
    // var File = e.target.files[0];
    setFiles(e.target.files[0])
    

  }
  const onSure = () => {
    var data=new FormData();
    data.append('ml_file', file)
    data.append('active', 1)
    postMethod('/manage/metadata/model/upload', data).then((res) => {
      if(res && res.code === 200) {
        setVisiable(false);
        onRefresh();
      }
    })
  }
  const onTrigger = (e) => {
    e?.stopPropagation();
    fileRef?.current?.click()
  }
  return (
    <Modal size='xl'  isOpen={visiable}  onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload</ModalHeader>
        <ModalCloseButton _focus={{
          boxShadow: "none",
          border: "none"
        }} />
        <ModalBody>
          {/* <input type="file" name="image" onChange={upload} /> */}
          <span className="input-file input-fileup" onClick={onTrigger}>
            select file
          </span>
          <input ref={fileRef} accept=".csv" type="file" name="image" onChange={upload} />
          <span className="file-name">{file.name}</span>
        </ModalBody>
        <ModalFooter>
          <Button ref={cancelRef} onClick={onClose} colorScheme='blue' mr={3}>
            Close
          </Button>
          <Button onClick={onSure} type='submit' colorScheme='teal' >Sure</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
})

export default DevelopmentDialog;
