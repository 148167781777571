
import { message } from 'antd';
const key = ''
export const successMessage = (content) => {
  message.success({
    content,
    duration: 30,
    key,
    style: {
      height: '120px',
      fontSize: '18px',
      fontWeight:'600',
      marginTop: '12vh'
    },
    onClick: () => {
      message.destroy(key)
    }
  })
}

export const warningMessage = (content) => {
  message.warning({
    content,
    duration: 30,
    key,
    style: {
      height: '120px',
      fontSize: '18px',
      fontWeight:'600',
      marginTop: '12vh'
    },
    onClick: () => {
      message.destroy(key)
    }
  })
}

export const errorMessage = (content) => {
  message.warning({
    content,
    duration: 30,
    key,
    style: {
      height: '120px',
      fontSize: '18px',
      fontWeight:'600',
      marginTop: '12vh'
    },
    onClick: () => {
      message.destroy(key)
    }
  })
}
