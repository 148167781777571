
import React, {useState, useEffect} from "react";
import CustomTable from "components/table";
import Pagination from "components/pagination";

import Card from "components/card/Card";

import SendDialog from "./components/sendMessage";
import EmailDialog from "./components/email";
// import DetailDialog from "./components/detail";

import {
  columns,
} from "./config";

import { postMethod } from 'api/index';

import {
  // Flex,
  Box,
  Input,
  Select,
  Text,
  Button,
  SimpleGrid,
} from "@chakra-ui/react";
export default function Settings() {
  // Chakra Color Mode
  const [query, setVal] = React.useState({
    has_read: '',
  });
  const [total, setTotal] = useState(0)
  /**
   * list 数据
   */
  const [dataList, setData] = React.useState([]);
  const [pagination, setPage] = React.useState({
    currentPage:1,
    pageSize: 10,
  }); 
  
  const cmsDialogRef = React.useRef();
  const emailDialogRef = React.useRef();
  // const detailDialogRef = React.useRef();

  useEffect(() => {
    getList(pagination, {})
  }, [pagination.currentPage])

  const getList = (pagination, params = {}) => {
    let newParams = {}
    if(Object.keys(params)) {
      for(let i in params) {
        if(params[i]) {
          newParams[i] = params[i]
        }
      }
    }
    postMethod(`/manage/subscription/page/${pagination.currentPage}/${pagination.pageSize}`, newParams).then((res) => {
      if(res && res.code=== 200) {
        setData(res.data.list)
        setTotal(res.data.total_count);
      }
    })
  }
  const handleInputChange = (e, key) => {
    setVal({
      ...query,
      [key]: e.target.value
    })
  }
  
  const onRefresh = () => {
    getList(pagination, query)
  }

  const queryList = (searchQuery = {}) => {
    getList({
      currentPage:1,
      pageSize: 10,
    }, query)
  }

  const readMessage = (row) => {
    postMethod('/manage/subscription/read', {id: row.id}).then((res) => {
      if(res && res.code === 200) {
        getList(pagination, query)
      }
    })
  }
  /**
   * 全选单选 选中数据
  */
 const emitSelectAll = (list) => {
  // // console.log(list)
}
  const borderRadius= "8px"

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Box textAlign="right" mb="12px">
        <Text h="32px" display="inline-block" lineHeight="32px">has read</Text>
        {/* <Input w="180px" ml="12px" value={query.name} onChange={(e) => handleInputChange(e, 'name')} /> */}
        <Select w="180px" ml="12px" display="inline-block" lineHeight="32px" className="input-h32" value={query.has_read} onChange={(e) => handleInputChange(e, 'has_read')}  placeholder="please choose" >
          <option value="1">read</option>
          <option value="0">unread</option>
        </Select>
        {/* <Text h="32px" display="inline-block" lineHeight="32px" ml="12px">phone</Text><Input w="120px" ml="12px" value={query.phone} onChange={(e) => handleInputChange(e, 'phone')} /> */}
        <Button borderRadius={borderRadius} ml="8px" onClick={() => {
          queryList()
        }}>search</Button>
        <Button borderRadius={borderRadius} ml="8px" onClick={() => {
          setVal({
            has_read: '',
          })
          setPage({
            currentPage:1,
            pageSize: 10,
          })
          getList({
            currentPage:1,
            pageSize: 10,
          }, {})
        }}>reset</Button>
      </Box>
      
        <SimpleGrid
          mb='0px'
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: "20px", xl: "20px" }}>
          <Card
            mt="12px"
            borderRadius={borderRadius}
            direction='column'
            w='100%'
            px='0px'
            p="0px"
            overflowX={{ sm: "scroll", lg: "scroll" }}>
              <CustomTable
                isChecked={false}
                columns={columns({
                  openCmsDialog: (row) => {
                    cmsDialogRef.current.setRow(row);
                  },
                  openEmailDialog: (row) => {
                    emailDialogRef.current.setRow(row);
                  },
                  readMes: (row) => {
                    readMessage(row)
                  }
                })}
                data={dataList}
                emitSelectAll={emitSelectAll}
              />
              <Box mt="32px" mb="30px">
                <Pagination
                  paginations={pagination}
                  data={dataList} 
                  total={total} 
                  onChange={(pagination) => {
                    setPage(pagination)
                    getList(pagination)
                  }}
                ></Pagination>

              </Box>
            </Card>
        </SimpleGrid>
      <SendDialog ref={cmsDialogRef} onRefresh={onRefresh}></SendDialog>
      <EmailDialog ref={emailDialogRef} onRefresh={onRefresh} ></EmailDialog>
      {/* <DetailDialog ref={detailDialogRef} queryList={queryList}></DetailDialog> */}
    </Box>
  );
}
