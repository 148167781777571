
import React, {useState, useEffect} from "react";
import {
  // Flex,
  Box,
  Input,
  Select,
  Text,
  Button,
  SimpleGrid,
} from "@chakra-ui/react";
import { useHistory } from 'react-router-dom';

import CustomTable from "components/table";
import Pagination from "components/pagination";

import Card from "components/card/Card";

import SendDialog from "./components/sendMessage";
import EmailDialog from "./components/email";
import TerminateDialog from "./components/terminate";

import CustomDataRange from "@/components/dataRange/index.jsx"
import { successMessage } from '@utils/message'

import './index.scss'

// import DetailDialog from "./components/detail";

import {
  columns,
} from "./config";

import { postMethod } from 'api/index';


export default function Settings() {
  const history = useHistory();

  // Chakra Color Mode
  const [query, setVal] = React.useState({
    email: '',
    phone_number: '',
    data: [],
    start_date: '',
    end_date: ''
  });
  const [total, setTotal] = useState(0)
  /**
   * list 数据
   */
  const [dataList, setData] = React.useState([]);
  const [pagination, setPage] = React.useState({
    currentPage:1,
    pageSize: 10,
  }); 
  
  const cmsDialogRef = React.useRef();
  const emailDialogRef = React.useRef();
  const terminateDialogRef = React.useRef();
  // const detailDialogRef = React.useRef();

  useEffect(() => {
    getList(pagination, {})
  }, [pagination.currentPage])

  const getList = (pagination, params = {}) => {
    let newParams = {}
    if(Object.keys(params)) {
      for(let i in params) {
        if(params[i]) {
          newParams[i] = params[i]
        }
      }
    }
    postMethod(`/manage/service/plan/change/page/${pagination.currentPage}/${pagination.pageSize}`, newParams).then((res) => {
      if(res && res.code=== 200) {
        setData(res.data.list)
        setTotal(res.data.total_count);
      }
    })
  }
  const handleInputChange = (e, key) => {
    setVal({
      ...query,
      [key]: e.target.value
    })
  }

  const handleRangeInputChange = (value, key) => {
    setVal({
      ...query,
      [key]: value,
      start_date: value[0],
      end_date: value[1],
    })
  }
  
  const onRefresh = () => {
    getList(pagination, {})
  }

  const queryList = (searchQuery = {}) => {
    setVal({
      ...query,
      start_date: query.data ? query.data[0] : '',
      end_date: query.data ? query.data[1] : '',
    })
    getList({
      currentPage:1,
      pageSize: 10,
    }, query)
  }
  // const readMessage = (row) => {
  //   postMethod('/manage/subscription/read', {id: row.id}).then((res) => {
  //     if(res && res.code === 200) {
  //       getList(pagination, query)
  //     }
  //   })
  // }
  const onSureTernimate = (row) => {
    postMethod(`/manage/account/delete`, {aid: row.account_id}).then((res) => {
      if(res && res.code === 200) {
        successMessage('success')
        postMethod(`/manage/service/plan/change/mark`, {id: row.id}).then((res) => {
          if(res && res.code === 200) {
            getList(pagination, query)
          }
        })
      }

    })
    // postMethod(`/manage/account/serviceplan/terminate/${row.account_id}/${row.user_id}`).then((res) => {
    //   if(res && res.code === 200) {
    //     successMessage('success')
    //     getList(pagination, query)
    //   }
    // })
  }
  /**
   * 全选单选 选中数据
  */
 const emitSelectAll = (list) => {
  // // console.log(list)
}
  const borderRadius= "8px"

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Box className="queryFrom" textAlign="right" mb="12px">
        <Text h="32px" display="inline-block" lineHeight="32px">email</Text>
        <Input w="180px" ml="12px" value={query.email} onChange={(e) => handleInputChange(e, 'email')} />
        <Text ml="8px" h="32px" display="inline-block" lineHeight="32px">phone_number</Text>
        <Input w="180px" ml="12px" value={query.phone_number} onChange={(e) => handleInputChange(e, 'phone_number')} />
        <Text ml="8px" h="32px" display="inline-block" lineHeight="32px">date</Text>
        <Box w="180px" h="32px" ml="12px" display="inline-block" lineHeight="32px">
          <CustomDataRange
            value={query.data}
            valueType={'string'}
            onChange={(value) => {
              handleRangeInputChange(value, 'data')
            }}
          ></CustomDataRange>
        </Box>
        {/* <Text h="32px" display="inline-block" lineHeight="32px" ml="12px">phone</Text><Input w="120px" ml="12px" value={query.phone} onChange={(e) => handleInputChange(e, 'phone')} /> */}
        <Button borderRadius={borderRadius} ml="8px" onClick={() => {
          queryList()
        }}>search</Button>
        <Button borderRadius={borderRadius} ml="8px" onClick={() => {
          setVal({
            email: '',
            phone_number: '',
            data: []
          })
          setPage({
            currentPage:1,
            pageSize: 10,
          })
          getList({
            currentPage:1,
            pageSize: 10,
          }, {})
        }}>reset</Button>
      </Box>
      
        <SimpleGrid
          mb='0px'
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: "20px", xl: "20px" }}>
          <Card
            mt="12px"
            borderRadius={borderRadius}
            direction='column'
            w='100%'
            px='0px'
            p="0px"
            overflowX={{ sm: "scroll", lg: "scroll" }}>
              <CustomTable
                isChecked={false}
                columns={columns({
                  openCmsDialog: (row) => {
                    cmsDialogRef.current.setRow(row);
                  },
                  // openEmailDialog: (row) => {
                  //   emailDialogRef.current.setRow(row);
                  // },
                  // readMes: (row) => {
                  //   readMessage(row)
                  // },
                  terminateDialog: (row, type) => {
                    if(type === 'change') {
                      history.push('/cms/account-management')
                    }else {
                      terminateDialogRef.current.terminate(row);
                    }
                  }
                })}
                data={dataList}
                emitSelectAll={emitSelectAll}
              />
              <Box mt="32px" mb="30px">
                <Pagination 
                  paginations={pagination}
                  data={dataList} 
                  total={total} 
                  onChange={(pagination) => {
                    setPage(pagination)
                    getList(pagination)
                  }}
                ></Pagination>

              </Box>
            </Card>
        </SimpleGrid>
      <SendDialog ref={cmsDialogRef} onRefresh={onRefresh}></SendDialog>
      <EmailDialog ref={emailDialogRef} onRefresh={onRefresh} ></EmailDialog>
      <TerminateDialog ref={terminateDialogRef} onTerminate={onSureTernimate}></TerminateDialog>

    </Box>
  );
}
