import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ArrowLeftIcon } from '@chakra-ui/icons'
import {
  Flex,
} from '@chakra-ui/react';
import CustomTable from "components/table";
import Pagination from "components/pagination";
// import DetailDialog from "./components/detail";
import Query from './components/query'
import AddtransDialog from "../account-management/components/add-transcation";
import DefaultButton from 'components/button'

import { columns } from "./config";
import { postMethod } from 'api/index';

import './index.scss'

const PolymofundHome = () => {
  const history = useHistory();
  const userInfo = useSelector((state) => state.userInfos)
  // // console.log(history.location.pathname.split('/').pop())
  // const detailDialogRef = React.useRef();
  const addTransDialogRef = React.useRef();

  const [dataList, setData] = React.useState([]);
  const [queryForm, setQuery] = useState({})
  useEffect(() => {
    if(!history.location.state) {
      history.push('/polymo/account-management')
    }
    if(userInfo.account_id && history.location.state.item.campaign_team_id) {
      getTranscationList(page)
    }
  }, [userInfo.account_id, history.location.state.item.campaign_team_id]);
  const [page, setPage] = React.useState({
    currentPage: 1,
    pageSize: 10
  })
  const [total, setTotal] = useState(0)

  const getTranscationList = (pagination, params = {}) => {
    let querySearch = {}
    if(params.data) {
      querySearch = {
        ...params,
        'date_occured_start': params.data[0] || '',
        'date_occured_end': params.data[1] || ''
      }
    } else {
      querySearch = {
        ...params,
      }
    }
    let newParams = {}
    if(Object.keys(querySearch)) {
      for(let i in querySearch) {
        if(typeof(querySearch[i]) === 'string' && querySearch[i]) {
          newParams[i] = querySearch[i]
        } else if(typeof(querySearch[i]) === 'object' && querySearch[i]) {
          if(querySearch[i].length) {
            newParams[i] = querySearch[i]
          }
        }
      }
    }
    let param = {
      ...newParams,
      campaign_id: history.location.state.item.campaign_team_id,
      account_id: userInfo.account_id,
    }
    postMethod(`/app/transaction/page/${pagination.currentPage}/${pagination.pageSize}`, param).then((res) => {
      if(res && res.code === 200) {
        setData(res.data.list)
        setTotal(res.data.total_count)
      }
    })
  }

  const onAddTrans = () => {
    addTransDialogRef.current.add(history.location.state.item);
  }
  // 添加transcation 之后需要重新调取接口
  const onAdd = () => {
    getTranscationList({
      currentPage: 1,
      pageSize: 10
    }, queryForm)
  }

  // const emitSort = (sort) => {
  //   // console.log(sort, 'sort')
  // }
  // const onClickRow = (item) => {
  //   // detailDialogRef.current.detail(item);
  // }

  const onSearchTable = (query, type) => {
    setQuery(query)
    if(type === 'clear') {
      setPage({
        currentPage: 1,
        pageSize: 10
      })
      getTranscationList({
        currentPage: 1,
        pageSize: 10
      }, query)
    } else {
      setPage({
        currentPage: 1,
        pageSize: 10
      })
      getTranscationList({
        currentPage: 1,
        pageSize: 10
      }, query)
    }
  }
  // 返回
  const onGoTeam = () => {
    history.push('/polymo/account-management', { item: history.location.state.item })
  }
  return (
    <div className="polyfund-view-transaction-page">
      <div className="bg-line"></div>
      <div className="polyfund-view-transaction-main">
        <div className="title">
          <Flex alignItems="center">
            <ArrowLeftIcon onClick={onGoTeam} mr="8px" cursor="pointer" w={4} h={4} />
            {history.location.state.item.campaign_team_name}'s transactions
          </Flex>
          <div className="operate-content">
            {/* <span className="operate-btn" onClick={onAddTrans}>
              Add transaction
            </span> */}
            <DefaultButton
              onClick={onAddTrans}
              name='Add transaction'
              width="140px"
            ></DefaultButton>
          </div>
        </div>
        <div className="transaction-content">
          <div className="search-data-query">
            <div className="query-title">Filter</div>
            <Query
              onSearch={(query, type) => onSearchTable(query, type)}
            />
          </div>
          <div className="query-table">
            <div className="table-content">
              <CustomTable
                isChecked={false}
                columns={columns()}
                data={dataList}
              />
            </div>
            <div className="view-transaction-pagination">
              <Pagination
                onChange={(pagination) => {
                  
                  setPage(pagination)
                  getTranscationList(pagination, queryForm)
                }}
                paginations={page}
                data={dataList}
                total={total}
              ></Pagination>

            </div>
          </div>
        </div>
      </div>
      <AddtransDialog ref={addTransDialogRef} onAdd={onAdd}></AddtransDialog>

      {/* <DetailDialog ref={detailDialogRef} ></DetailDialog> */}
    </div>
  );
}
export default PolymofundHome
