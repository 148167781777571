import React from "react";
import { message } from 'antd';

import { useHistory } from "react-router-dom";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Field, Form, Formik } from 'formik';
import useAuth from '../../../auth/useAuth'

import './index.scss'

function ForgetPassword(props) {
  const {resetPassword} = useAuth();
  
  const validateEmail = (value) => {
    let error 
    if (!value) {
      error = 'Email is required'
    } else {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(value)) {
        error = 'Please enter the correct email address'
      }
    }
    return error
  }
 

  const history = useHistory();
  const onCancle = () => {
    history.push('/polymo/home');
  }
  const toSignIn = () => {
    history.push('/polymo/sign-in');
  }
  return (
    <Flex className="forget-password-page">
      <div className="forget-password-card">
        <div className="title">Forget password</div>
        <Formik
          initialValues={{ email: '', password: '', firstname: '', lastname: '' }}
          onSubmit={(values, actions) => {
            try {
              // console.log(values, actions)
              // handleSubmit(values.email, values.password)
              resetPassword?.(values.email)
                .then((res) => {
                    // // console.log(res)
                    // TODO 注册成功跳转到登录页面
                    history.push('/polymo/sign-in');
                  }).catch((err) => {
                    message.error(err.errors[0].message, 4);
                    // // console.log(err.errors)
                  })
                  
            } catch (error) {
              // console.log(error, '22')
            } finally {
            }
            actions.setSubmitting(false)
          }}
        >
          {(props) => (
            <Form className="sing-in-form">
              <Field name='email' validate={validateEmail}>
                {({ field, form }) => (
                  <FormControl isRequired isInvalid={form.errors.email && form.touched.email}>
                    <FormLabel className="name">Email</FormLabel>
                    <Input className="input-h48" form="novalidateform" {...field} placeholder='Email' focusBorderColor='#3D5A80'  errorBorderColor='#E53E3E' />
                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <div className="sign-operate">
                <Button
                  type="button"
                  _hover= {{
                    background: '#fff',
                    border: '2px solid #5BA4FA'
                  }}
                  background="#fff"
                  color="rgb(40, 58, 83)"
                  border="1px solid rgb(40, 58, 83)" 
                  borderRadius="6px"
                  isLoading={props.isSubmitting}
                  w='109px'
                  marginRight="24px"
                  onClick={onCancle}
                  fontFamily="Inter"
                >
                  Cancel
                  </Button>
                <Button
                  borderRadius="6px"
                  _hover= {{
                    background: 'rgb(40, 58, 83);',
                    border: '2px solid #5BA4FA'
                  }}
                  _click= {{
                    background: 'rgb(40, 58, 83);',
                  }}
                  background="rgb(40, 58, 83);"
                  color="#fff"
                  isLoading={props.isSubmitting}
                  type='submit'
                  w='109px'
                  fontFamily="Inter"
                >
                  Send
                  </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {/* <p className="to-forget-password">
        <span className="sign-in" onClick={toSignIn}>Sign in</span>
      </p> */}
    </Flex>
  );

}

export default ForgetPassword;
