/* eslint-disable */
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { successMessage } from '@utils/message'
import DeleteSmsDialog from "./delete-sms";

import CustomTable from "components/table";
import { SmsColumns } from "../config";

// import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import DefaultButton from 'components/button'
import AddTemplateDialog from "./add-template";
import { postMethod } from 'api/index';

const TemplateMessageDialog = forwardRef((props, ref) => {
  const { onDeleteRefresh } = props;
  // const history = useHistory();
  const userInfo = useSelector((state) => state.userInfos)
  const [visiable, setVisiable] = useState(false);
  const [teamInfo, setTeamInfo] = useState({});
  const [templateList, setTemplatelist] = useState([]);
  const addTemplateDialogRef = React.useRef();
  const deleteSmsTemplateDialogRef = React.useRef();

  useImperativeHandle(ref, () => ({
    show: (info, list) => {
      setTeamInfo(info);
      setTemplatelist(list)
      setVisiable(true);
    }
  }))

  const onEditMessage = (item) => {
    addTemplateDialogRef.current.add(teamInfo, 'Edit', item);
  }

  const onClose = () => {
    setVisiable(false);
  }

  const onRefreshTemplate = () => {
    // 获取短信模版
    getTemplateMessageList();
  }

  const getTemplateMessageList = (type) => {
    let params = {
      account_id: userInfo.account_id,
      campaign_team_id: teamInfo.campaign_team_id
    }
    postMethod(`/app/sms/plan/template/page/1/10`, params).then((res) => {
      if (res && res.code === 200) {
        setTemplatelist(res?.data.list)
        if(type === 'delete') { 
          onDeleteRefresh(res?.data.list)
        }
      }
    })
  }

  const onSureDeleteSms = (item) => {
    postMethod(`/app/sms/plan/template/delete/${item.id}`).then((res) => {
      if (res && res.code === 200) {
        successMessage('success')
        getTemplateMessageList('delete')
      }
    })
  }
  return (
    <>
      <Modal size='2xl' isOpen={visiable} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Sms template </ModalHeader>
          <ModalCloseButton _focus={{
            boxShadow: "none",
            border: "none"
          }} />
          <ModalBody className="default-el-table">
            <CustomTable
              
              columns={SmsColumns({
                deleteDialog: (row) => {
                  deleteSmsTemplateDialogRef.current.delete(row);
                },
                editDialog: (row) => {
                  onEditMessage(row)
                }

              })}
              data={templateList}
            />
          </ModalBody>
          <ModalFooter>
            <DefaultButton
              onClick={onClose}
              type="button"
              name='Cancel'
              width="109px"
              color="#000"
              background="#EDF2F7"
              marginLeft="12px"
            ></DefaultButton>
          </ModalFooter>
        </ModalContent>
      </Modal >
      <AddTemplateDialog ref={addTemplateDialogRef} onRefresh={onRefreshTemplate}></AddTemplateDialog>
      <DeleteSmsDialog ref={deleteSmsTemplateDialogRef} onDeleteSms={onSureDeleteSms}></DeleteSmsDialog>
    </>
  );
})

export default TemplateMessageDialog;
