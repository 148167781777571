/* eslint-disable */
import {
  Flex,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Select,
  Option,
  FormErrorMessage,
} from "@chakra-ui/react";
import React, { forwardRef, useImperativeHandle, useState } from "react";
// import { DatePicker, Select as SelectAntd } from 'antd';

import { Field, Form, Formik } from 'formik';

import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import DefaultButton from 'components/button'

import { getMethod, postMethod } from 'api/index';

const AddTranscationDialog = forwardRef((props, ref) => {
  const { onAdd } = props;
  const history = useHistory();
  const userInfo = useSelector((state) => state.userInfos)
  const [visiable, setVisiable] = useState(false);
  const borderRadius = '6px'
  const [teamInfo, setTeamInfo] = useState({})
  const [committeeList, setCommitteeList] = useState([])
  useImperativeHandle(ref, () => ({
    add: (info) => {
      setTeamInfo(info)
      setVisiable(true);
      // getCommitteeList();
      getPaymentList()
      setCommitteeList([{
        committee_sboe_id: info.committee_sboe_id,
        name: info.campaign_team_name
      }])
    }
  }))

  // const getCommitteeList = () => {
  //   getMethod('/app/committee/list').then((res) => {
  //     if (res && res.code === 200) {
  //       setCommitteeList(res.data)
  //     }
  //   })
  // }
  const [paymentList, setPaymentList] = useState([])
  const getPaymentList = () => {
    getMethod('/app/payment/list').then((res) => {
      if (res && res.code === 200) {
        setPaymentList(res.data)
      }
    })
  }
  const onClose = () => {
    setVisiable(false);
  }
  const validateDonorName = (value) => {
    let error
    if (!value) {
      error = 'Donor name is required'
    }
    return error
  }
  const validateAmount = (value) => {
    let error
    if (!value) {
      error = 'Amount is required'
    } else if (!(/^[0-9]+([.]{1}[0-9]+){0,1}$/.test(value))) {
      error = 'Please enter a number'
    }
    return error
  }
  const validateCommittee = (value) => {
    let error
    if (!value) {
      error = 'Committee is required'
    }
    return error
  }
  const validatePayment = (value) => {
    let error
    if (!value) {
      error = 'Payment is required'
    }
    return error
  }
  const validateDate = (value) => {
    let error
    if (!value) {
      error = 'Date is required'
    } else if(!(/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|(1|2)[0-9]|30|31)$/.test(value))) {
      error = 'Please enter the correct date'
    }
    return error
  }
  const validateAddress1 = (value) => {
    let error
    if (value.length > 100) {
      error = 'Address1 maximum length is 100'
    }
    return error
  }
  const validateAddress2 = (value) => {
    let error
    if (value.length > 100) {
      error = 'Address2 maximum length is 100'
    }
    return error
  }
  const validateCity = (value) => {
    let error
    if (!value) {
      error = 'City is required'
    }
    return error
  }
  const validateState = (value) => {
    let error
    if (!value) {
      error = 'State is required'
    }
    return error
  }
  const validateZipCode = (value) => {
    let error
    if (!value) {
      error = 'Zip code is required'
    }
    return error
  }
  return (
    <Modal size='2xl' isOpen={visiable} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add transaction</ModalHeader>
        <ModalCloseButton _focus={{
          boxShadow: "none",
          border: "none"
        }} />
        <ModalBody>
          <Formik
            initialValues={{ 
              donor_name: '', 
              amount: '', 
              committee_sboe_id: teamInfo.committee_sboe_id || '', 
              form_of_payment: '', 
              date_occured: '', 
              address1: '', 
              address2: '',
              city: '',
              state: '',
              zip_code: ''
            }}
            onSubmit={(values, actions) => {
              try {
                // console.log(values)
                let params = {
                  ...values,
                  account_id: userInfo.account_id || '',
                  campaign_id: teamInfo.campaign_team_id,
                }
                postMethod('/app/transaction', params).then((res) => {
                  if (res && res.code === 200) {
                    setVisiable(false);
                    onAdd()
                  }
                })
                actions.setSubmitting(false)
              } catch (error) {
                // console.log(error, '22')
              } finally {
              }
            }}
          >
            {(props) => (
              <Form className="form-flex">
                <Flex>
                  <Field name='donor_name' validate={validateDonorName}>
                    {({ field, form }) => (
                      <FormControl className="form-control" isRequired isInvalid={form.errors.donor_name && form.touched.donor_name} mr="111px">
                        <div className="control">
                          <FormLabel className="label">Donor name</FormLabel>
                          <Input className="input-h32" focusBorderColor='#3D5A80' errorBorderColor='#E53E3E' form="novalidateform" {...field} placeholder='Donor name' />
                        </div>
                        <FormErrorMessage className="error-mes">{form.errors.donor_name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Flex>
                <Flex>
                  <Field name='amount' validate={validateAmount}>
                    {({ field, form }) => (
                      <FormControl className="form-control" isRequired isInvalid={form.errors.amount && form.touched.amount} mr="111px">
                        <div className="control">
                          <FormLabel className="label">Amount</FormLabel>
                          <Input className="input-h32" focusBorderColor='#3D5A80' errorBorderColor='#E53E3E' form="novalidateform" {...field} placeholder='Amount' />
                        </div>
                        <FormErrorMessage className="error-mes">{form.errors.amount}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Flex>
                <Flex>
                  <Field name='committee_sboe_id' validate={validateCommittee}>
                    {({ field, form }) => (
                      <FormControl className="form-control" isRequired isInvalid={form.errors.committee_sboe_id && form.touched.committee_sboe_id} mr="111px">
                        <div className="control">
                          <FormLabel className="label">Committee</FormLabel>
                          <Select disabled className="input-h32" placeholder='Select your committee' form="novalidateform" {...field}>
                            {
                              committeeList?.map((item, index) => {
                                return <option key={index} value={item.committee_sboe_id}>{item.name}</option>
                              })
                            }
                          </Select>
                        </div>
                        <FormErrorMessage className="error-mes">{form.errors.committee_sboe_id}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Flex>
                <Flex>
                  <Field name='form_of_payment' validate={validatePayment}>
                    {({ field, form }) => (
                      <FormControl className="form-control" isRequired isInvalid={form.errors.form_of_payment && form.touched.form_of_payment} mr="111px">
                        <div className="control">
                          <FormLabel className="label">Payment</FormLabel>
                          <Select className="input-h32" placeholder='Select your payment' form="novalidateform" {...field}>
                            {
                              paymentList?.map((item, index) => {
                                return <option key={index} value={item.payment_type_name}>{item.payment_type_name}</option>
                              })
                            }
                          </Select>
                        </div>
                        <FormErrorMessage className="error-mes">{form.errors.form_of_payment}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Flex>
                <Flex>
                  <Field name='date_occured' validate={validateDate}>
                    {({ field, form }) => (
                      <FormControl className="form-control" isRequired isInvalid={form.errors.date_occured && form.touched.date_occured} mr="111px">
                        <div className="control">
                          <FormLabel className="label">Date</FormLabel>
                          <Input className="input-h32" focusBorderColor='#3D5A80' errorBorderColor='#E53E3E' form="novalidateform" {...field} placeholder='2023-01-01' />
                        </div>
                        <FormErrorMessage className="error-mes">{form.errors.date_occured}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Flex>
                <Flex>
                  <Field name='address1' validate={validateAddress1}>
                    {({ field, form }) => (
                      <FormControl className="form-control" isInvalid={form.errors.address1 && form.touched.address1} mr="111px">
                        <div className="control">
                          <FormLabel className="label">Address1</FormLabel>
                          <Input className="input-h32" focusBorderColor='#3D5A80' errorBorderColor='#E53E3E' form="novalidateform" {...field} placeholder='address1' />
                        </div>
                        <FormErrorMessage className="error-mes">{form.errors.address1}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Flex>
                <Flex>
                  <Field name='address2' validate={validateAddress2}>
                    {({ field, form }) => (
                      <FormControl className="form-control" isInvalid={form.errors.address2 && form.touched.address2} mr="111px">
                        <div className="control">
                          <FormLabel className="label">Address2</FormLabel>
                          <Input className="input-h32" focusBorderColor='#3D5A80' errorBorderColor='#E53E3E' form="novalidateform" {...field} placeholder='address2' />
                        </div>
                        <FormErrorMessage className="error-mes">{form.errors.address2}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Flex>
                <Flex>
                  <Field name='city' validate={validateCity}>
                    {({ field, form }) => (
                      <FormControl className="form-control" isRequired isInvalid={form.errors.city && form.touched.city} mr="111px">
                        <div className="control">
                          <FormLabel className="label">City</FormLabel>
                          <Input className="input-h32" focusBorderColor='#3D5A80' errorBorderColor='#E53E3E' form="novalidateform" {...field} placeholder='City' />
                        </div>
                        <FormErrorMessage className="error-mes">{form.errors.city}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Flex>
                <Flex>
                  <Field name='state' validate={validateState}>
                    {({ field, form }) => (
                      <FormControl className="form-control" isRequired isInvalid={form.errors.state && form.touched.state} mr="111px">
                        <div className="control">
                          <FormLabel className="label">State</FormLabel>
                          <Input className="input-h32" focusBorderColor='#3D5A80' errorBorderColor='#E53E3E' form="novalidateform" {...field} placeholder='State' />
                        </div>
                        <FormErrorMessage className="error-mes">{form.errors.state}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Flex>
                <Flex>
                  <Field name='zip_code' validate={validateZipCode}>
                    {({ field, form }) => (
                      <FormControl className="form-control" isRequired isInvalid={form.errors.zip_code && form.touched.zip_code} mr="111px">
                        <div className="control">
                          <FormLabel className="label">Zip code</FormLabel>
                          <Input className="input-h32" focusBorderColor='#3D5A80' errorBorderColor='#E53E3E' form="novalidateform" {...field} placeholder='Zip code' />
                        </div>
                        <FormErrorMessage className="error-mes">{form.errors.zip_code}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Flex>
                <Flex justifyContent="flex-end" mt="80px">
                  {
                    <div>
                      {/* <Button
                        _hover={{
                          background: '#EDF2F7',
                          border: '2px solid #5BA4FA'
                        }}
                        onClick={onClose} w="109px" background="#EDF2F7" colorScheme='#EDF2F7' color="#000" borderRadius={borderRadius} fontFamily="Inter" mr="12px" >Cancel</Button>
                      <Button
                        type='submit'
                        _hover={{
                          background: 'rgb(40, 58, 83);',
                          border: '2px solid #5BA4FA'
                        }}
                        _click={{
                          background: 'rgb(40, 58, 83);',
                        }}
                        background="rgb(40, 58, 83);"
                        colorScheme='rgb(40, 58, 83);'
                        color="#fff"
                        borderRadius={borderRadius}
                        fontFamily="Inter"
                        w="109px"
                      >Add</Button> */}
                      <DefaultButton
                        onClick={onClose}
                        type="button"
                        name='Cancel'
                        width="109px"
                        color="#000"
                        background="#EDF2F7"
                        marginLeft="12px"
                      ></DefaultButton>
                      <DefaultButton
                        name='Add'
                        type='submit'
                        width="109px"
                        marginLeft="12px"
                      ></DefaultButton>
                    </div>
                  }
                </Flex>
              </Form>
            )}
          </Formik>
        </ModalBody>
        <ModalFooter>
          {/* <Button ref={cancelRef} onClick={onClose}
            type="button"
            _hover={{
              background: '#EDF2F7',
            }}
            background="#EDF2F7"
            color="#1A202C"
            borderRadius="6px"
            w='109px'
            marginRight="24px">
            Cancel
        </Button>
          <Button onClick={onSure} type='submit' 
          borderRadius="6px"
          _hover= {{
            background: 'rgb(40, 58, 83);',
          }}
          _click= {{
            background: 'rgb(40, 58, 83);',
          }}
          background="rgb(40, 58, 83);"
          color="#fff"
          isLoading={props.isSubmitting}
          w='109px' >Add</Button> */}
        </ModalFooter>
      </ModalContent>
    </Modal>

  );
})

export default AddTranscationDialog;
