
import React, {useState, useEffect} from "react";

import UploadDonorDialog from "./components/upload-donor";
import UploadCommitteeDialog from "./components/upload-committee";
import UploadTransactionDialog from "./components/upload-transaction";
import { postMethod } from 'api/index';
import { successMessage } from '@utils/message'

import {
  Box,
  Flex,
  Button,
} from "@chakra-ui/react";
import DefaultButton from 'components/button'

export default function Settings() {
  const UploadDonorDialogRef = React.useRef();
  const UploadCommitteeDialogRef = React.useRef();
  const UploadTransactionDialogRef = React.useRef();

  useEffect(() => {
  }, [])
  
  const onRefresh = () => {
  }

  /**
   * 全选单选 选中数据
  */
 const emitSelectAll = (list) => {
  // // console.log(list)
}
  const borderRadius= "8px"

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Box p="0 100px" color="red" fontWeight="600">
      This tool does not provide data rollback function. Please back up the data in the database before synchronize data!
      </Box>
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Box border="1px solid #ddd" mt="10px" borderRadius="5px" w="300px" textAlign="center" p="20px">
          <Box marginBottom="10px">Synchronize donor data</Box>
          <DefaultButton
            onClick={() => {
              // UploadDonorDialogRef.current.setRow({});
              postMethod('/manage/db/donor/import').then((res) => {
                if(res && res.code === 200) {
                  successMessage('success')
                }
              })
            }}
            name='Synchronize'
            width="165px"
          ></DefaultButton>
        </Box>
        <Box border="1px solid #ddd" mt="20px" borderRadius="5px" w="300px" textAlign="center" p="20px">
          <Box marginBottom="10px">Synchronize committee data</Box>
          <DefaultButton
            onClick={() => {
              // UploadCommitteeDialogRef.current.setRow({});
              postMethod('/manage/db/committee/import').then((res) => {
                if(res && res.code === 200) {
                  successMessage('success')
                }
              })
            }}
            name='Synchronize'
            width="165px"
          ></DefaultButton>
        </Box>
        <Box border="1px solid #ddd" mt="20px" borderRadius="5px" w="300px" textAlign="center" p="20px">
          <Box marginBottom="10px">Synchronize transaction data</Box>
          <DefaultButton
            onClick={() => {
              // UploadTransactionDialogRef.current.setRow({});
              postMethod('/manage/db/transaction/import').then((res) => {
                if(res && res.code === 200) {
                  successMessage('success')
                }
              })
            }}
            name='Synchronize'
            width="165px"
          ></DefaultButton>
        </Box>
      </Flex>
      
      <UploadDonorDialog ref={UploadDonorDialogRef} ></UploadDonorDialog>
      <UploadCommitteeDialog ref={UploadCommitteeDialogRef} ></UploadCommitteeDialog>
      <UploadTransactionDialog ref={UploadTransactionDialogRef} ></UploadTransactionDialog>

    </Box>
  );
}
