import * as type from '../type.js';
const initState = {
    loading: false
};

const fetchLoading = (state = initState, action = {}) => {
    if(action.type === type.LOADING) {
        return Object.assign({}, state, action.infos);
    } 
    return state
};
export default fetchLoading;