// Chakra Imports
import React, { useState, useEffect } from 'react';
import {
	Box,
	Flex,
	// Menu,
	// MenuButton,
	// MenuItem,
	// MenuList,
	// Text,
	// useColorModeValue,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
// import AdminNavbarLinks from 'components/navbar/NavbarLinksAdmin';
import '@/assets/css/navbar.scss'

import routes from "../../routes.js";
import useAuth from 'auth/useAuth'
import { setUserCmsInfo } from 'store/action/userCmsInfos.js'

export default function AdminNavbar(props) {
	// let menuBg = useColorModeValue('white', 'navy.800');
	// const shadow = useColorModeValue(
	// 	'14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
	// 	'14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
	// );
	const {logout} = useAuth();

	const [scrolled, setScrolled] = useState(false);
	const dispatch = useDispatch();
	// const userInfo = useSelector((state) => state)
	useEffect(() => {
		window.addEventListener('scroll', changeNavbar);
		return () => {
			window.removeEventListener('scroll', changeNavbar);
		};
	});
	let navbarPosition = 'fixed';
	let gap = '0px';
	const changeNavbar = () => {
		if (window.scrollY > 1) {
			setScrolled(true);
		} else {
			setScrolled(false);
		}
	};

	const history = useHistory();
	const urlRoute = history.location.pathname;
	
	const onGoPolymofund = () => {
		// history.push('/polymo/learn-more')
	}
	const signOut = () => {
		logout().then(() => {
			dispatch(setUserCmsInfo({}));
			window.localStorage.removeItem('cmstoken');
			window.localStorage.removeItem('account_cms_email');
			history.push('/cms/sign-in');
		})
	};
	return (
		<Box
			position={navbarPosition}
			h='93px'
			background="white"
			pl="48px"
			pr="48px"
			w={{ base: 'calc( 100% - 290px )', xl: 'calc( 100% - 290px )' }}>
			<Flex
				w='100%'
				justifyContent="space-between"
				alignItems={{ xl: 'center' }}
				mb={gap}>
				<Box className="navbar-content" lineHeight='93px' fontSize="24px" fontWeight="700" color='#283A53'>
					{/* <div className="page-name" onClick={onGoPolymofund}>
						<span>Polymo</span><span style={{ color: '#FFBC0A' }}>Fund</span>
					</div> */}
				</Box>
				<Box lineHeight='93px' fontSize="16px" cursor="pointer" onClick={signOut}>Sign out</Box>
			</Flex>
		</Box>
	);
}

AdminNavbar.propTypes = {
	brandText: PropTypes.string,
	variant: PropTypes.string,
	secondary: PropTypes.bool,
	fixed: PropTypes.bool,
	onOpen: PropTypes.func
};
