import {get, post} from '../utils/request'

// 查询公共donor
export function publicDonorList(url,data) {
  return post({
      url,
      data,
      cancel: true
  })
}

// 添加donor
export function addPublicDonorList(url,data) {
  return post({
      url,
      data,
      cancel: true
  })
}