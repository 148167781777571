import {get, post} from '../utils/request'

// 更新用户信息
export function updateUserInfo(url,data) {
  return post({
      url,
      data,
      cancel: true
  })
}

// 更改密码
export function restPassword(url,data) {
  return post({
      url,
      data,
      cancel: true
  })
}

// 提交subscribe
export function subscribePublic(url,data) {
    return post({
        url,
        data,
        cancel: true
    })
}
