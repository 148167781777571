
import React, {useState, useEffect} from "react";
import CustomTable from "components/table";
import Pagination from "components/pagination";

import Card from "components/card/Card";

import Dialog from "./components/add-or-edit";
import DeleteDialog from "./components/delete";
// import DetailDialog from "./components/detail";

import {
  columns,
} from "./config";

import { getMethod, postMethod } from 'api/index';

import {
  // Flex,
  Box,
  Input,
  Text,
  Button,
  SimpleGrid,
} from "@chakra-ui/react";
export default function Settings() {
  // Chakra Color Mode
  const [query, setVal] = React.useState({
    name: '',
  });
  const [total, setTotal] = useState(0)
  /**
   * list 数据
   */
  const [dataList, setData] = React.useState([]);
  const [pagination, setPage] = React.useState({
    currentPage:1,
    pageSize: 10,
  }); 
  
  const dialogRef = React.useRef();
  const deleteDialogRef = React.useRef();
  const detailDialogRef = React.useRef();

  useEffect(() => {
    getList(pagination, {})
  }, [pagination.currentPage])

  const getList = (pagination, params = {}) => {
    // let newParams = {}
    // if(Object.keys(params)) {
    //   for(let i in params) {
    //     if(params[i]) {
    //       newParams[i] = params[i]
    //     }
    //   }
    // }
    postMethod(`/manage/committee/lost/page/${pagination.currentPage}/${pagination.pageSize}`).then((res) => {
      if(res && res.code=== 200) {
        setData(res.data.list)
        setTotal(res.data.total_count);
      }
    })
  }
  const handleInputChange = (e, key) => {
    setVal({
      ...query,
      [key]: e.target.value
    })
  }
  
  const onRefresh = () => {
    getList(pagination, query)
  }

  const queryList = (searchQuery = {}) => {
    getList(pagination, query)
  }

  const onSureDelete = (row) => {
    postMethod('/manage/account/delete', {aid: row.id}).then((res) => {
      if(res && res.code === 200) {
        getList(pagination, query)
      }
    })
  }
  /**
   * 全选单选 选中数据
  */
 const emitSelectAll = (list) => {
  // // console.log(list)
}
  const borderRadius= "8px"

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Box textAlign="right" mb="12px">
        {/* <Text h="32px" display="inline-block" lineHeight="32px">donor name</Text><Input w="180px" ml="12px" value={query.name} onChange={(e) => handleInputChange(e, 'name')} /> */}
        {/* <Text h="32px" display="inline-block" lineHeight="32px" ml="12px">phone</Text><Input w="120px" ml="12px" value={query.phone} onChange={(e) => handleInputChange(e, 'phone')} /> */}
        {/* <Button borderRadius={borderRadius} ml="8px" onClick={() => {
          queryList()
        }}>查询</Button>
        <Button borderRadius={borderRadius} ml="8px" onClick={() => {
          setVal({
            name: '',
          })
          getList(pagination, {})
        }}>重置</Button> */}
      </Box>
      
        <SimpleGrid
          mb='0px'
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: "20px", xl: "20px" }}>
          <Card
            mt="12px"
            borderRadius={borderRadius}
            direction='column'
            w='100%'
            px='0px'
            p="0px"
            overflowX={{ sm: "scroll", lg: "scroll" }}>
              <CustomTable
                isChecked={false}
                columns={columns({
                  openDialog: (row) => {
                    dialogRef.current.setRow(row);
                  },
                  deleteDialog: (row) => {
                    deleteDialogRef.current.delete(row);
                  },
                  detailDialog: (row) => {
                    detailDialogRef.current.detail(row);
                  }
                })}
                data={dataList}
                emitSelectAll={emitSelectAll}
              />
              <Box mt="32px" mb="30px">
                <Pagination
                  paginations={pagination}
                  data={dataList} 
                  total={total} 
                  onChange={(pagination) => {
                    setPage(pagination)
                    getList(pagination)
                  }}
                ></Pagination>

              </Box>
            </Card>
        </SimpleGrid>
      <Dialog ref={dialogRef} onRefresh={onRefresh}></Dialog>
      <DeleteDialog ref={deleteDialogRef} onDelete={onSureDelete} ></DeleteDialog>
      {/* <DetailDialog ref={detailDialogRef} queryList={queryList}></DetailDialog> */}
    </Box>
  );
}
