import React, { forwardRef, useImperativeHandle, useState } from "react";
/* eslint-disable */
import {
  Flex,
  // Text,
  // Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  FormErrorMessage
} from "@chakra-ui/react";
import { Field, Form, Formik } from 'formik';
import { useSelector } from 'react-redux';

import { successMessage } from '@utils/message'
import DefaultButton from 'components/button'
import { getMethod, postMethod } from 'api/index';


const DevelopmentDialog = forwardRef((props, ref) => {
  // const { onRefresh } = props;
  const [visiable, setVisiable] = useState(false);
  const userInfo = useSelector((state) => state.userInfos)
  const [rowData, setData] = useState({})
  const [smsTemplateList, setSmsTemplateList] = useState([]);
  const [templateContent, setTemplateContent] = useState('')

  useImperativeHandle(ref, () => ({
    setRow: (record, info) => {
      setData(record)
      setVisiable(true);
      getPaymentList(info);
      setTemplateContent('')
    }
  }))
  const getPaymentList = (info) => {
    let params = {
      account_id: userInfo.account_id,
      campaign_team_id: info.campaign_team_id
    }
    postMethod('/app/sms/plan/template/page/1/10', params).then((res) => {
      if (res && res.code === 200) {
        setSmsTemplateList(res?.data.list)
      }
    })
  }

  const handleSelectChange = (e, form) => {
    form.setFieldValue('content', e.target.value)
  }
  // 关闭弹框
  const onClose = () => {
    setVisiable(false);
  }
  const validatePhone = (value) => {
    let error
    if (!value) {
      error = 'Phone is required'
    }
    return error
  }
  const validateContent = (value) => {
    let error
    if (!value) {
      error = 'Sms content is required'
    } else if(value.length > 50 || value.length < 5) {
      error = 'Sms content length must between 5, 50'
    }
    return error
  }
  
  return (
    <Modal size='xl' isOpen={visiable} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Send Message</ModalHeader>
        <ModalCloseButton _focus={{
          boxShadow: "none",
          border: "none"
        }} />
        <ModalBody>
          <Formik
            initialValues={{ phone: rowData.phone_number || '', content: templateContent || '' }}
            onSubmit={(values, actions) => {
              try {
                let params = {
                  account_id: userInfo.account_id,
                  to: values.phone,
                  content: values.content,
                  first_name: rowData.first_name
                }
                postMethod('/api/sms', params).then((res) => {
                  if (res && res.code === 200) {
                    successMessage('success');
                    setVisiable(false);
                  }
                })
                actions.setSubmitting(false)
              } catch (error) {
                // console.log(error, '22')
              } finally {
              }

            }}
          >
            {(formProps) => (
              <Form className="sing-in-form">
                <Field name='phone' validate={validatePhone}>
                  {({ field, form }) => (
                    <FormControl isRequired isInvalid={form.errors.phone && form.touched.phone}>
                      <FormLabel className="name">Phone</FormLabel>
                      <Input disabled className="input-h48" focusBorderColor='#3D5A80' errorBorderColor='#E53E3E' form="novalidateform" {...field} placeholder='Phone' />
                      <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name='template'>
                  {({ field, form }) => (
                    <FormControl isInvalid={form.errors.template && form.touched.template}>
                      <FormLabel className="name">Template</FormLabel>
                      <Select onChange={(e) => handleSelectChange(e, formProps)} className="input-h48" placeholder='Select your template'>
                        {
                          smsTemplateList?.map((item, index) => {
                            return <option key={index} value={item.sms_template_content}>{item.sms_template_name}</option>
                          })
                        }
                      </Select>
                    </FormControl>
                  )}
                </Field>
                <Field name="content" validate={validateContent} mt="10px">
                  {({ field, form }) => (
                    <FormControl isRequired isInvalid={form.errors.content && form.touched.content}>
                      <FormLabel className="name">Content</FormLabel>
                      <Textarea className="input-h48" focusBorderColor='#3D5A80' errorBorderColor='#E53E3E' form="novalidateform" {...field} placeholder='Content' />
                      <FormErrorMessage>{form.errors.content}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                
                <Flex mt="20px" justifyContent="flex-end" className="sign-operate">
                  <DefaultButton
                    onClick={onClose}
                    name='Cancel'
                    width="109px"
                    color="#000"
                    background="#EDF2F7"
                    marginLeft="12px"
                  ></DefaultButton>
                  <DefaultButton
                    name='Sure'
                    type='submit'
                    width="109px"
                    marginLeft="12px"
                  ></DefaultButton>
                </Flex>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
})

export default DevelopmentDialog;
