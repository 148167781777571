import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  
  apiKey: "AIzaSyDAnEjz-SS604Vepm34pUgyVRkbEkRDoHE",
  authDomain: "polymo-flask-api.firebaseapp.com",
  databaseURL: "https://polymo-flask-api-default-rtdb.firebaseio.com",
  projectId: "polymo-flask-api",
  storageBucket: "polymo-flask-api.appspot.com",
  messagingSenderId: "811101062089",
  appId: "1:811101062089:web:b0c4fe95d41b66f3e3de18"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);