import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { RepeatIcon } from '@chakra-ui/icons'
import { getMethod } from 'api/index';

import './index.scss'

const PolymofundPurchase = () => {
  const userInfo = useSelector((state) => state.userInfos)
  const [smsNum, setSmsNum] = useState(0);

  useEffect(() => {
    if (userInfo.account_id) {
      getPlanList();
    }
  }, [userInfo.account_id]);
  const getPlanList = () => {
    getMethod(`/app/sms/count/${userInfo.account_id}`).then((res) => {
      if (res && res.code === 200) {
        setSmsNum(typeof(res.data) === 'number' ? res.data : 0)
      }
    })
  }
  const onClickRefresh = () => {
    getPlanList()
  }
  return (
    <div className="polyfund-service-plan-page">
      <div className="bg-line"></div>
      <div className="polyfund-service-plan-main">
        <div className="polyfund-service-plan-content">
          <div className="content-title">
            <div className="name">Polymo<span style={{ color: '#FFBC0A' }}>Fund</span></div>
          </div>
          <div className="fee">
            <div>
              Your account has {smsNum}  
              <span className="refresh-btn" onClick={onClickRefresh}>
                <RepeatIcon w={4} h={4} />
              </span> 
              text messages left. 
            </div>
            <div>
              Once purchased, paid text message packages are non-refundable.
            </div>
          </div>
          <div className="content">
            <div>
              <div className="content-c">
                {/* 50 */}
                {/* <stripe-buy-button
                  buy-button-id="buy_btn_1OeSaAI2dSxsXaUhzqaR1nAd"
                  publishable-key="pk_test_51Nw8LdI2dSxsXaUh4FQgRyrwK1sI7uf7S41j8RQFrVKW60plIykIpWQUQH0aQFB9bZrfnMSvfO8db4UZTYjIRqIH00hKm645Kk"
                  client-reference-id={userInfo.uid}
                >
                </stripe-buy-button> */}

                {/* 100 */}
                {/* <stripe-buy-button
                  buy-button-id="buy_btn_1OeScFI2dSxsXaUhgifydI6o"
                  publishable-key="pk_test_51Nw8LdI2dSxsXaUh4FQgRyrwK1sI7uf7S41j8RQFrVKW60plIykIpWQUQH0aQFB9bZrfnMSvfO8db4UZTYjIRqIH00hKm645Kk"
                  client-reference-id={userInfo.uid}
                >
                </stripe-buy-button> */}
                <stripe-pricing-table
                  pricing-table-id="prctbl_1P1ILRI2dSxsXaUhdcHskywe"
                  client-reference-id={userInfo.uid}
                  publishable-key="pk_live_51Nw8LdI2dSxsXaUhpOjXB1nD2rQHEv6GjLqnjIq2L5sXez48QacWoY0dEJPixEKqfxuiIt7CwYULRnGShahw5pXx00vtfiUW1L">
                </stripe-pricing-table>
                <stripe-pricing-table 
                  pricing-table-id="prctbl_1P1IHmI2dSxsXaUhVkUzQxIv"
                  client-reference-id={userInfo.uid}
                  publishable-key="pk_live_51Nw8LdI2dSxsXaUhpOjXB1nD2rQHEv6GjLqnjIq2L5sXez48QacWoY0dEJPixEKqfxuiIt7CwYULRnGShahw5pXx00vtfiUW1L">
                </stripe-pricing-table>
              </div>
            </div>
          </div>
          <div className="fee">
            *All plus a tax fee of 2.9%
          </div>
        </div>

      </div>
    </div >
  );
}
export default PolymofundPurchase
