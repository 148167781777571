import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import { ArrowLeftIcon, RepeatIcon, QuestionOutlineIcon } from '@chakra-ui/icons'
import {
  Flex,
  Select,
  Tooltip
} from '@chakra-ui/react';
import CustomTable from "components/table";
import Pagination from "components/pagination";
import DeleteTeamDialog from "./components/delete-team";
import DeleteDialog from "./components/delete";
import InviteDialog from "./components/invite";
import AddtransDialog from "./components/add-transcation";
import PulltransDialog from "./components/pull-transcation";
import AddActblueDialog from "./components/add-actblue";
import AddTemplateDialog from "./components/add-template";
import TemplateMessageDialog from "./components/template-message";

import ChangeDialog from "./components/change-auth";
import DefaultButton from 'components/button'

import { columns } from "./config";
// import { cloneDeep } from 'lodash'
import InvitePng from 'assets/img/account/invite.png'
import './index.scss'
import { getMethod, postMethod } from 'api/index';

const PolymofundHome = () => {
  const history = useHistory();
  const userInfo = useSelector((state) => state.userInfos)
  const inviteDialogRef = React.useRef();

  const deleteTeamDialogRef = React.useRef();
  const deleteDialogRef = React.useRef();
  const changeAuthDialogRef = React.useRef();
  const addTransDialogRef = React.useRef();
  const pullTransDialogRef = React.useRef();
  const addActDialogRef = React.useRef();
  const addTemplateDialogRef = React.useRef();
  const templateMessageDialogRef = React.useRef();

  const [teamName, setTeamName] = useState('');

  const [teamInfo, setTeamInfo] = useState({});

  const [teamList, setTeamList] = useState([]);

  const [wordBookList, setWorkBookList] = useState([]);

  const [transcationNum, setTranscationNum] = useState(0);

  const [actblueInfo, setActBlueInfo] = useState({});

  const [templateMesList, setTemplateList] = useState([]);
  const [servicePlan, setServicePlan] = useState('')

  useEffect(() => {
    if (window.localStorage.getItem('user_item')) {
      setTeamInfo(JSON.parse(window.localStorage.getItem('user_item')))
    }
    if (userInfo.account_id && teamInfo.campaign_team_id) {
      setTeamName(teamInfo.campaign_team_name || '');
      // 获取transcation 数量
      getTranscationList();
      // 获取workbook
      getWordList();
      // 获取team member
      getTeamMemberList(page);
      // 获取所有的team
      getTeamList();
      if (userInfo.account_role >= 252) {
        getActBlueInfo();
        // 获取短信模版
        getTemplateMessageList();
      }
    }
  }, [userInfo.account_id, teamInfo.campaign_team_id])

  const changeTeam = (e) => {
    let item = teamList.find((item) => {
      return item.campaign_team_id === e.target.value
    })
    window.localStorage.setItem('user_item', JSON.stringify(item))
    setTeamInfo(item)
  }

  // 获取所以得team
  const getTeamList = () => {
    setTeamList([])
    getMethod(`/app/user/${userInfo?.uid || ''}`).then((res) => {
      if (res && res.code === 200) {
        setServicePlan(res.data.service_plan_team_count)
        setTeamList(res.data.campaign_team_owned || [])
      }
    })
  }

  // 获取transtion
  const getTranscationList = () => {
    setTranscationNum(0)
    getMethod(`/app/transaction/count/${userInfo.account_id}/${teamInfo.campaign_team_id}`).then((res) => {
      if (res && res.code === 200) {
        setTranscationNum(res.data)
      }
    })
  }

  // 获取word
  const getWordList = () => {
    setWorkBookList([])
    let params = {
      account_id: userInfo.account_id,
      campaign_id: teamInfo.campaign_team_id
    }
    postMethod(`/app/workbook/list/1/10`, params).then((res) => {
      if (res && res.code === 200) {
        setWorkBookList(res.data.list)
      }
    })
  }

  // 获取actblue信息
  const getActBlueInfo = () => {
    getMethod(`/app/actblue/detail/${userInfo.account_id}/${teamInfo.committee_sboe_id}`).then((res) => {
      if (res && res.code === 200) {
        setActBlueInfo(res.data)
      }
    })
  }

  const getTemplateMessageList = () => {
    let params = {
      account_id: userInfo.account_id,
      campaign_team_id: teamInfo.campaign_team_id
    }
    postMethod(`/app/sms/plan/template/page/1/10`, params).then((res) => {
      if (res && res.code === 200) {
        setTemplateList(res?.data.list)
        // setActBlueInfo(res.data)
      }
    })
  }

  const [page, setPage] = React.useState({
    currentPage: 1,
    pageSize: 10
  })
  const [total, setTotal] = useState(0)
  // 获取team member
  const getTeamMemberList = (pagination) => {
    setData([])
    setTotal(0)
    getMethod(`/app/team/user/list/${teamInfo.campaign_team_id}/${pagination.currentPage}/${pagination.pageSize}`).then((res) => {
      if (res && res.code === 200) {
        setData(res.data.list)
        setTotal(res.data.total_count)
      }
    })
  }

  // 添加transcation 之后需要重新调取接口
  const onAdd = () => {
    // console.log('add')
    getTranscationList()
  }

  const [dataList, setData] = React.useState([]);
  const [index, setIndex] = useState(0)
  const onChangeAuth = (row, auth) => {
    const roleid = auth === 'Admin' ? 252 : 248
    postMethod(`/app/team/user/update/role/${row.user_id}/${roleid}`).then((res) => {
      if (res && res.code === 200) {
        getTeamMemberList(page);
      }
    })
  }

  // 确实删除
  const deleteUser = (row) => {
    postMethod(`/app/team/user/delete/${row.campaign_team_id}/${row.user_id}`).then((res) => {
      if (res && res.code === 200) {
        if (res.data.is_current) {
          history.push('/polymo/all-teams');
          return
        }
        if (dataList.length === 1 && page.currentPage > 1) {
          setPage({
            ...page,
            currentPage: page.currentPage - 1
          })
          getTeamMemberList({
            currentPage: page.currentPage - 1,
            pageSize: 10
          })
        } else {
          getTeamMemberList(page);
        }
      }
    })
  }

  // Delete Team
  const onDeleteTeam = () => {
    deleteTeamDialogRef.current.delete(teamName);
  }

  const deleteTeam = () => {
    const params = {
      campaign_team_id: teamInfo.campaign_team_id,
      user_id: userInfo.uid
    }
    postMethod('/app/remove/team', params).then((res) => {
      if (res && res.code === 200) {
        history.push('/polymo/all-teams')
      }
    })
  }

  const onCreateWorkbook = () => {
    window.localStorage.setItem('from', 'account')
    history.push('/polymo/create-book', { item: teamInfo })
  }
  const onViewWorkbook = () => {
    history.push('/polymo/switch-campaign', { item: teamInfo })
  }

  const onAddTransaction = () => {
    addTransDialogRef.current.add(teamInfo);
  }

  const onViewTransaction = () => {
    history.push('/polymo/view-transcation', { item: teamInfo })
  }

  const onPullTransaction = () => {
    pullTransDialogRef.current.pull(teamInfo, actblueInfo);
  }

  const onAddArtblue = () => {
    addActDialogRef.current.add(teamInfo, {}, 'Add');
  }

  const onEditArtblue = () => {
    addActDialogRef.current.add(teamInfo, actblueInfo, 'Edit');
  }

  const onAddMessage = () => {
    addTemplateDialogRef.current.add(teamInfo, 'Add', {});
  }

  const onClickTemplate = () => {
    templateMessageDialogRef.current.show(teamInfo, templateMesList);
  }

  const onRefreshTemplate = () => {
    getTemplateMessageList();
  }

  const onDeleteRefresh = (list) => {
    setTemplateList(list)
  }

  // 刷新artblue 信息
  const onRefreshArtblue = () => {
    getActBlueInfo();
  }

  // 刷新transcation
  const onClickRefreshTranscation = () => {
    getTranscationList();
  }

  // 邀请
  const onClickInvite = () => {
    inviteDialogRef.current.invite(teamInfo)
  }

  // 刷新
  const onClickRefresh = () => {
    getTeamMemberList(page);
  }
  // 返回
  const onGoTeam = () => {
    history.push('/polymo/all-teams')
  }
  return (
    <div className="polyfund-account-page">
      <div className="bg-line"></div>
      <div className="polyfund-account-main">
        <div className="account-header">
          <div className="team-name">
            {/* {teamInfo.campaign_team_name} */}
            <Flex alignItems="center">
              <ArrowLeftIcon onClick={onGoTeam} mr="8px" cursor="pointer" w={4} h={4} />
              <Select
                w="auto"
                border="none"
                size="normal"
                onChange={(e) => changeTeam(e)}
                _focus={{
                  border: '1px solid inherit'
                }}
                width="120px" value={teamInfo.campaign_team_id}>
                {
                  teamList && teamList?.map((item, index) => {
                    return <option key={index} value={item.campaign_team_id}>{item.campaign_team_name}</option>
                  })
                }
              </Select>
            </Flex>
          </div>
          <div className="delete-btn" onClick={onDeleteTeam}>
            Delete team
          </div>
        </div>
        <div className="card-content message-content">
          {/* workbook 有数据&无数据 */}
          {
            wordBookList.length
              ?
              <div className="message-con">
                <div className="text">{teamInfo.campaign_team_name}'s workbook</div>
                {/* <div className="mes-btn" onClick={onViewWorkbook}>View workbook</div> */}
                <DefaultButton
                  onClick={onViewWorkbook}
                  name='View workbook'
                  width="165px"
                  background="#fff"
                  color="rgb(40, 58, 83);"
                  border="1px solid #3D5A80"
                ></DefaultButton>
              </div>
              :
              <div className="message-con">
                <div className="text">No workbook</div>
                {/* <div className="mes-btn" onClick={onCreateWorkbook}>Create workbook</div> */}
                <DefaultButton
                  onClick={onCreateWorkbook}
                  name='Create workbook'
                  width="165px"
                  background="#fff"
                  color="rgb(40, 58, 83);"
                  border="1px solid #3D5A80"
                ></DefaultButton>
              </div>
          }
        </div>

        <div className="card-content message-content transactions-content">
          {
            transcationNum > 0
              ?
              <div className="message-con">
                <div className="text">
                  You have {transcationNum} transactions
                  <span className="transcation-btn" onClick={onClickRefreshTranscation}>
                    <RepeatIcon w={4} h={4} />
                  </span>
                </div>
                <div style={{ display: 'flex' }}>
                  {/* <div className="mes-btn" style={{marginRight:'12px'}} onClick={onAddTransaction}>Add transaction</div> */}
                  <DefaultButton
                    onClick={onAddTransaction}
                    name='Add transaction'
                    width="165px"
                    background="#fff"
                    color="rgb(40, 58, 83);"
                    border="1px solid #3D5A80"
                  ></DefaultButton>
                  {/* <div className="mes-btn" style={{marginRight:'12px'}} onClick={onViewTransaction}>View transaction</div> */}
                  <DefaultButton
                    onClick={onViewTransaction}
                    name='View transaction'
                    width="156px"
                    background="#fff"
                    color="rgb(40, 58, 83);"
                    border="1px solid #3D5A80"
                    marginLeft="12px"
                  ></DefaultButton>
                </div>
              </div>
              :
              <div className="message-con">
                <div className="text">
                  No transaction
                  <span className="transcation-btn" onClick={onClickRefreshTranscation}>
                    <RepeatIcon w={4} h={4} />
                  </span>
                </div>
                <div style={{ display: 'flex' }}>
                  {/* <div className="mes-btn" style={{marginRight:'12px'}} onClick={onAddTransaction}>Add transaction</div> */}
                  <DefaultButton
                    onClick={onAddTransaction}
                    name='Add transaction'
                    width="165px"
                    background="#fff"
                    color="rgb(40, 58, 83);"
                    border="1px solid #3D5A80"
                  ></DefaultButton>
                  {/* {
                  userInfo.account_role >= 252 ? 
                  <div className="mes-btn" onClick={onViewTransaction}>Pull transaction</div> : null
                } */}
                </div>
              </div>
          }
        </div>
        {
          (userInfo.account_role >= 252 && servicePlan !== 1) ?
            <div className="card-content message-content transactions-content">
              <div className="message-con">
                <div className="text">Actblue</div>
                <div style={{ display: 'flex' }}>
                  {
                    Object.keys(actblueInfo).length ?
                      <DefaultButton
                        onClick={onEditArtblue}
                        name='Edit account'
                        width="165px"
                        background="#fff"
                        color="rgb(40, 58, 83);"
                        border="1px solid #3D5A80"
                        marginLeft="12px"
                      ></DefaultButton> :
                      <DefaultButton
                        onClick={onAddArtblue}
                        name='Add account'
                        width="165px"
                        background="#fff"
                        color="rgb(40, 58, 83);"
                        border="1px solid #3D5A80"
                        marginLeft="12px"
                      ></DefaultButton>
                  }
                  {
                    Object.keys(actblueInfo).length ?
                      <DefaultButton
                        onClick={onPullTransaction}
                        name='Pull transaction'
                        width="165px"
                        background="#fff"
                        color="rgb(40, 58, 83);"
                        border="1px solid #3D5A80"
                        marginLeft="12px"
                      ></DefaultButton> : null
                  }
                </div>
              </div>
            </div> : null
        }
        {
          userInfo.account_role >= 252 ?
          <div className="card-content message-content transactions-content">
            <div className="message-con">
              <div className="text">SMS template
              <Tooltip label='10 SMS templates for each team'>
                <QuestionOutlineIcon boxSize={5} ml="8px" />
              </Tooltip>
              </div>
              <div style={{ display: 'flex' }}>
                {
                  templateMesList.length < 10 ?
                    <DefaultButton
                      onClick={onAddMessage}
                      name='Add template'
                      width="165px"
                      background="#fff"
                      color="rgb(40, 58, 83);"
                      border="1px solid #3D5A80"
                      marginLeft="12px"
                    ></DefaultButton> : null
                }
                {
                  templateMesList.length ?
                    <DefaultButton
                    onClick={onClickTemplate}
                    name='View template'
                    width="165px"
                    background="#fff"
                    color="rgb(40, 58, 83);"
                    border="1px solid #3D5A80"
                    marginLeft="12px"
                  ></DefaultButton> : null
                }
              </div>
            </div>
          </div> : null

        }

        <div className="card-content table-content">
          <div className="team-member">
            <span>Team member</span>
            <div className="operate">
              <span className="invite-btn" onClick={onClickRefresh}>
                <RepeatIcon w={4} h={4} />
              </span>
              <span className="invite-btn" onClick={onClickInvite}><img src={InvitePng} alt="" /> Invite</span>
            </div>
          </div>
          <CustomTable
            isChecked={false}
            columns={columns({
              deleteDialog: (row) => {
                deleteDialogRef.current.delete(row);
              },
              changeAuth: (e, row, index) => {
                setIndex(index)
                changeAuthDialogRef.current.change(row, e.target.value);
              }
            })}
            data={dataList}
          />
          <div className="account-pagination">
            <Pagination
              onChange={(pagination) => {
                // // console.log(pagination)
                setPage(pagination)
                getTeamMemberList(pagination)
              }}
              paginations={page}
              data={dataList}
              total={total}
            ></Pagination>
          </div>
        </div>
      </div>
      <DeleteTeamDialog ref={deleteTeamDialogRef} onDeleteTeam={() => {
        // console.log('刷新')
        deleteTeam()
      }} ></DeleteTeamDialog>
      <DeleteDialog ref={deleteDialogRef} onDelete={(row) => {
        deleteUser(row)
      }} ></DeleteDialog>
      <ChangeDialog ref={changeAuthDialogRef} onChange={(row, auth) => {
        onChangeAuth(row, auth)
      }} ></ChangeDialog>
      <InviteDialog ref={inviteDialogRef}></InviteDialog>
      <AddtransDialog ref={addTransDialogRef} onAdd={onAdd}></AddtransDialog>
      <PulltransDialog ref={pullTransDialogRef}></PulltransDialog>
      <AddActblueDialog ref={addActDialogRef} onRefresh={onRefreshArtblue}></AddActblueDialog>
      <AddTemplateDialog ref={addTemplateDialogRef} onRefresh={onRefreshTemplate}></AddTemplateDialog>
      <TemplateMessageDialog ref={templateMessageDialogRef} onDeleteRefresh={onDeleteRefresh}></TemplateMessageDialog>
    </div>
  );
}
export default PolymofundHome
