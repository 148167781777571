import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import './index.scss'
import icon1 from 'assets/img/home-icon1.svg'
import icon2 from 'assets/img/home-icon2.svg'


const PolymofundAbout = () => {
  const [title] = useState('Privacy Policy')
  const [paymentVoterDate] = useState(
    [
      {
        title: '',
        content: 'This Privacy Policy explains how PolymoAI collects, uses, and discloses information about you when you use our mobile application. By using our app, you agree to the terms of this Privacy Policy.'
      },
      {
        title: 'Information We Collect',
        content: 'We collect personal information that you provide to us, such as your name, address, zip code, phone number, location data and etc. We use this information to provide our services and features within the app.'
      },
      {
        title: 'How We Use Your Information',
        content: 'We use the personal information we collect to:',
        list: [
          'Provide, maintain, and improve our app and services',
          'Communicate with you about your account or transactions',
          'Respond to customer service requests',
          'Personalize your app experience',
          "Send you marketing communications (if you've opted in)",
          'Comply with legal obligations'
        ],
      },
      {
        title: 'Sharing Your Information',
        content: 'We do not sell or rent your personal information to third parties for marketing purposes without your explicit consent. However, we may share your information with trusted third-party service providers that perform services on our behalf, such as hosting providers, analytics providers, and payment processors. These third parties are prohibited from using your personal information except to provide these services, and they are required to maintain the confidentiality of your information.'
      },
      {
        title: '',
        content: 'We may also disclose your personal information if required to do so by law or in response to valid requests by public authorities.'
      },
      {
        title: 'Data Security',
        content: 'We have implemented appropriate physical, technical, and administrative security measures to protect the personal information we collect. However, no data transmission over the internet or data storage system can be guaranteed to be 100% secure.'
      },
      {
        title: 'Your Rights',
        content1: 'You may have certain rights under applicable data protection laws regarding your personal information, including the right to access, correct, delete, or restrict the use of your personal information. Please contact us at',
        email: 'info@polymoai.com',
        content2: 'if you wish to exercise these rights'
      },
      {
        title: "Children's Privacy",
        content: 'Our app is not directed to children under the age of 17, and we do not knowingly collect personal information from children under 17. If we become aware that we have collected personal information from a child under 17, we will take steps to delete such information.'
      },
      {
        title: 'Changes to This Privacy Policy',
        content: 'We may update this Privacy Policy from time to time to reflect changes in our practices or services. We will notify you of any material changes by posting the updated Privacy Policy within the app.'
      },
      {
        title: 'Contact Us',
        content1: 'If you have any questions, please contact us at',
        email: 'info@polymoai.com',
        content2: '.'
      },
    ]
  )
  return (
    <div className="polyfund-home-page">
      <div className="bg-line"></div>
      <div className="polyfund-home-main">
        <div className="polyfund-home-content">
          <div className="content-title">
            <span className="name">
              <img className="icon1" src={icon1} alt="" />
              <img className="icon2" src={icon2} alt="" />
              Polymo<span className="text">AI</span></span>
          </div>

          <div style={{ textAlign: 'center' }}>
            <div style={{ fontSize: 28, fontWeight: 'bold', marginTop: 10, marginBottom: 16 }}>{title}</div>
          </div>
          {
            paymentVoterDate.map((item, index) => {
              return <div key={index}>
                {
                  item.title ?
                    <div className="title">{item.title}</div> : null
                }
                {
                  item.content ?
                    <div className="content">{item.content}</div> : null
                }
                {
                  item.list ? item.list.map((i, j) => {
                    return <div key={j} className="row">
                      <div className="circle"></div>
                      <div className="content">
                        <div>{i}</div>
                      </div>
                    </div>
                  }) : null
                }
                {
                  item.email ?
                    <div className="content">{item.content1} <span className="email" >{item.email}</span> {item.content2}</div> : null
                }
              </div>
            })
          }

        </div>

      </div>
    </div>
  );
}
export default PolymofundAbout
