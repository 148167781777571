import React from "react";

import {
  Flex,
  Text,
} from "@chakra-ui/react";
export const columns = (props) => {
  const {
    terminateDialog=() => {},
    openCmsDialog=() => {},
    openEmailDialog=() => {}
  } = props ?? {}

  return [
    {
      title: "Account name",
      dataIndex: "account_name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Phone number",
      dataIndex: "phone_number",
    },
    {
      title: "Service plan",
      dataIndex: "service_name",
    },
    {
      title: "Service change type",
      dataIndex: "service_change_type",
      render (val, item, index) {
        return val === '1' ? <span>change service plan</span> : <span>terminate service plan</span>
      }
    },
    {
      title: 'Create time',
      dataIndex: "create_time"
    },
    {
      title: "Operation",
      render (val, item, index) {
        return <>
          <Flex mw="180px">
            {/* <Text cursor="pointer" onClick={() => openEmailDialog(item)}  display="inline-block" ml="8px" fontSize='sm' fontWeight='700'>
              email
            </Text> */}
            {/* <Text cursor="pointer" onClick={() => openCmsDialog(item)} display="inline-block" ml="8px" fontSize='sm' fontWeight='700'>
              sms
            </Text> */}
            {/* <Text cursor="pointer" onClick={() => deleteDialog(item)} display="inline-block" ml="8px" fontSize='sm' fontWeight='700'>
              Delete
            </Text> */}
            {
              item.service_change_type === '1' ? <Text cursor="pointer" onClick={() => terminateDialog(item, 'change')} display="inline-block" ml="8px" fontSize='sm' fontWeight='700'>
              change
            </Text>
              : item.readed === -1 ?
              <Text cursor="pointer" onClick={() => terminateDialog(item)} display="inline-block" ml="8px" fontSize='sm' fontWeight='700'>
              Terminate
            </Text> : <Text color="#ccc"  display="inline-block" ml="8px" fontSize='sm' fontWeight='700'>
              done
            </Text>
            }
            
          </Flex>
        </>
      }
    },
  ];
  
}