import React from 'react';
import axios from 'axios'
import { useHistory } from "react-router-dom";
// import { useDispatch } from 'react-redux';
// import { fetchLoading } from 'store/action/loading.js'
import ReactDOM from 'react-dom';
import { Spin } from 'antd';
import { debounce } from 'lodash';
import { Modal } from 'antd';
import { navigatorSource } from '@utils/navigator'

import { ExclamationCircleFilled } from '@ant-design/icons';
// import { setUserInfo } from 'store/action/userInfos.js'

// TODO 请求取消令牌列表
export let cancelArr = [];
//可以使用自定义配置新建一个 axios 实例
var req = axios.create({
    //请求路径
    // `baseURL` 将自动加在 `url` 前面，除非 `url` 是一个绝对 URL。
    // 它可以通过设置一个 `baseURL` 便于为 axios 实例的方法传递相对 URL
    // baseURL: "http://23.251.58.230:8000",
    // 覆写库的超时默认值
    // 现在，在超时前，所有请求都会等待 10 秒
    timeout: 1800000
})

// 定义一个函数用于显示错误消息，并进行防抖处理
const showErrorNotification = debounce((message, type) => {
    hideGlobalLoading();
    Modal.error({
        title: '500 Error. ',
        content: (
            <div>
                <p>Something went wrong. Please try again shortly.</p>
                <p>If you still experiencing error. Please contact us.</p>
            </div>
        ),
        onCancel() {
            // // console.log('Cancel');
        },
    }); 
}, 2000); // 设置防抖的等待时间，比如 2000 毫秒
// 定义一个函数用于显示错误消息，并进行防抖处理
const showErrorCodeNotification = debounce((code, message) => {
    hideGlobalLoading();
    Modal.error({
        title: `${code} Error. `,
        content: (
            <div>
                <p>{message}</p>
            </div>
        ),
        onOk() {
            if(code === 507) {
                if(navigatorSource() === 'PC') {
                    window.open('/polymo/home', '_self');
                } else {
                    window.open('/polymo/sign-in', '_self');
                }
            }
        },
        onCancel() {
            // // console.log('Cancel');
        },
    }); 
}, 2000); // 设置防抖的等待时间，比如 2000 毫秒

const showGlobalLoading = () => {
    hideGlobalLoading();
    const dom = document.createElement('div')
    dom.id = 'globalLoading'
    dom.style = 'width: 100%; height: 100%; position: fixed; top: 0; left: 0; background-color: rgba(255, 255, 255, 0.45); display: flex; justify-content: center; align-items: center; z-index: 90000;';
    document.body.appendChild(dom)
    ReactDOM.render(<Spin fullscreen />, dom)
}
const hideGlobalLoading = () => {
    const dom = document.getElementById('globalLoading')
    if (dom) {
        document.body.removeChild(dom)
    }
}
// 添加请求拦截器
req.interceptors.request.use((config) => {
    // TODO 请求头
    if(window.location.pathname.includes('cms')) {
        config.headers['Authorization'] = window.localStorage.getItem('cmstoken')
    } else if(window.location.pathname.includes('polymo')) {
        config.headers['Authorization'] = window.localStorage.getItem('token')
    }
    if(config.url.indexOf('/campaign') === 0) {
        config.baseURL = 'https://campaign.polymoai.com'
    } else if(config.url.indexOf('/voter') === 0) {
        config.baseURL = 'https://voter.polymoai.com'
    }
    // config.headers['Content-Type'] = 'application/json;charset=UTF-8';
    // TODO 添加取消令牌
    config.cancelToken = new axios.CancelToken(cancel => {
        cancelArr.push(cancel)
    })
    if(config.url !== '/app/fb/user'){
        showGlobalLoading();
    }
    return config;

}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
req.interceptors.response.use((response) => {
    const res = response.data;
    if (res.code !== 200) {
        if (res.code === 401){
            hideGlobalLoading();
			window.localStorage.removeItem('isSignIn');
			window.localStorage.removeItem('token');
			window.localStorage.removeItem('userRole');
			window.localStorage.removeItem('account');
			window.localStorage.removeItem('user_item');
            window.localStorage.removeItem('general_phone');
            if(window.location.pathname.includes('cms')) {
                window.open('/cms/sign-in', '_self');
            } else {
                window.open('/polymo/sign-in', '_self');
            }
        }
        if(res.code === 500){
            showErrorNotification()
        } else if (res.code === 400){
            showErrorCodeNotification(res.code, res.message)
        }else if(res.code === 403) {
            showErrorCodeNotification(res.code, res.message)
        }else if(res.code === 507) {
            showErrorCodeNotification(res.code, res.message)
        }else {
            showErrorNotification()
        }
    } else {
        hideGlobalLoading();
        return res
    }
}, function (error) {
    showErrorNotification();
    hideGlobalLoading();
    // 对响应错误做点什么
    // return Promise.reject(error);
});

export const get = ({ url, data = {}, ...other }) => {
    return req({
        url: url,
        method: "GET",
        params: data,
        ...other
    })
}
export const post = ({ url, data = {} }) => {
    return req({
        url: url,
        method: "POST",
        data: data
    })
}

export default req
