import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import './index.scss'
import icon1 from 'assets/img/home-icon1.svg'
import icon2 from 'assets/img/home-icon2.svg'


const PolymofundHome = () => {
  
  return (
    <div className="polyfund-home-page">
      <div className="bg-line"></div>
      <div className="polyfund-home-main">
        <div className="polyfund-home-content">
          <div className="content-title">
            <span className="name">
              <img className="icon1" src={icon1} alt="" />
              <img className="icon2" src={icon2} alt="" />
              Polymo<span className="text">AI</span></span>
          </div>

          <div className="card-info">
            <p className="title">SMS Disclaimer</p>
            <p className="des">
              By joining a campaign team through invitation code or scanning QR code, users opt in to receive campaign messages to the phone number provided. No purchase necessary. Message frequency varies. Standard message and data rates may apply. Please refer to the confirmation message for the link to opt out.
            </p>
          </div>

        </div>

      </div>
    </div>
  );
}
export default PolymofundHome
