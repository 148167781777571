import React, { forwardRef, useImperativeHandle, useState } from "react";
/* eslint-disable */
import {
  Flex,
  // Text,
  // Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Select,
  FormErrorMessage
} from "@chakra-ui/react";
import { Field, Form, Formik } from 'formik';

import { getMethod, postMethod } from 'api/index';

const DevelopmentDialog = forwardRef((props, ref) => {
  const { onRefresh } = props;
  const [visiable, setVisiable] = useState(false);
  const cancelRef = React.useRef();
  const [rowData, setData] = useState({})

  const [planList, setPlanList] = useState([])
  useImperativeHandle(ref, () => ({
    setRow: (record) => {
      setData(record)
      setVisiable(true);
      getPlanList()
    }
  }))

  const getPlanList = () => {
    getMethod('/manage/service/plan/list').then((res) => {
      if (res && res.code === 200) {
        setPlanList(res.data)
      }
    })
  }

  // 关闭弹框
  const onClose = () => {
    setVisiable(false);
  }
  const validateName = (value) => {
    let error
    if (!value) {
      error = 'Account name is required'
    }
    return error
  }
  const validatePlan = (value) => {
    let error
    if (!value) {
      error = 'Account plan is required'
    }
    return error
  }
  const validateEmail = (value) => {
    let error
    if (!value) {
      error = 'Email is required'
    }
    return error
  }
  const validateExpiration = (value) => {
    let error
    if (!value) {
      error = 'Expiration date is required'
    }
    return error
  }
  return (
    <Modal size='xl' isOpen={visiable} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{rowData.id ? '编辑' : '新增'}</ModalHeader>
        <ModalCloseButton _focus={{
          boxShadow: "none",
          border: "none"
        }} />
        <ModalBody>
          <Formik
            initialValues={{ email: rowData.email || '', name: rowData.name || '', plan: rowData.plan || '', expiration_date: rowData.expiration_date || '' }}
            onSubmit={(values, actions) => {
              try {
                let params = {}
                if(rowData.id) {
                  params = {
                    plan_id: values.plan,
                    account_id: rowData.id,
                    expiration_date: values.expiration_date
                  }
                } else {
                  params = values
                }
                const url = !rowData.id ? '/manage/account/create' : '/manage/account/update'
                postMethod(url, params).then((res) => {
                  if (res && res.code === 200) {
                    setVisiable(false);
                    onRefresh()
                  }
                })
                actions.setSubmitting(false)
              } catch (error) {
              } finally {
              }

            }}
          >
            {(props) => (
              <Form className="sing-in-form">
                <Field name='name' validate={validateName}>
                  {({ field, form }) => (
                    <FormControl isRequired isInvalid={form.errors.name && form.touched.name}>
                      <FormLabel className="name">Account name</FormLabel>
                      <Input disabled={rowData.id} className="input-h48" focusBorderColor='#3D5A80' errorBorderColor='#E53E3E' form="novalidateform" {...field} placeholder='Account name' />
                      <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="plan" validate={validatePlan} mt="10px">
                  {({ field, form }) => (
                    <FormControl isRequired isInvalid={form.errors.plan && form.touched.plan}>
                      <FormLabel className="name">Account plan</FormLabel>
                      <Select className="input-h48" form="novalidateform" errorBorderColor='#E53E3E' placeholder="please choose Account plan" {...field}>
                        {
                          planList?.map((item, index) => {
                            return <option key={index} value={item.id}>{item.service_name}</option>
                          })
                        }
                      </Select>
                      <FormErrorMessage>{form.errors.plan}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name='email' validate={validateEmail} mt="10px">
                  {({ field, form }) => (
                    <FormControl isRequired isInvalid={form.errors.email && form.touched.email}>
                      <FormLabel className="name">Email</FormLabel>
                      <Input disabled={rowData.id} className="input-h48" type="email" focusBorderColor='#3D5A80' errorBorderColor='#E53E3E' className="input-h48" form="novalidateform" {...field} placeholder='Email' />
                      <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                {
                  rowData.id ? <Field name='expiration_date' validate={validateExpiration} mt="10px">
                  {({ field, form }) => (
                    <FormControl isRequired isInvalid={form.errors.expiration_date && form.touched.expiration_date}>
                      <FormLabel className="name">Expiration date</FormLabel>
                      <Input className="input-h48" type="expiration_date" focusBorderColor='#3D5A80' errorBorderColor='#E53E3E' className="input-h48" form="novalidateform" {...field} placeholder='Expiration date' />
                      <FormErrorMessage>{form.errors.expiration_date}</FormErrorMessage>
                    </FormControl>
                  )}
                  </Field> : null
                }
                
                <Flex mt="20px" justifyContent="flex-end" className="sign-operate">
                  <Button
                    type="button"
                    _hover={{
                      background: '#fff',
                      border: '2px solid #5BA4FA'
                    }}
                    background="#fff"
                    color="rgb(40, 58, 83)"
                    border="1px solid rgb(40, 58, 83)"
                    borderRadius="6px"
                    isLoading={props.isSubmitting}
                    w='109px'
                    marginRight="24px"
                    onClick={onClose}
                    fontFamily="Inter"
                  >
                    Cancel
                  </Button>
                  <Button
                    borderRadius="6px"
                    _hover={{
                      background: 'rgb(40, 58, 83);',
                      border: '2px solid #5BA4FA'
                    }}
                    _click={{
                      background: 'rgb(40, 58, 83);',
                    }}
                    background="rgb(40, 58, 83);"
                    color="#fff"
                    isLoading={props.isSubmitting}
                    type='submit'
                    w='109px'
                    fontFamily="Inter"
                  >
                    sure
                  </Button>
                </Flex>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
})

export default DevelopmentDialog;
