import React from "react";

import {
  Flex,
  Text,
} from "@chakra-ui/react";
export const columns = (props) => {
  const {
    // detailDialog=() => {},
    openDialog=() => {},
    deleteDialog=() => {}
  } = props ?? {}

  return [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Version",
      dataIndex: "version",
    },
    {
      title: "Url",
      dataIndex: "url",
    },
    {
      title: "Create time",
      dataIndex: "create_time",
    },
    {
      title: "Create id",
      dataIndex: "create_id",
    },
    {
      title: "Active",
      dataIndex: "active",
      render: (val, item, index) => {
        return <span>{val === 1 ? 'yes' : 'no'}</span>;
      }
    },
    
    // {
    //   title: "Operation",
    //   render (val, item, index) {
    //     return <>
    //       <Flex mw="180px">
    //         <Text cursor="pointer" onClick={() => detailDialog(item)}  display="inline-block" fontSize='sm' fontWeight='700'>
    //           Detail
    //         </Text>
    //         {
    //           item.deleted ? null :
    //           <Text cursor="pointer" onClick={() => openDialog(item)} display="inline-block" ml="8px" fontSize='sm' fontWeight='700'>
    //             Edit
    //           </Text>
    //         }
    //         <Text cursor="pointer" onClick={() => deleteDialog(item)} display="inline-block" ml="8px" fontSize='sm' fontWeight='700'>
    //           Delete
    //         </Text>
    //       </Flex>
    //     </>
    //   }
    // },
  ];
  
}