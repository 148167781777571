/* eslint-disable */
import {
  Flex,
  Checkbox,
  Table,
  Tbody,
  Td,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import React, { useState, useImperativeHandle, forwardRef, useEffect } from "react";
import 'rc-pagination/assets/index.css';
import { cloneDeep } from "lodash"
const DevelopmentTable = forwardRef((props, ref) => {
  const { columns, data = [], emitSelectAll, isChecked, emitSort = () => { }, onClickRow, emptytext = 'empty', sortQuery, wrap } = props;
  useImperativeHandle(ref, () => ({
    clear: (info) => {
      setOrderType({})
    }
  }))

  useEffect(() => {
    if (sortQuery) {
      setOrderType(sortQuery)
    }
  }, [sortQuery])

  const [selectList, setSelectList] = useState([])
  /** 表格选中idlist */
  const [selectIds, setSelectIds] = useState([]);
  const isIndeterminate = selectIds.some(Boolean) && (selectIds?.length !== data?.length) && selectIds.length > 0
  /**
   * 全选
  */
  const onSelectAll = (checked) => {
    const arr = checked ?
      data.reduce((_ids, item, index, _data) => [..._ids, item.id], []) : []
    if (selectList.length && arr.length) {
      const arrs = selectList.concat(arr)
      setSelectList(arrs)
      setSelectIds(arrs)
      emitSelectAll(arrs);
    } else {
      setSelectList(arr)
      setSelectIds(arr)
      emitSelectAll(arr);
    }
  }

  /**
   * 单选
  */
  const changeSelect = (id, bool) => {
    if (bool) {
      emitSelectAll([...selectIds, id]);
      return setSelectIds([...selectIds, id])
    }
    const _ind = selectIds.indexOf(id)
    const copyIds = cloneDeep(selectIds)
    copyIds.splice(_ind, 1);
    setSelectIds(copyIds);
    emitSelectAll(copyIds);
  }
  const [orderType, setOrderType] = useState({})
  const onSort = (item) => {
    setOrderType({})
    const sortdata = {
      [item.dataIndex]: orderType[item.dataIndex] === 'desc' ? 'asc' : 'desc'
    }
    setOrderType(sortdata)
    emitSort(sortdata)
  }
  const onClickTd = (item) => {
    onClickRow && onClickRow(item)
  }
  const checkedAll = () => {
    const idsToCheck = data.map(item => item.id);
    const result = idsToCheck.every(id => selectIds.some(item => item === id));
    return result
  }
  return (
    <div>
      <Table className="custom-table" variant='simple' overflowX>
        <Thead fontSize="12px" fontWeight="600" background="#E4E9F1" >
          <Tr>
            {
              isChecked ? <Td w="60px">
                <Checkbox
                  borderColor="#ccc"
                  className="selectAll"
                  isChecked={checkedAll()}
                  // isIndeterminate={isIndeterminate}
                  onChange={(e) => onSelectAll(e?.target?.checked)}
                >
                </Checkbox>
              </Td> : null
            }
            {
              columns?.map((item, index) => <Td fontFamily="Inter" whiteSpace="nowrap" maxW="300px" key={index}>
                {item?.title || '--'}
                {item.isSort ? <span style={{ fontSize: '16px' }} onClick={() => onSort(item)}>
                  {
                    orderType[item.dataIndex] == 'desc' ? <ChevronDownIcon ml='6px' cursor="pointer" /> : orderType[item.dataIndex] == 'asc' ? <ChevronUpIcon ml='6px' cursor="pointer" /> : <span style={{ cursor: 'pointer', 'marginLeft': '6px' }}>-</span>
                  }
                </span> : null}
              </Td>
              )
            }
          </Tr>
        </Thead>

        <Tbody color='gray.500'>
          {
            data?.map((item, index) => {
              return <Tr borderBottom="1px solid rgb(229, 229, 229)" fontFamily="Inter" _hover={{ background: '#F3F4F8' }} key={index}>
                {
                  isChecked ? <Td w="60px">
                    <Checkbox
                      isChecked={selectIds?.includes(item.id)}
                      onChange={(e) => changeSelect(item.id, e.target.checked)}
                    >
                    </Checkbox>
                  </Td> : null
                }
                {
                  columns?.map((colItem, colIndex) => <Td maxW="300px" _click={{background: 'none'}} style={{textWrap: wrap ? 'nowrap' : '', whiteSpace: wrap ? 'pre' : ''}}
                    cursor={onClickRow ? 'pointer' : ''}
                    onClick={() => onClickTd(item)} key={colIndex}
                  >
                    {
                      colItem?.render ? colItem?.render(item?.[colItem.dataIndex], item, index) : (item?.[colItem.dataIndex] || '--')
                    }
                  </Td>
                  )
                }
              </Tr>
            })
          }
        </Tbody>


      </Table>
      {
        !data.length ? <Flex mt="8px" fontFamily="Inter" justifyContent="center">{emptytext}</Flex> : null
      }

    </div>
  );
})
export default DevelopmentTable;
