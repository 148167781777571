import React, { forwardRef, useState, useCallback, useEffect } from "react";
import {
  Box,
  Flex,
  Input,
  Select,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  CheckboxGroup,
  RadioGroup,
  Radio,
  Stack,
  Checkbox,
} from "@chakra-ui/react";

// import CustomSearch from "@/components/inputSearch/index.jsx"
import CustomDataRange from "@/components/dataRange/index.jsx"
import { getMethod } from 'api/index';
import SelectSearch from '@/components/selectSearch/index.jsx'

import '../index.scss'
const queryParams = {
  name: '',
  data: [],
  last_active_date_begin: '',
  last_active_date_end: '',
  race: [],
  category: [],
  // donated: 1,
  // frequence: [0, 10],
  start_frequency: '',
  end_frequency: '',
  total_mount_begin: '',
  total_mount_end: '',
  has_phone: 1,
  precinct_desc: '',
  municipality_desc: '',
  cong_dist_abbrv: '',
  nc_house_abbrv: '',
  nc_senate_abbrv: ''
  // totalAmount: [0, 2000],
  // donatedTo: []
}
const DonorDataDialog = forwardRef((props, ref) => {
  const { onSearch } = props;
  const queryList = [
    {
      title: 'Race',
      type: 'checkbox',
      list: [
        {
          label: 'White',
          value: 'white'
        },
        {
          label: 'Black',
          value: 'black'
        },
        {
          label: 'Asian',
          value: 'asian'
        },
        {
          label: 'India',
          value: 'india'
        },
        {
          label: 'Other',
          value: 'other'
        }
      ],
      key: 'race'
    },
    // {
    //   title: 'Donated',
    //   list: [
    //     {
    //       label: 'Yes',
    //       value: 1
    //     },
    //     {
    //       label: 'No',
    //       value: 2
    //     },
    //   ],
    //   key: 'donated'
    // },
    {
      title: 'Category',
      type: 'checkbox',
      list: [
        {
          label: 'Court',
          value: 'court'
        },
        {
          label: 'Education',
          value: 'education'
        },
        {
          label: 'Governor',
          value: 'governor'
        },
        {
          label: 'Municipal',
          value: 'municipal'
        },
        {
          label: 'Nc_house',
          value: 'nc_house'
        },
        {
          label: 'Other',
          value: 'other'
        },
        {
          label: 'Sheriff',
          value: 'sheriff'
        },
        {
          label: 'Nc_senate',
          value: 'nc_senate'
        },
      ],
      key: 'category'
    }
  ]
  const [formQuery, setFormQuery] = useState({ ...queryParams })

  const [precinctDescList, setPrecinctDescList] = useState([]);
  const [municipalityDescList, setMunicipalityDescList] = useState([]);
  const [congDistAbbrvList, setCongDistAbbrvList] = useState([]);
  const [ncHouseAbbrvList, setNcHouseAbbrvList] = useState([]);
  const [ncSenateAbbrvList, setNcSenateAbbrvList] = useState([]);

  const onSearchClear = () => {
    setFormQuery({ ...queryParams });
    onSearch({}, 'clear')
  }
  const keyData = ['start_frequency', 'end_frequency', 'total_mount_begin', 'total_mount_end'];
  const onChangeValue = (value, key, type) => {
    if(keyData.includes(key) && value !== ''){
      if(!(/^[0-9]+$/.test(value))) {
        return
      }
    }
    setFormQuery({
      ...formQuery,
      [key]: type === 'number' ? Number(value) : value
    })
  }
  const onSearchTable = () => {
    onSearch(formQuery)
  }

  return (
    <div>
      <Accordion className="query-content" allowMultiple onChange={(val) => {
        if(val.indexOf(7) !== -1 && !precinctDescList.length) {
          getMethod('/app/option/precinct').then((res) => {
            if (res && res.code === 200) {
              setPrecinctDescList(res.data)
            }
          })
        }
        if(val.indexOf(8) !== -1 && !municipalityDescList.length) {
          getMethod('/app/option/municipality').then((res) => {
            if (res && res.code === 200) {
              setMunicipalityDescList(res.data)
            }
          })
        }
        if(val.indexOf(9) !== -1 && !congDistAbbrvList.length) {
          getMethod('/app/option/congdist').then((res) => {
            if (res && res.code === 200) {
              setCongDistAbbrvList(res.data)
            }
          })
        }
        if(val.indexOf(10) !== -1 && !ncHouseAbbrvList.length) {
          getMethod('/app/option/house').then((res) => {
            if (res && res.code === 200) {
              setNcHouseAbbrvList(res.data)
            }
          })
        }
        if(val.indexOf(11) !== -1 && !ncSenateAbbrvList.length) {
          getMethod('/app/option/senate').then((res) => {
            if (res && res.code === 200) {
              setNcSenateAbbrvList(res.data)
            }
          })
        }
      }}>
        <AccordionItem>
          <h2>
            <AccordionButton _focus={{
              border: 'none'
            }}>
              <Box as="span" flex='1' textAlign='left'>
                Name
                      </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Input h="28px" fontSize="12px" borderRadius="1px" paddingLeft="5px" placeholder="Type name" value={formQuery.name} onChange={(e) => {
              onChangeValue(e.target.value, 'name')
            }} />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton _focus={{
              border: 'none'
            }}>
              <Box as="span" flex='1' textAlign='left'>
                Last active date
                      </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <CustomDataRange
              value={formQuery.data}
              valueType={'string'}
              onChange={(value) => {
                onChangeValue(value, 'data')
              }}
            ></CustomDataRange>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton _focus={{
              border: 'none'
            }}>
              <Box as="span" flex='1' textAlign='left'>
                Frequency
                      </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pt="16px">
            
            <Flex>
              <Input h="28px" fontSize="12px" borderRadius="1px" paddingLeft="5px" placeholder="Min" value={formQuery.start_frequency} onChange={(e) => {
                onChangeValue(e.target.value, 'start_frequency')
              }} />
              <Box m="0 4px"></Box>
              <Input h="28px" fontSize="12px" borderRadius="1px" paddingLeft="5px" placeholder="Max" value={formQuery.end_frequency} onChange={(e) => {
                onChangeValue(e.target.value, 'end_frequency')
              }} />
            </Flex>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton _focus={{
              border: 'none'
            }}>
              <Box as="span" flex='1' textAlign='left'>
                Total amount
                      </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pt="16px">
            <Flex>
              <Input h="28px" fontSize="12px" borderRadius="1px" paddingLeft="5px" placeholder="Min" value={formQuery.total_mount_begin} onChange={(e) => {
                onChangeValue(e.target.value, 'total_mount_begin')
              }} />
              <Box m="0 4px"></Box>
              <Input h="28px" fontSize="12px" borderRadius="1px" paddingLeft="5px" placeholder="Max" value={formQuery.total_mount_end} onChange={(e) => {
                onChangeValue(e.target.value, 'total_mount_end')
              }} />
            </Flex>
          </AccordionPanel>
        </AccordionItem>
        {
          queryList?.map((item, index) => {
            return <AccordionItem key={index}>
              <h2>
                <AccordionButton _focus={{
                  border: 'none'
                }}>
                  <Box as="span" flex='1' textAlign='left'>
                    {item.title}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                {item.type === 'checkbox' ?
                  <CheckboxGroup value={formQuery[item.key]} defaultValue={formQuery[item.key]} onChange={(e) => onChangeValue(e, item.key)}>
                    <Stack direction={['column']}>
                      {
                        item.list?.map((i, ind) => {
                          return <Checkbox key={ind} value={i.value}>{i.label}</Checkbox>
                        })
                      }
                    </Stack>
                  </CheckboxGroup> :
                  <RadioGroup onChange={(e) => onChangeValue(e, item.key, 'number')} value={formQuery[item.key]}>
                    <Stack>
                      {
                        item.list?.map((i, ind) => {
                          return <Radio key={ind} value={i.value}>{i.label}</Radio>
                        })
                      }
                    </Stack>
                  </RadioGroup>
                }
              </AccordionPanel>
            </AccordionItem>

          })
        }
        <AccordionItem>
          <h2>
            <AccordionButton _focus={{
              border: 'none'
            }}>
              <Box as="span" flex='1' textAlign='left'>
                Has phone number
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pt="16px">
            <Select h="28px" placeholder='' value={formQuery.has_phone} onChange={(e) => {
              onChangeValue(e.target.value, 'has_phone')
            }} >
              <option value={1}>all</option>
              <option value={2}>has phone number</option>
              <option value={3}>no phone number</option>
            </Select>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton _focus={{
              border: 'none'
            }}>
              <Box as="span" flex='1' textAlign='left'>
                Precinct
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pt="16px">
            {
              precinctDescList.length ? 
              <SelectSearch propChildren={{
                label: 'option',
                value: 'option'
              }} value={formQuery.precinct_desc} data={precinctDescList} onChange={(value) => {
                onChangeValue(value, 'precinct_desc')
              }}></SelectSearch> : null
            }
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton _focus={{
              border: 'none'
            }}>
              <Box as="span" flex='1' textAlign='left'>
                Municipality
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pt="16px">
            {
              municipalityDescList.length ? 
              <SelectSearch propChildren={{
                label: 'option',
                value: 'option'
              }} value={formQuery.municipality_desc} data={municipalityDescList} onChange={(value) => {
                onChangeValue(value, 'municipality_desc')
              }}></SelectSearch> : null
            }
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton _focus={{
              border: 'none'
            }}>
              <Box as="span" flex='1' textAlign='left'>
                Congress Dist
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pt="16px">
            {
              congDistAbbrvList.length ? 
              <SelectSearch propChildren={{
                label: 'option',
                value: 'option'
              }} value={formQuery.cong_dist_abbrv} data={congDistAbbrvList} onChange={(value) => {
                onChangeValue(value, 'cong_dist_abbrv')
              }}></SelectSearch> : null
            }
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton _focus={{
              border: 'none'
            }}>
              <Box as="span" flex='1' textAlign='left'>
                NC House
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pt="16px">
            {
              ncHouseAbbrvList.length ? 
              <SelectSearch propChildren={{
                label: 'option',
                value: 'option'
              }} value={formQuery.nc_house_abbrv} data={ncHouseAbbrvList} onChange={(value) => {
                onChangeValue(value, 'nc_house_abbrv')
              }}></SelectSearch> : null
            }
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton _focus={{
              border: 'none'
            }}>
              <Box as="span" flex='1' textAlign='left'>
                NC Senate
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pt="16px">
            {
              ncSenateAbbrvList.length ? 
              <SelectSearch propChildren={{
                label: 'option',
                value: 'option'
              }} value={formQuery.nc_senate_abbrv} data={ncSenateAbbrvList} onChange={(value) => {
                onChangeValue(value, 'nc_senate_abbrv')
              }}></SelectSearch> : null
            }
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      
      <div className="search-operate">
        <span className="search-btn search-btn-clear" onClick={onSearchClear}>Clear all</span>
        <span className="search-btn search-btn-apply" onClick={onSearchTable}>Apply</span>
      </div>
    </div>
  );
})

export default DonorDataDialog;
