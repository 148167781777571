import React, { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import {
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

import CustomTable from "components/table";
import Pagination from "components/pagination";
import Query from './query'
import DefaultButton from 'components/button'

import { columns } from "./config";
import { warningMessage, successMessage } from '@utils/message'

import '../index.scss'

import { publicDonorList, addPublicDonorList } from 'api/donor'
import { postMethod } from 'api/index';

const DonorDataDialog = forwardRef((props, ref) => {

  const history = useHistory();
  
  const userInfo = useSelector((state) => state.userCmsInfos)

  const [visiable, setVisiable] = React.useState(false);
  const borderRadius = '6px';
  const [queryForm, setQuery] = useState({})
  const [orderQuery, setOrderQuery] = useState({})
  const [page, setPage] = React.useState({
    currentPage: 1,
    pageSize: 10
  })
  const [rowData, setRow] = useState({})
  const donorTableRef = React.useRef();
  const queryRef = React.useRef();
  useImperativeHandle(ref, () => ({
    show: (row) => {
      if(row) {
        setRow(row)
      }
      setVisiable(true);
      getDonorList(page)
    }
  }))
  const [total, setTotal] = useState(0)
  const getDonorList = (pagination, params = {}) => {
    let querySearch = {}
    if(params.data) {
      querySearch = {
        ...params,
        'last_active_date_begin': params.data[0] || '',
        'last_active_date_end': params.data[1] || ''
      }
    } else {
      querySearch = {
        ...params,
      }
    }
    let newParams = {}
    if(Object.keys(querySearch)) {
      for(let i in querySearch) {
        if(typeof(querySearch[i]) === 'string' && querySearch[i]) {
          newParams[i] = querySearch[i]
        } else if(typeof(querySearch[i]) === 'object' && querySearch[i]) {
          if(querySearch[i].length) {
            newParams[i] = querySearch[i]
          }
        }
      }
    }
    publicDonorList(`/app/campaign/donor/public/list/${pagination.currentPage}/${pagination.pageSize}`, newParams).then((res) => {
      if(res && res.code === 200) {
        setData(res.data.list)
        setTotal(res.data.total_count)
      }
    })
  }

  const onSearchTable = (query, type) => {
    donorTableRef.current.clear()
    setQuery(query)
    if(type === 'clear') {
      setPage({
        currentPage: 1,
        pageSize: 10
      })
      getDonorList({
        currentPage: 1,
        pageSize: 10
      }, query)
    } else {
      setPage({
        currentPage: 1,
        pageSize: 10
      })
      getDonorList({
        currentPage: 1,
        pageSize: 10
      }, query)
    }
  }

  const [dataList, setData] = React.useState([]);

  const [selectIds, setSelectIds] = React.useState([]);
  const emitSelectAll = (list) => {
    setSelectIds(list)
  }

  const onClose = () => {
    setPage({
      currentPage: 1,
      pageSize: 10
    })
    setSelectIds([])
    setVisiable(false);
  }
  const onSure = () => {
    if(selectIds.length) {
      if(selectIds.length > 500) {
        warningMessage('The number of donors added at a time cannot exceed 500.');
        return;
      }
      let params = {
        public_donor_ids: selectIds
      }

      addPublicDonorList(`/app/campaign/donor/public/batch/add/${rowData.id}/${rowData.campaign_team_id}`, params).then((res) => {
        if(res && res.code === 200) {
          successMessage('success');
        }
      })
    }
    // 调取接口
  }
  const handleChildValue = (value) => {
    setQuery(value)
  }
  const onSureAddQualified = () => {
    let querySearch = {}
    if(queryForm.data) {
      querySearch = {
        ...queryForm,
        'last_active_date_begin': queryForm.data[0] || '',
        'last_active_date_end': queryForm.data[1] || ''
      }
    } else {
      querySearch = {
        ...queryForm,
      }
    }
    let newParams = {}
    if(Object.keys(querySearch)) {
      for(let i in querySearch) {
        if(typeof(querySearch[i]) === 'string' && querySearch[i]) {
          newParams[i] = querySearch[i]
        } else if(typeof(querySearch[i]) === 'object' && querySearch[i]) {
          if(querySearch[i].length) {
            newParams[i] = querySearch[i]
          }
        }
      }
    }

    let params = {
      ...newParams,
      account_id: rowData.id,
      campaign_id: rowData.campaign_team_id
    }
    postMethod(`/app/campaign/donor/condition/batch/add`, params).then((res) => {
      if(res && res.code === 200) {
        successMessage('success');
      }
      // successMessage('The request was successful. Due to the large amount of data, there was a delay in adding it to your workbook.');
    })
  }
  const emitSort = (sort) => {
    setOrderQuery(sort)
    setPage({
      currentPage: 1,
      pageSize: 10
    })
    getDonorList({
      currentPage: 1,
      pageSize: 10
    }, {...queryForm, ...sort})
  }
  return (
    <div>
      <Modal size='xxl' isOpen={visiable} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select Donor data</ModalHeader>
          <ModalCloseButton _focus={{
            boxShadow: "none",
            border: "none"
          }} />
          <ModalBody>
            <div className="add-donor-data-content">
              <div className="search-data-query">
                <div className="query-title">Filter</div>
                <Query 
                  queryRef={queryRef}
                  onChildValue={handleChildValue}
                  onSearch={(query, type) => onSearchTable(query, type)}
                />
              </div>
              <div className="query-table">
                <div className="table-content">
                  <CustomTable
                    isChecked={true}
                    columns={columns()}
                    data={dataList}
                    emitSort={emitSort}
                    ref={donorTableRef}
                    emitSelectAll={emitSelectAll}
                  />
                </div>
                <div className="donor-pagination">
                  <Pagination 
                    onChange={(pagination) => {
                      setPage(pagination)
                      getDonorList(pagination, {...queryForm, ...orderQuery})
                    }}  
                    paginations={page}
                    data={dataList} 
                    total={total}
                  ></Pagination>
                </div>
                <Flex mt="20px" justifyContent="flex-end">
                  <DefaultButton
                    onClick={onClose}
                    name='Cancel'
                    width="109px"
                    color="#000"
                    background="#EDF2F7"
                    marginLeft="12px"
                  ></DefaultButton>
                  <DefaultButton
                    name='Add qualified donor to workbook'
                    onClick={onSureAddQualified} 
                    width="300px"
                    marginLeft="12px"
                  ></DefaultButton>
                  <DefaultButton
                    name='Add selected donor to workbook'
                    onClick={onSure} 
                    width="300px"
                    marginLeft="12px"
                  ></DefaultButton>
                </Flex>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {/* <Button onClick={onClose} background="#EDF2F7" colorScheme='#EDF2F7' color="#000" borderRadius={borderRadius} mr="12px" >Cancel</Button>
            <Button onClick={onSure}  background="#6E7682" colorScheme='#6E7682' color="#fff" borderRadius={borderRadius} >Add to work book</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
})

export default DonorDataDialog;
