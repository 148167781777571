/* eslint-disable */

import React, { useEffect, useState } from "react";
import {cloneDeep} from 'lodash';

import './index.scss'

const CustomPagination = (props) => {
  const { onChange, total, paginations = {currentPage: 1,
    pageSize: 10,} } = props;
  const [clonePage, setClonePage] = useState({
    currentPage: 1,
    pageSize: 10,
  })
  // const objData = Object.assign({}, ...pagination, ...paginations)
  const cloneData = cloneDeep(paginations);
  const [pagination, setPVal] = useState(cloneData);
  useEffect(() => {
    setPVal(
      {
        ...pagination,
        currentPage: paginations.currentPage
      }
    )
    setClonePage(paginations);
    if(total === 0) {
      setPVal({
        ...pagination,
        currentPage: 1
      })
      setClonePage(
        {
        currentPage: 1,
        pageSize: 10,
        }
      );
    }
  }, [total]);
  const onBlur = () => {
    let current = { ...pagination }
    if (pagination.currentPage <= 0) {
      current.currentPage = 1
    }
    if (pagination.currentPage >= Math.ceil(total / 10)) {
      current.currentPage = Math.ceil(total / 10)
    }
    current.currentPage = Number(current.currentPage)
    setPVal({ ...current })
    setClonePage({ ...current })
    onChange(current)
  }
  const onChangePage = (e) => {
    setPVal({
      ...pagination,
      currentPage: e.target.value
    })
  }
  const onHandleEnter = (event) => {
    if(event.key === 'Enter') {
      onBlur();
    }
  }
  const previousPage = () => {
    if (Number(pagination.currentPage) <= 1) {
      return
    }
    const newPVal = { ...pagination, currentPage: Number(pagination.currentPage) - 1 }
    setClonePage({ ...newPVal })
    setPVal({ ...newPVal })
    onChange(newPVal)
  }
  const nextPage = () => {
    if (Number(pagination.currentPage) >= Math.ceil(total / 10)) {
      return
    }
    const newPVal = { ...pagination, currentPage: Number(pagination.currentPage) + 1 }
    setClonePage({ ...newPVal })
    setPVal({ ...newPVal })
    onChange(newPVal)
  }

  return (
    <div className="pagination-content">
      <span className="page-text">Page</span>
      <input type="number" value={pagination.currentPage} onKeyPress={onHandleEnter} onChange={(e) => onChangePage(e)} className="page-number" />
      <span className="of-text">of</span>
      <span className="total-text">{Math.ceil(total / 10)}</span>
      <button disabled={(clonePage.currentPage <= 1)} className={((clonePage.currentPage <= 1)) ? "disabledBtnP operate-btn": "operate-btn"} onClick={previousPage}>Previous</button>
      <button disabled={(clonePage.currentPage >= Math.ceil(total / 10))} className={((clonePage.currentPage >= Math.ceil(total / 10) || !total)) ? "disabledBtnP operate-btn": "operate-btn"} onClick={nextPage}>Next</button>
    </div>
  );
}
export default CustomPagination
