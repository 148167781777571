import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {
  return (
    <Flex align='center' direction='column'>
      {/* LOGO */}
      {localStorage.getItem('userRole') === 'user' ? 
      <Text h="80px" lineHeight="80px" fontWeight="600" fontSize="24px"> Donation APP</Text> : 
      null}
      
      {/* <HorizonLogo h='26px' w='175px' my='32px' color={logoColor} /> */}
      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
