import React from "react";
import DeleteSvg from 'assets/img/account/delete.svg';
import DeleteHoverSvg from 'assets/img/account/delete_hover.svg';

import {
  Flex,
  Text,
  Select,
} from "@chakra-ui/react";
import { EditIcon } from '@chakra-ui/icons'

export const columns = (props) => {
  const {
    deleteDialog = () => { },
    changeAuth = () => { }
  } = props ?? {}
  return [
    {
      title: "NAME",
      dataIndex: "user_name",
    },
    {
      title: "PHONE",
      dataIndex: "phone",
    },
    {
      title: "EMAIL",
      dataIndex: "email",
    },

    {
      title: "ROLE",
      dataIndex: "role",
      render: (val, item, index) => {
        return <Select
          border="none"
          size="normal"
          onChange={(e) => changeAuth(e, item, index)}
          _focus={{
            border: '1px solid inherit'
          }}
          width="120px" value={val}>
          <option value='User'>User</option>
          <option value='Admin'>Admin</option>
        </Select>
      }
    },
    {
      title: "OPERATION",
      render(val, item, index) {
        return <>
          <Flex mw="180px">
            <Text cursor="pointer" className="operate-container" onClick={() => deleteDialog(item)} display="inline-block" ml="8px" fontSize='sm' fontWeight='700'>
              <img className="operate-delete" src={DeleteSvg} alt=""/>
              <img className="operate-hover-delete" src={DeleteHoverSvg} alt=""/>
            </Text>
          </Flex>
        </>
      }
    },
  ];

}

export const SmsColumns = (props) => {
  const {
    deleteDialog = () => { },
    editDialog = () => { }
  } = props ?? {}
  return [
    {
      title: "NAME",
      dataIndex: "sms_template_name",
    },
    {
      title: "CONTENT",
      dataIndex: "sms_template_content",
    },
    {
      title: "OPERATION",
      render(val, item, index) {
        return <>
          <Flex mw="180px">
            <Text cursor="pointer" onClick={() => editDialog(item)} >
              <EditIcon cursor="pointer" boxSize={5} />
            </Text>
            <Text cursor="pointer" className="operate-container" onClick={() => deleteDialog(item)} display="inline-block" h="21px" ml="8px" fontSize='sm' fontWeight='700'>
              <img className="operate-delete" src={DeleteSvg} alt=""/>
              <img className="operate-hover-delete" src={DeleteHoverSvg} alt=""/>
            </Text>
          </Flex>
        </>
      }
    },
  ];

}