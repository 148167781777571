/* eslint-disable */
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { successMessage, warningMessage } from '@utils/message'

// import { Field, Form, Formik } from 'formik';

import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import DefaultButton from 'components/button'
import CustomDataRange from "@/components/dataRange/index.jsx"

import { getMethod, postMethod } from 'api/index';

const PullTranscationDialog = forwardRef((props, ref) => {
  const { onAdd } = props;
  // const history = useHistory();
  const userInfo = useSelector((state) => state.userInfos)
  const [visiable, setVisiable] = useState(false);
  const [showError, setShowError] = useState(false);
  const [artblueInfo, setArtblueInfo] = useState({});
  const [teamInfo, setTeamInfo] = useState({});
  useImperativeHandle(ref, () => ({
    pull: (info, actblueInfo) => {
      setTeamInfo(info);
      setArtblueInfo(actblueInfo);
      setFormQuery({
        ...formQuery,
        "date": []
      })
      setVisiable(true);
      setShowError(false);
    }
  }))

  
  const [formQuery, setFormQuery] = useState({
    date: []
  })
 
  const onClose = () => {
    setVisiable(false);
  }
  const onChangeValue = (value, key, type) => {
    setFormQuery({
      ...formQuery,
      [key]: type === 'number' ? Number(value) : value
    })
  }
  const onPullTranscation = () => {
    if(formQuery.date.length) {
      setShowError(false)
      let params = {
        date_start: formQuery.date[0],
        date_end: formQuery.date[1],
        account_id: userInfo.account_id,
        campaign_team_id: teamInfo.campaign_team_id,
        committee_sboe_id: artblueInfo.committee_sboe_id || '',
      }
      postMethod('/app/transaction/pull', params).then((res) => {
        if (res && res.code === 200) {
          successMessage('There is a delay in retrieving the data, please wait for some time before refreshing the transaction records!')
          
          setVisiable(false);
        }
      })
    } else {
      setShowError(true)
    }
  }
  return (
    <Modal size='2xl' isOpen={visiable} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Pull transaction</ModalHeader>
        <ModalCloseButton _focus={{
          boxShadow: "none",
          border: "none"
        }} />
        <ModalBody>
          <CustomDataRange
            value={formQuery.date}
            valueType={'string'}
            onChange={(value) => {
              onChangeValue(value, 'date')
            }}
          ></CustomDataRange>
          {
            (showError && (formQuery.date.length === 0)) ? <div className="error-text" style={{color: '#EE5D50'}}>date is required</div> : null
          }
        </ModalBody>
        <ModalFooter>
          <DefaultButton
            onClick={onClose}
            name='Cancel'
            width="109px"
            color="#000"
            background="#EDF2F7"
            marginLeft="12px"
          ></DefaultButton>
          <DefaultButton
            name='Pull'
            onClick={onPullTranscation}
            width="109px"
            marginLeft="12px"
          ></DefaultButton>
        </ModalFooter>
      </ModalContent>
    </Modal>

  );
})

export default PullTranscationDialog;
