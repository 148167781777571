import {get, post} from '../utils/request'

export function getUserList(url,data) {
    return get({
        // `url` 是用于请求的服务器 URL
        url,
		//需要传递到服务器的 参数
        data,
        cancel: true
    })
}

export function getUserCustomClaims(url,data) {
    return get({
        // `url` 是用于请求的服务器 URL
        url,
        //需要传递到服务器的 参数
        data,
        cancel: true
    })
}

export function getMethod(url,data) {
    return get({
        // `url` 是用于请求的服务器 URL
        url,
        //需要传递到服务器的 参数
        data,
        cancel: true
    })
}

export function postMethod(url,data) {
    return post({
        // `url` 是用于请求的服务器 URL
        url,
        //需要传递到服务器的 参数
        data,
        cancel: true
    })
}
