import React, { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Popconfirm, Button } from 'antd';

import { useSelector } from 'react-redux';
import {
  Flex,
  // Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

import CustomTable from "components/table";
import Pagination from "components/pagination";
import Query from './query'
import DefaultButton from 'components/button'

import { columns } from "./config";
import { warningMessage, successMessage, errorMessage } from '@utils/message'
import { PayAsYouGo } from '@utils/common'
import { publicDonorList, addPublicDonorList } from 'api/donor'
import { postMethod, getMethod } from 'api/index';

import '../index.scss'


const DonorDataDialog = forwardRef((props, ref) => {

  const history = useHistory();
  const userInfo = useSelector((state) => state.userInfos)

  const [visiable, setVisiable] = React.useState(false);
  const [visiablePay, setVisiablePay] = React.useState(false);
  const [payParam, setPayParam] = React.useState({});
  const [isPay, setIsPay] = React.useState(false);
  const [queryParamsS, setQueryParamsS] = React.useState({});
  const [clickBtn, setClickBtn] = useState('')

  const borderRadius = '6px';
  const [queryForm, setQuery] = useState({})
  const [orderQuery, setOrderQuery] = useState({})
  const [page, setPage] = React.useState({
    currentPage: 1,
    pageSize: 10
  })

  const donorTableRef = React.useRef();
  const queryRef = React.useRef();
  useImperativeHandle(ref, () => ({
    show: () => {
      setVisiable(true);
      getDonorList(page)
      getPlanList();
    }
  }))
  const [payTotal, setPayTotal] = useState(0)
  const [total, setTotal] = useState(0)
  const [servicePlan, setServicePlan] = useState('');

  const getPlanList = () => {
    getMethod('/api/service/plan/list').then((res) => {
      if (res && res.code === 200) {
        const item = res.data.find((i) => {
          return i.id === userInfo.service_plan_id
        })
        setServicePlan(item?.service_name)
      }
    })
  }
  const getDonorList = (pagination, params = {}) => {
    let querySearch = {}
    if (params.data) {
      querySearch = {
        ...params,
        'last_active_date_begin': params.data[0] || '',
        'last_active_date_end': params.data[1] || ''
      }
    } else {
      querySearch = {
        ...params,
      }
    }
    let newParams = {}
    if (Object.keys(querySearch)) {
      for (let i in querySearch) {
        if (typeof (querySearch[i]) === 'string' && querySearch[i]) {
          newParams[i] = querySearch[i]
        } else if (typeof (querySearch[i]) === 'object' && querySearch[i]) {
          if (querySearch[i].length) {
            newParams[i] = querySearch[i]
          }
        }
      }
    }
    publicDonorList(`/app/campaign/donor/public/list/${pagination.currentPage}/${pagination.pageSize}`, newParams).then((res) => {
      if (res && res.code === 200) {
        setData(res.data.list)
        setTotal(res.data.total_count)
      }
    })
  }

  const onSearchTable = (query, type) => {
    donorTableRef.current.clear()
    setQuery(query)
    if (type === 'clear') {
      setPage({
        currentPage: 1,
        pageSize: 10
      })
      getDonorList({
        currentPage: 1,
        pageSize: 10
      }, query)
    } else {
      setPage({
        currentPage: 1,
        pageSize: 10
      })
      getDonorList({
        currentPage: 1,
        pageSize: 10
      }, query)
    }
  }

  const [dataList, setData] = React.useState([]);

  const [selectIds, setSelectIds] = React.useState([]);
  const emitSelectAll = (list) => {
    setSelectIds(list)
  }
  const onCloseDialog = () => {
    setIsPay(false);
    setVisiablePay(false)
  }
  const onCloseDialogForPay = () => {
    warningMessage('Do you want to waive payment? If you give up the payment, please do not do the payment operation again, otherwise you will not be able to add data even if the payment is completed.');
    setIsPay(false);
  }
  const onSurePay = () => {
    setIsPay(true);
    window.open(payParam.url);
  }
  const onSurePayDone = () => {
    
      getMethod(`/app/stripe/payment/validate/${userInfo.uid}/${payParam.session_id}`).then((res) => {
        if (res && res.code === 200) {
          setVisiablePay(false);
          if(clickBtn === 'all'){
            onSureAddQualifiedForPay(queryParamsS)
          } else {
            if (selectIds.length > 500) {
              warningMessage('The number of donors added at a time cannot exceed 500.');
              return;
            }
            let params = {
              public_donor_ids: selectIds
            }
            addPublicDonorList(`/app/campaign/donor/public/batch/add/${userInfo.account_id}/${history.location.state.item.campaign_team_id}`, params).then((res) => {
              if (res && res.code === 200) {
                setVisiablePay(false)
                successMessage('success');
              }
            })
          }
        }
      })
  }

  const onSureAddQualifiedForPay = (params) => {
    postMethod(`/app/campaign/donor/condition/batch/add`, params).then((res) => {
      if (res && res.code === 200) {
        successMessage('success');
      }
    })
  }
  const onClose = () => {
    setPage({
      currentPage: 1,
      pageSize: 10
    })
    setSelectIds([])
    setVisiable(false);
  }


  const onSure = () => {
    setClickBtn('selects')
    setPayTotal(0)
    setIsPay(false);
    if (selectIds.length) {
      if (servicePlan === PayAsYouGo) {
        setVisiablePay(true);
        let params = {
          amount: selectIds.length,
          currency: 'usd',
          uid: userInfo.uid,
          public_donor_ids: selectIds
        }
        setQueryParamsS({
          ...params,
          account_id: userInfo.account_id,
          campaign_id: history.location.state.item.campaign_team_id
        })
        postMethod(`/app/stripe/create_payment_link`, params).then((res) => {
          if (res && res.code === 200) {
            setPayParam(res.data)
          }
        })
      } else {
        if (selectIds.length > 500) {
          warningMessage('The number of donors added at a time cannot exceed 500.');
          return;
        }
        let params = {
          public_donor_ids: selectIds
        }
        addPublicDonorList(`/app/campaign/donor/public/batch/add/${userInfo.account_id}/${history.location.state.item.campaign_team_id}`, params).then((res) => {
          if (res && res.code === 200) {
            successMessage('success');
          }
        })
      }
    }
  }
  const handleChildValue = (value) => {
    setQuery(value)
  }
  const onSureAddQualified = () => {
    setIsPay(false);
    setClickBtn('all');
    let querySearch = {}
    if (queryForm.data) {
      querySearch = {
        ...queryForm,
        'last_active_date_begin': queryForm.data[0] || '',
        'last_active_date_end': queryForm.data[1] || ''
      }
    } else {
      querySearch = {
        ...queryForm,
      }
    }
    let newParams = {}
    if (Object.keys(querySearch)) {
      for (let i in querySearch) {
        if (typeof (querySearch[i]) === 'string' && querySearch[i]) {
          newParams[i] = querySearch[i]
        } else if (typeof (querySearch[i]) === 'object' && querySearch[i]) {
          if (querySearch[i].length) {
            newParams[i] = querySearch[i]
          }
        }
      }
    }

    let params = {
      ...newParams,
      account_id: userInfo.account_id,
      campaign_id: history.location.state.item.campaign_team_id
    }
    setQueryParamsS({ ...params })
    if (servicePlan === PayAsYouGo) {
      // 获取所有数据的条数, 确认框展示
      publicDonorList(`/app/campaign/donor/public/list/1/10`, params).then((res) => {
        if (res && res.code === 200) {
          setPayTotal(res.data.total_count)
          setVisiablePay(true);
          let queryParams = {
            amount: res.data.total_count,
            currency: 'usd',
            uid: userInfo.uid
          }
          postMethod(`/app/stripe/create_payment_link`, queryParams).then((res) => {
            if (res && res.code === 200) {
              setPayParam(res.data)
            }
          })
        }
      })
    } else {
      onSureAddQualifiedForPay(params)
      // postMethod(`/app/campaign/donor/condition/batch/add`, params).then((res) => {
      //   if(res && res.code === 200) {
      //     successMessage('success');
      //   }
      //   // successMessage('The request was successful. Due to the large amount of data, there was a delay in adding it to your workbook.');
      // })
    }
  }
  const emitSort = (sort) => {
    setOrderQuery(sort)
    setPage({
      currentPage: 1,
      pageSize: 10
    })
    getDonorList({
      currentPage: 1,
      pageSize: 10
    }, { ...queryForm, ...sort })
  }

  const text = () => {
    return `Are you sure to add ${payTotal ? payTotal : selectIds.length} donor data to your workbook?`
  }
  return (
    <div>
      <Modal size='xxl' isOpen={visiable} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select Donor data</ModalHeader>
          <ModalCloseButton _focus={{
            boxShadow: "none",
            border: "none"
          }} />
          <ModalBody>
            <div className="add-donor-data-content">
              <div className="search-data-query">
                <div className="query-title">Filter</div>
                <Query
                  queryRef={queryRef}
                  onChildValue={handleChildValue}
                  onSearch={(query, type) => onSearchTable(query, type)}
                />
              </div>
              <div className="query-table">
                <div className="table-content">
                  <CustomTable
                    isChecked={true}
                    columns={columns()}
                    data={dataList}
                    emitSort={emitSort}
                    ref={donorTableRef}
                    emitSelectAll={emitSelectAll}
                  />
                </div>
                <div className="donor-pagination">
                  <Pagination
                    onChange={(pagination) => {
                      setPage(pagination)
                      getDonorList(pagination, { ...queryForm, ...orderQuery })
                    }}
                    paginations={page}
                    data={dataList}
                    total={total}
                  ></Pagination>
                </div>
                <Flex mt="20px" justifyContent="flex-end">
                  <DefaultButton
                    onClick={onClose}
                    name='Cancel'
                    width="109px"
                    color="#000"
                    background="#EDF2F7"
                    marginLeft="12px"
                  ></DefaultButton>
                  {/* <DefaultButton
                    name='Add qualified donor to workbook'
                    onClick={onSureAddQualified} 
                    width="300px"
                    marginLeft="12px"
                  ></DefaultButton> */}
                  <Popconfirm
                    title="Add donor"
                    description="Are you sure to add all qualified donor data to your workbook?"
                    onConfirm={onSureAddQualified}
                    // onCancel={onClose}
                    okText="Yes"
                    cancelText="No"
                    okButtonProps={{
                      className: 'antBtnConfirm'
                    }}
                  >
                    <Button className="antPrimaryBtn" type="primary">Add qualified donor to workbook</Button>
                    {/* <DefaultButton
                      name='Add selected donor to workbook'
                      // onClick={onSure} 
                      width="300px"
                      marginLeft="12px"
                    ></DefaultButton> */}
                  </Popconfirm>
                  <Popconfirm
                    title="Add donor"
                    description={text}
                    onConfirm={onSure}
                    // onCancel={onClose}
                    okText="Yes"
                    cancelText="No"
                    okButtonProps={{
                      className: 'antBtnConfirm'
                    }}
                  >
                    <Button className="antPrimaryBtn" type="primary">Add selected donor to workbook</Button>
                    {/* <DefaultButton
                      name='Add selected donor to workbook'
                      // onClick={onSure} 
                      width="300px"
                      marginLeft="12px"
                    ></DefaultButton> */}
                  </Popconfirm>
                </Flex>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {/* <Button onClick={onClose} background="#EDF2F7" colorScheme='#EDF2F7' color="#000" borderRadius={borderRadius} mr="12px" >Cancel</Button>
            <Button onClick={onSure}  background="#6E7682" colorScheme='#6E7682' color="#fff" borderRadius={borderRadius} >Add to work book</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal size='xl' isOpen={visiablePay} onClose={onCloseDialog}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Payment</ModalHeader>
          <ModalCloseButton _focus={{
            boxShadow: "none",
            border: "none"
          }} />
          <ModalBody>
            {
              isPay ? <div>When making payment, please keep the current window closed, otherwise the purchase will fail.</div> : <div>You have choosed {payTotal || selectIds.length} donor datas, each donor data is worth 50 cents.</div>
            }
          </ModalBody>
          <ModalFooter>
            {
              isPay ?
                <div>
                  <DefaultButton
                    onClick={onCloseDialogForPay}
                    name='Problem encountered in payment'
                    width="290px"
                    color="#000"
                    background="#EDF2F7"
                  ></DefaultButton>
                  <DefaultButton
                    disabled={payTotal ? !payTotal : !selectIds.length}
                    name='Payment completed'
                    onClick={onSurePayDone}
                    width="190px"
                    marginLeft="12px"
                  ></DefaultButton>
                </div>
                :
                <div>
                  <DefaultButton
                    onClick={onCloseDialog}
                    name='Abandon'
                    width="109px"
                    color="#000"
                    background="#EDF2F7"
                  ></DefaultButton>
                  <DefaultButton
                    disabled={payTotal ? !payTotal : !selectIds.length}
                    name='Payment'
                    onClick={onSurePay}
                    width="125px"
                    marginLeft="12px"
                  ></DefaultButton>
                </div>
            }

          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
})

export default DonorDataDialog;
