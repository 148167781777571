import React, {useState} from "react";
import { useHistory } from 'react-router-dom';
import DefaultButton from 'components/button'
import './index.scss'
import icon1 from 'assets/img/home-icon1.svg'
import icon2 from 'assets/img/home-icon2.svg'

const PolymofundHome = () => {
  const history = useHistory();

  const onCheckTeam = () => {
    history.push('/polymo/all-teams');
  }
  return (
    <div className="check-team-page">
      <div className="bg-line"></div>
      <div className="check-team-main">
        <div className="check-team-content">
          <div className="content-title">
            <span className="name">
              <img className="icon1" src={icon1} alt=""/>
              <img className="icon2" src={icon2} alt=""/>
              PolymoFund</span>
          </div>
          <div className="content-des">
            As a guru in political campaign analytics, we are focusing on unveiling the comprehensive insight on both voters and donors by leveraging industry leading artificial intelligence technologies. 
          </div>
          <div className="operate-content">
            <DefaultButton
              onClick={onCheckTeam}
              name='Check out your team'
              width="284px"
              height="77px"
              borderRadius="20px"
              fontSize="20px"
            ></DefaultButton>
            {/* <div className="operate-btn" onClick={onCheckTeam}>
              Check out your team
            </div> */}
          </div>
        </div>

      </div>
    </div>
  );
}
export default PolymofundHome
