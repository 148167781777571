import React from "react";
import { message } from 'antd';

import { useHistory } from "react-router-dom";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Field, Form, Formik } from 'formik';
import useAuth from '../../../auth/useAuth'
import { postMethod } from 'api/index';
import { successMessage } from '@utils/message'

import './index.scss'

function SignIn(props) {
  const {signup} = useAuth();
  const validateFirstname = (value) => {
    let error 
    if (!value) {
      error = 'First name is required'
    }
    return error
  }
  const validateLastname = (value) => {
    let error 
    if (!value) {
      error = 'Last name is required'
    }
    return error
  }
  const validateEmail = (value) => {
    let error 
    if (!value) {
      error = 'Email is required'
    } else {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(value)) {
        error = 'Please enter the correct email address'
      }
    }
    return error
  }
  const validatePassword = (value) => {
    let error 
    if (!value) {
      error = 'Password is required'
    } else if (value.length<6) {
      error = 'Use at least 6 characters'
    }
    return error
  }

  const history = useHistory();
  const onCancle = () => {
    history.push('/polymo/home');
  }
  const toSignIn = () => {
    history.push('/polymo/sign-in');
  }
  return (
    <Flex className="sign-up-page">
      <div className="sign-up-card">
        <div className="title">Sign Up</div>
        <Formik
          initialValues={{ email: '', password: '', first_name: '', last_name: '' }}
          onSubmit={(values, actions) => {
            try {
              postMethod('/app/fb/user/email/signUp', values).then((res) => {
                if(res && res.code === 200) {
                  successMessage('Your registration was successful. Please check your email for a verification link.');
                  history.push('/polymo/sign-in');
                }
              })
            } catch (error) {
              // console.log(error, '22')
            } finally {
            }
            actions.setSubmitting(false)
          }}
        >
          {(props) => (
            <Form className="sing-in-form">
              <Flex>
                <Field name='first_name' validate={validateFirstname}>
                  {({ field, form }) => (
                    <FormControl isRequired isInvalid={form.errors.first_name && form.touched.first_name} mr="26px">
                      <FormLabel className="name">First Name</FormLabel>
                      <Input className="input-h48" form="novalidateform" {...field} placeholder='Fitst name' focusBorderColor='#3D5A80'  errorBorderColor=' #E53E3E'/>
                      <FormErrorMessage>{form.errors.first_name}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name='last_name' validate={validateLastname}>
                  {({ field, form }) => (
                    <FormControl isRequired isInvalid={form.errors.last_name && form.touched.last_name}>
                      <FormLabel className="name">Last Name</FormLabel>
                      <Input className="input-h48" form="novalidateform" {...field} placeholder='Last name' focusBorderColor='#3D5A80'  errorBorderColor='#E53E3E' />
                      <FormErrorMessage>{form.errors.last_name}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Flex>
              <Field name='email' validate={validateEmail}>
                {({ field, form }) => (
                  <FormControl isRequired isInvalid={form.errors.email && form.touched.email}>
                    <FormLabel className="name">Email</FormLabel>
                    <Input className="input-h48" form="novalidateform" {...field} placeholder='Email' focusBorderColor='#3D5A80'  errorBorderColor='#E53E3E' />
                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name='password' validate={validatePassword}>
                {({ field, form }) => (
                  <FormControl isRequired isInvalid={form.errors.password && form.touched.password}>
                    <FormLabel className="name">Password</FormLabel>
                    <Input type="password" className="input-h48" form="novalidateform" {...field} placeholder='Password' focusBorderColor='#3D5A80'  errorBorderColor='#E53E3E' />
                    <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <div className="sign-operate">
                <Button
                  type="button"
                  _hover= {{
                    background: '#fff',
                    border: '2px solid #5BA4FA'
                  }}
                  background="#fff"
                  color="rgb(40, 58, 83)"
                  border="1px solid rgb(40, 58, 83)" 
                  borderRadius="6px"
                  isLoading={props.isSubmitting}
                  w='109px'
                  marginRight="24px"
                  onClick={onCancle}
                  fontFamily="Inter"
                >
                  Cancel
                  </Button>
                <Button
                  borderRadius="6px"
                  _hover= {{
                    background: 'rgb(40, 58, 83);',
                    border: '2px solid #5BA4FA'
                  }}
                  _click= {{
                    background: 'rgb(40, 58, 83);',
                  }}
                  background="rgb(40, 58, 83);"
                  color="#fff"
                  isLoading={props.isSubmitting}
                  type='submit'
                  w='109px'
                  fontFamily="Inter"
                >
                  Sign Up
                  </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <p className="to-sign-up">
        Already have an account? <span className="sign-in" onClick={toSignIn}>Sign in</span>
      </p>
    </Flex>
  );

}

export default SignIn;
