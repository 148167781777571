// import { useContext } from "react";
import { auth } from "./firebase";

import {
    updatePassword,
    sendPasswordResetEmail,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
} from 'firebase/auth'
// import AuthContext from "./context";
// import authStorage from "./storage";

const useAuth = () => {
    const login = (email, password) => new Promise((res, rej) =>
        signInWithEmailAndPassword(auth, email, password).then(data => res(data)).catch((error) => {
            rej(JSON.parse(JSON.stringify(error)));
        })
    )
    
    const signup = (email, password) => new Promise((res, rej) =>
        createUserWithEmailAndPassword(auth, email, password).then(data => {
            // data.user.sendEmailVerification();
            res(data)
        }).catch((error) => {
            rej(JSON.parse(JSON.stringify(error))?.customData?._tokenResponse.error);
        })
    )

    const logout = () => {
        return signOut(auth)
    }

    const resetPassword = (email) => new Promise((res, rej) =>
        sendPasswordResetEmail(auth, email).then(data => res(data)).catch((error) => {
            rej(JSON.parse(JSON.stringify(error))?.customData?._tokenResponse.error);
        })
    )

    const changePassword = (user, newPassword) => {
        return updatePassword(user, newPassword)
    }

    return { login, signup, logout, resetPassword, changePassword };
};

export default useAuth;
