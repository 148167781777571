import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import DefaultButton from 'components/button'

import AddTeamDialog from "./components/add";

import { useSelector } from 'react-redux';
import { getUserCustomClaims } from 'api/index';
import { RepeatIcon } from '@chakra-ui/icons'
import { navigatorSource } from '@utils/navigator'
import './index.scss'


const PolymofundHome = () => {
  const history = useHistory();

  const userInfo = useSelector((state) => state.userInfos)
  useEffect(() => {
    if(userInfo?.uid) {
      getTeamList()
    }
  }, [userInfo?.uid]);
  const getTeamList = () => {
    getUserCustomClaims(`/app/user/${userInfo?.uid || ''}`).then((res) => {
      if(res && res.code === 200) {
        setTeamList(res.data.campaign_team_owned || [])
      }
    })
  }
  const addDialogRef = React.useRef();
  const [teamList ,setTeamList] = useState([]);

  const onAddTeam = () => {
    addDialogRef.current.add({});
  }

  const onClickTeamName = (item) => {
    window.localStorage.setItem('user_item', JSON.stringify(item))
    if(navigatorSource() === 'PC') {
      history.push('/polymo/account-management', { item: item });
    } else {
      history.push('/polymo/switch-campaign-mobile', { item: item });

    }
  }

  const onClickRefresh = () => {
    getTeamList();
  }
  return (
    <div className="all-team-page">
      <div className="bg-line"></div>
      <div className="all-team-main">
        <div className="all-team-content">
          <div className="content-title">Hi, {userInfo.full_name ? userInfo.full_name : userInfo.email}
          </div>
          <div className="team-contents">
            <div className="team-contents-title">
              <div className="title">All teams
                <span className="refresh-btn" onClick={onClickRefresh}>
                  <RepeatIcon w={4} h={4} />
                </span> 
              </div>
              <div className="operate-content">
                {
                  userInfo.account_role === 254 ? 
                  // <span className="operate-btn" onClick={onAddTeam}>
                  //   Add new team
                  // </span>
                  <DefaultButton
                    onClick={onAddTeam}
                    name='Add new team'
                    width="130px"
                  ></DefaultButton>
                   : null
                }
                
              </div>
            </div>
            <div>
              {
                teamList.length
                ? 
                <div className="all-teams">
                  {
                    teamList?.map((item, index) => {
                      return <div className="item-team" key={index} onClick={() => onClickTeamName(item)}>{item.campaign_team_name}</div>
                    })
                  }
                </div>
                : null
              }
              {
                (!teamList.length && navigatorSource() === 'PC')
                ? 
                <div className="no-team">
                  <div className="des">You dont have any team yet. </div>
                  {
                    userInfo.account_role === 254 
                    ? <div className="add-team">Please click <span className="add-new-team" onClick={onAddTeam}>Add new team</span> button to add your team.</div>
                    : null
                  }
                  
                </div>
                : null
              }
              {
                (!teamList.length && (navigatorSource() !== 'PC'))
                ? 
                <div className="no-team-mobile">
                  <div className="des">You don’t have a workbook. </div>
                  <div>Please use our desktop site (https://polymoai.com) to setup your workbook.</div>
                </div>
                : null
              }
            </div>
          </div>
        </div>

      </div>
      <AddTeamDialog ref={addDialogRef} onAddTeam={getTeamList}></AddTeamDialog>
    </div>
  );
}
export default PolymofundHome
