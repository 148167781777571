import React, { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import {
  Box,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import sendNote from 'assets/img/send-note.svg'
import '../index.scss'
import { postMethod } from 'api/index';

const DonorDetailDialog = forwardRef((props, ref) => {
  const {onRefresh} = props;
  // const history = useHistory();
  // const userInfo = useSelector((state) => state.userInfos)
  const [visiable, setVisiable] = useState(false);
  const [rowData, setData] = useState({})
  const [teamInfo, setTeamInfo] = useState({})
  const [noteList, setNoteList] = useState([]);

  useImperativeHandle(ref, () => ({
    show: (record) => {
      setData(record)
      onChangeFormValue('', 'value')
      if (window.localStorage.getItem('user_item')) {
        setTeamInfo(JSON.parse(window.localStorage.getItem('user_item')))
      }
      setSource(record.source === 'polymo');
      if(record.source === 'polymo' && teamInfo.campaign_team_id) {
        getNodelist(record);
      }
      // 获取到行数据 取数据
      setVisiable(true);
    }
  }))

  useEffect(() => {
    if (teamInfo.campaign_team_id) {
      if(rowData.source === 'polymo') {
        getNodelist(rowData);
      }
    }
  }, [teamInfo.campaign_team_id])
  const onChangeFormValue = (value, key) => {
    setFormQuery({
      ...formQuery,
      [key]: value,
    })
  }
  const onSendNote = () => {
    if (!formQuery.value) {
      return;
    }
    const params = {
      note: formQuery.value,
      campaign_team_id: teamInfo.campaign_team_id,
      campaign_team_name: teamInfo.campaign_team_name,
      donor_id: rowData.id,
      donor_name: rowData.name
    }

    // 
    postMethod(`/app/workbook/donor/notes/add`, params).then((res) => {
      if (res && res.code === 200) {
        onChangeFormValue('', 'value')
        getNodelist(rowData)
      }
    })
  }
  const getNodelist = (row) => {
    const params = {
      did: row.id,
      // account_id: userInfo.account_id,
      tid: teamInfo.campaign_team_id
    }
    postMethod(`/app/workbook/donor/notes/1/20`, params).then((res) => {
      if (res && res.code === 200) {
        setNoteList(res.data.list)
      }
    })
  }

  const [source, setSource] = useState(false)

  const [formQuery, setFormQuery] = useState({
    value: ''
  })

  const onClose = () => {
    setVisiable(false);
  }


  return (
    <div>
      <Modal className="donor-detail-dialog" size='3xl' isOpen={visiable} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Donor details</ModalHeader>
          <ModalCloseButton _focus={{
            boxShadow: "none",
            border: "none"
          }} />
          <ModalBody>
            <div className="donor-note-content">
              <div className="donor-note">
                    {
                      source 
                      ? <div>
                          <div className="add-note">
                            <Input h="40px" placeholder='Add a note...' background="#fff" value={formQuery.value} onChange={(e) => onChangeFormValue(e.target.value, 'value')} />
                            <img className="send-note-btn" src={sendNote} alt="" onClick={onSendNote} />
                          </div>
                          <div className="note-content">

                            {
                              noteList?.map((item, index) => {
                                return <div key={index} className="note-item">
                                  <div className="note-userinfo">
                                    <div className="info-pic">
                                      <div className="user-name-default">
                                        {item.creater_full_name ? item.creater_full_name.split(' ')[0].substr(0, 1) : 'f'}
                                        {item.creater_full_name ? item.creater_full_name.split(' ')[1].substr(0, 1) : 'l'}
                                      </div>
                                    </div>
                                    <div className="userinfo">
                                      <div className="name">{item.creater_name} {item.creater_full_name}</div>
                                      <div className="time">{item.create_time}</div>
                                    </div>
                                  </div>
                                  <div className="note-des">{item.note}</div>
                                </div>
                              })
                            }
                          </div> 
                        </div>
                      : 
                      <div>
                        <div className="add-note">
                          <Input h="40px" disabled placeholder='Add a note...' background="#fff" value={formQuery.value} onChange={(e) => onChangeFormValue(e.target.value, 'value')} />
                          <img className="send-note-btn" src={sendNote} alt="" />
                          
                        </div>
                        <div className="service-unavaliable">
                          service unavaliable for private donor
                        </div>
                      </div>
                    }
                  
                </div>
            </div>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      {/* <SendDialog ref={cmsDialogRef}></SendDialog>
      <EmailDialog ref={emailDialogRef}></EmailDialog> */}

    </div>

  );
})

export default DonorDetailDialog;
