import React from "react";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { setUserInfo } from 'store/action/userInfos.js'
import DefaultButton from 'components/button'

import './index.scss'
import { successMessage } from '@utils/message'

import { postMethod, getUserList } from 'api/index';

const GeneralContent = () => {
  const history = useHistory();
  const userInfo = useSelector((state) => state.userInfos);
  const dispatch = useDispatch();
  // const validateAccountName = (value) => {
  //   let error
  //   if (!value) {
  //     error = 'Account name is required'
  //   }
  //   return error
  // }
  // const validateCampaignName = (value) => {
  //   let error
  //   if (!value) {
  //     error = 'Campaign name is required'
  //   }
  //   return error
  // }
  const validateInvitationCode = (value) => {
    let error
    if (!value) {
      error = 'Invitation code is required'
    }
    return error
  }
  
  const resetUserInfo = () => {
    getUserList('/app/fb/user').then((res) => {
      if (res && res.data) {
        if (window.location.href.includes('polymo')) {
          if (res.data.account_role === 255) {
            history.push('/polymo/sign-in')
          } else {
            window.localStorage.setItem('account', res.data.account_id || '');
            window.localStorage.setItem('userRole', res.data.account_role || '');
          }
          dispatch(setUserInfo(res.data));
          history.push('/polymo/setting', {val: 'service-plan'});
        }
      }
    })
  }
  return (
    <div className="join-page">
      <Formik
        initialValues={{invitation_code: ''}}
        onSubmit={(values, actions) => {
          try {
            // console.log(userInfo)
            const params = {
              email: userInfo?.email || '',
              ...values
            }
            postMethod('/app/team/join', params).then((res)=> {
              if(res && res.code === 200) {
                resetUserInfo();
                successMessage('success');
              }
            })
            actions.setSubmitting(false)
          } catch (error) {
            // console.log(error, '22')
          } finally {
          }
        }}
      >
        {(props) => (
          <Form className="join-form">
            {/* <Field name='account_name' validate={validateAccountName}>
              {({ field, form }) => (
                <FormControl isRequired isInvalid={form.errors.account_name && form.touched.account_name} mr="26px">
                  <FormLabel className="name">Account name</FormLabel>
                  <Input className="input-h48" form="novalidateform" {...field} placeholder='account name' focusBorderColor='#3D5A80'  errorBorderColor='#E53E3E' />
                  <FormErrorMessage>{form.errors.account_name}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name='campaign_team_name' validate={validateCampaignName}>
              {({ field, form }) => (
                <FormControl isRequired isInvalid={form.errors.campaign_team_name && form.touched.campaign_team_name}>
                  <FormLabel className="name">Campaign team name</FormLabel>
                  <Input className="input-h48" form="novalidateform" {...field} placeholder='campaign team name' focusBorderColor='#3D5A80'  errorBorderColor='#E53E3E' />
                  <FormErrorMessage>{form.errors.campaign_team_name}</FormErrorMessage>
                </FormControl>
              )}
            </Field> */}
            <Field name='invitation_code' validate={validateInvitationCode}>
              {({ field, form }) => (
                <FormControl isRequired isInvalid={form.errors.invitation_code && form.touched.invitation_code}>
                  <FormLabel className="name">Invitation code</FormLabel>
                  <Input className="input-h48" form="novalidateform" {...field} placeholder='invitation code' focusBorderColor='#3D5A80'  errorBorderColor='#E53E3E' />
                  <FormErrorMessage>{form.errors.invitation_code}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <div className="join-operate">
              <DefaultButton
                width="109px"
                name='Join'
                type='submit'
              ></DefaultButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
export default GeneralContent