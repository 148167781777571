/* eslint-disable */
import {
  // Flex,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import React, { forwardRef, useImperativeHandle, useState } from "react";

const DevelopmentDeleteDialog = forwardRef((props, ref) => {
  const {onDelete} = props;
  const [visiable, setVisiable] = useState(false);
  const cancelRef = React.useRef();
  const [rowData, setData] = useState({})
  useImperativeHandle(ref, () => ({
    delete: (record) => {
      setData(record)
      setVisiable(true);
    }
  }))
  const onClose = () => {
    setVisiable(false);
  }
  const onSure = () => {
    setVisiable(false);
    // 调取删除接口然后刷新列表
    onDelete(rowData);
  }
 
  return (
    <Modal size='xl'  isOpen={visiable}  onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete</ModalHeader>
        <ModalCloseButton _focus={{
          boxShadow: "none",
          border: "none"
        }} />
        <ModalBody>
          <Box>
            Are you sure delete {rowData.name}?
          </Box>
        </ModalBody>
        <ModalFooter>
        <Button ref={cancelRef} onClick={onClose} colorScheme='blue' mr={3}>
          Close
        </Button>
        <Button onClick={onSure} type='submit' colorScheme='teal' >Sure</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
    
  );
})

export default DevelopmentDeleteDialog;
