import React, { useState, useEffect } from "react";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Textarea,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  // useDisclosure,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import DefaultButton from 'components/button'

import { setUserInfo } from 'store/action/userInfos.js'
import { successMessage } from '@utils/message'

import { getUserCustomClaims } from 'api/index'
import { subscribePublic } from 'api/setting'

import { getMethod, postMethod, getUserList } from 'api/index';
import './index.scss'

const ServicePlanContent = () => {
  const history = useHistory();
	const dispatch = useDispatch();
  const [planList, setPlanList] = useState([]);
  const [servicePlanName, setServicePlanName] = useState('');
  const [campaignTeamList, setCampaignTeamList] = useState([]);
  const [dueDay, setDueDay] = useState('');

  const [isHaveAccount, setStatus] = useState(false)
  const [accountRole, setAccountRole] = useState(false)

  const userInfo = useSelector((state) => state.userInfos)

  const [accountName, setAccountName] = useState('')
  useEffect(() => {
    if(userInfo?.uid) {
      getInfo();
      getPlanList();
    }
    if(window.localStorage.getItem('account')) {
      setStatus(true)
    }
    if(Number(window.localStorage.getItem('userRole')) === 254) {
      setAccountRole(true)
    }
  }, [userInfo?.uid, window.localStorage.getItem('account'), window.localStorage.getItem('userRole')]);
  const getInfo = () => {
    if(window.localStorage.getItem('account')) {
      setStatus(true)
    }
    if(window.localStorage.getItem('userRole') === 254) {
      setAccountRole(true)
    }
    getUserCustomClaims(`/app/user/${userInfo?.uid || ''}`).then((res) => {
      if(res) {
        setDueDay(res.data.account_expiration_time)
        setCampaignTeamList(res.data.campaign_team_owned || [])
        setAccountName(res.data.account_name)
      }
    })
  }
  const getPlanList = () => {
    getMethod('/api/service/plan/list').then((res) => {
      if (res && res.code === 200) {
        setPlanList(res.data);
        const item = res.data.find((i) => {
          return i.id === userInfo.service_plan_id
        })
        setServicePlanName(item?.service_name)
      }
    })
  }
  /**
   * 控制change plan
  */
  const [changePlanVisiable, setChangePlanVisiable] = useState(false);
  /**
   * 控制terminate
  */
 const [terminateVisiable, setTerminateVisiable] = useState(false);
  const onCloseChangePlan = () => {
    setChangePlanVisiable(false);
  }
  const onSureChangePlan = () => {
    const params = {
      account_id: userInfo?.account_id,
      current_service_plan_id: userInfo?.service_plan_id || '',
      user_id: userInfo?.user_id,
      email: userInfo?.email || '',
      phone_number: userInfo?.phone_number || '',
    }
    postMethod('/app/serviceplan/change', params).then((res) => {
      if(res && res.code === 200) {
        resetUserInfo();
        setChangePlanVisiable(false);
        successMessage('send success');
      }
    })
  }

  const onCloseTerminate = () => {
    setTerminateVisiable(false);
  }
  // const onSureChangePlan = () => {
  //   setChangePlanVisiable(false);
  // }
  const resetUserInfo = (path) => {
    getUserList('/app/fb/user').then((res) => {
      if (res && res.data) {
        window.localStorage.setItem('account', res.data.account_id || '');
        window.localStorage.setItem('userRole', res.data.account_role || '');
        dispatch(setUserInfo(res.data));
        if(path) {
          history.push(path);
        }
      }
    })
  }
  const onQuit = () => {
    postMethod(`/app/account/user/quit/${userInfo?.account_id}/${userInfo?.user_id}`).then((res) => {
      if(res && res.code === 200) {
        resetUserInfo('/polymo/home');
        successMessage('success');
        window.localStorage.removeItem('account');
        setStatus(false);
      }
    })
  }
  const onTerminate = () => {
    const params = {
      account_id: userInfo?.account_id,
      current_service_plan_id: userInfo?.service_plan_id || '',
      user_id: userInfo?.user_id,
      email: userInfo?.email || '',
      phone_number: userInfo?.phone_number || '',
    }
    postMethod('/app/subscribe/terminate', params).then((res) => {
      if(res && res.code === 200) {
        resetUserInfo();
        setTerminateVisiable(true)
      }
    })

  }
  const onChangePlan = () => {
    setChangePlanVisiable(true);
  }
  const onGoPayment = () => {
		history.push(`/polymo/payment`);
	}
  const validateFirstname = (value) => {
    let error
    if (!value) {
      error = 'First name is required'
    }
    return error
  }
  const validateLastname = (value) => {
    let error
    if (!value) {
      error = 'Last name is required'
    }
    return error
  }
  const validateEmail = (value) => {
    let error
    if (!value) {
      error = 'Email is required'
    }
    return error
  }
  const validatePhone = (value) => {
    let error
    if (!value) {
      error = 'Phone is required'
    }
    return error
  }
  const validatePlan = (value) => {
    let error
    if (!value) {
      error = 'Service plan is required'
    }
    return error
  }
  return (
    <div className="service-plan-page">
      <div className="service-plan-name">
        <span className="name">PolymoFund</span>
      </div>
      {
        isHaveAccount ?
          <div className="no-join-team">
            {
              accountRole ? 
              <div className="account-content">
                <div className="account-name">Service plan</div>
                <div className="account-name"><span className="dot"></span>{ servicePlanName } <span className="due-day">(Due Date: { dueDay })</span></div>
              </div> :
              null
            }
            <div className="account-content">
              <div className="account-name">Associated account</div>
              <div className="account-name"><span className="dot"></span>{ accountName }</div>
            </div>
            <div className="campaign-teams">Campaign teams</div>
            <div className="team-content">
              {
                campaignTeamList?.map((item, index) => {
                  return <div className="team-item" key={index}>
                    <span className="dot"></span>{item.campaign_team_name}
                  </div>
                })
              }
            </div>
            {
              accountRole ? 
              <div className="operate-content">
                <DefaultButton
                  onClick={onChangePlan}
                  name='Change plan'
                  width="120px"
                ></DefaultButton>
                <DefaultButton
                  onClick={onTerminate}
                  name='Terminate'
                  width="120px"
                  marginLeft="20px"
                ></DefaultButton>
                <DefaultButton
                  onClick={onGoPayment}
                  name='Payment'
                  width="120px"
                  marginLeft="20px"
                ></DefaultButton>
              </div> : 
              <div className="operate-content">
                <DefaultButton
                  onClick={onQuit}
                  name='Quit'
                  width="120px"
                  marginLeft="40px"
                ></DefaultButton>
              </div>
            }
          </div> :
          <div className="create-account-content">
            <div className="des">There is no associated account or subscription. Please create a new one.</div>
            <Formik
              initialValues={{ 
                email: userInfo?.email || '',
                phone_number: '',
                first_name: userInfo?.first_name || '',
                last_name: userInfo?.last_name || '',
                message: '',
                service_plan_id: ''
              }}
              onSubmit={(values, actions) => {
                try {
                  subscribePublic('/app/subscribe/public', values).then((res) => {
                    if(res && res.code === 200) {
                      successMessage('success');
                    }
                  })
                  actions.setSubmitting(false)
                } catch (error) {
                  // console.log(error, '22')
                } finally {
                }
              }}
            >
              {(props) => (
                <Form className="service-plan-form">
                  <Flex>
                    <Field name='first_name' validate={validateFirstname}>
                      {({ field, form }) => (
                        <FormControl isRequired isInvalid={form.errors.first_name && form.touched.first_name} mr="26px">
                          <FormLabel className="name">First Name</FormLabel>
                          <Input disabled className="input-h48" form="novalidateform" {...field} placeholder='First name' focusBorderColor='#3D5A80'  errorBorderColor='#E53E3E' />
                          <FormErrorMessage>{form.errors.first_name}</FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name='last_name' validate={validateLastname}>
                      {({ field, form }) => (
                        <FormControl isRequired isInvalid={form.errors.last_name && form.touched.last_name}>
                          <FormLabel className="name">Last Name</FormLabel>
                          <Input disabled className="input-h48" form="novalidateform" {...field} placeholder='Last name' focusBorderColor='#3D5A80'  errorBorderColor='#E53E3E' />
                          <FormErrorMessage>{form.errors.last_name}</FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Flex>
                  <Field name='phone_number' validate={validatePhone}>
                    {({ field, form }) => (
                      <FormControl isRequired isInvalid={form.errors.phone_number && form.touched.phone_number}>
                        <FormLabel className="name">Phone</FormLabel>
                        <Input className="input-h48" form="novalidateform" {...field} placeholder='Phone' focusBorderColor='#3D5A80'  errorBorderColor='#E53E3E' />
                        <FormErrorMessage>{form.errors.phone_number}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="service_plan_id" validate={validatePlan} mt="10px">
                    {({ field, form }) => (
                      <FormControl isRequired isInvalid={form.errors.service_plan_id && form.touched.service_plan_id}>
                        <FormLabel className="name">Service plan</FormLabel>
                        <Select className="input-h48" form="novalidateform" errorBorderColor='#E53E3E' placeholder="Please choose service plan" {...field}>
                          {
                            planList?.map((item, index) => {
                              return <option key={index} value={item.id}>{item.service_name}</option>
                            })
                          }
                        </Select>
                        <FormErrorMessage>{form.errors.service_plan_id}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name='email' validate={validateEmail}>
                    {({ field, form }) => (
                      <FormControl isRequired isInvalid={form.errors.email && form.touched.email}>
                        <FormLabel className="name">Email</FormLabel>
                        <Input disabled className="input-h48" form="novalidateform" {...field} placeholder='Email' focusBorderColor='#3D5A80'  errorBorderColor='#E53E3E' />
                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name='message'>
                    {({ field, form }) => (
                      <FormControl>
                        <FormLabel className="name">Message</FormLabel>
                        <Textarea form="novalidateform" {...field} placeholder='Message' />
                      </FormControl>
                    )}
                  </Field>

                  <div className="service-plan-operate">
                    <DefaultButton
                      type='submit'
                      name='Request to Create'
                      width="170px"
                    ></DefaultButton>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
      }
      <Modal onClose={onCloseChangePlan} isOpen={changePlanVisiable} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Are you sure to change plan</ModalHeader>
          <ModalCloseButton _focus={{
          boxShadow: "none",
          border: "none",
          background: "none"
        }} />
          <ModalBody>
            It will take a few business days to change your plan. Are you sure to make the change?
          </ModalBody>
          <ModalFooter>
            <DefaultButton
              onClick={onCloseChangePlan}
              name='Cancel'
              background="#EDF2F7"
              width="86px"
              color="#000"
            ></DefaultButton>
            {/* <Button onClick={onCloseChangePlan} background="#EDF2F7" colorScheme='#EDF2F7' color="#000" borderRadius={borderRadius} mr="12px" >Cancel</Button> */}
            <DefaultButton
              onClick={onSureChangePlan}
              name='Yes'
              width="60px"
              marginLeft="12px"
            ></DefaultButton>
            {/* <Button onClick={onSureChangePlan} background="#3182CE" borderRadius={borderRadius} type='submit' colorScheme='#3182CE' >Yes</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal onClose={onCloseTerminate} isOpen={terminateVisiable} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton _focus={{
          boxShadow: "none",
          border: "none",
          background: "none"
        }} />
          <ModalBody>
          PolymoAI has recieved your request about changing plan. We will get back to you in a few business days.
          </ModalBody>
          <ModalFooter>
            <DefaultButton
              onClick={onCloseTerminate}
              name='Close'
              background="#EDF2F7"
              width="86px"
              colorScheme='#EDF2F7'
              color="#000"
            ></DefaultButton>
            {/* <Button onClick={onCloseTerminate} background="#EDF2F7" colorScheme='#EDF2F7' color="#000" borderRadius={borderRadius}>Close</Button> */}
            {/* <Button onClick={onSure} type='submit' colorScheme='teal' >Sure</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}
export default ServicePlanContent