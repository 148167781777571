import React from "react";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import DefaultButton from 'components/button'
import { successMessage } from '@utils/message'
import { restPassword } from 'api/setting'
import './index.scss'

const ChangePasswordContent = () => {
  const userInfo = useSelector((state) => state.userInfos)

  const validatecConfirmPassward = (value) => {
    let error
    if (!value) {
      error = 'Password is required'
    } else if (value.length < 6) {
      error = 'Use at least 6 characters'
    }
    return error
  }
  const validatePassward = (value) => {
    let error
    if (!value) {
      error = 'Password is required'
    } else if (value.length < 6) {
      error = 'Use at least 6 characters'
    }
    return error
  }

  return (
    <div className="change-password-page">
      <Formik
        initialValues={{ password: '', confirm_password: '' }}
        onSubmit={(values, actions) => {
          try {
            const params = {
              id: userInfo?.uid || '',
              ...values
            }
            restPassword('/app/fb/user/password/rest', params).then((res) => {
              if(res && res.code === 200) {
                successMessage('success');
              }
            })
            
            actions.setSubmitting(false)
          } catch (error) {
            // console.log(error, '22')
          } finally {
          }
        }}
      >
        {(props) => (
          <Form className="change-password-form">
            <Field name='password' validate={validatePassward}>
              {({ field, form }) => (
                <FormControl isRequired isInvalid={form.errors.password && form.touched.password}>
                  <FormLabel className="name">New password</FormLabel>
                  <Input className="input-h48" form="novalidateform" {...field} placeholder='New password' focusBorderColor='#3D5A80'  errorBorderColor='#E53E3E' />
                  <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name='confirm_password' validate={validatecConfirmPassward}>
              {({ field, form }) => (
                <FormControl isRequired isInvalid={form.errors.confirm_password && form.touched.confirm_password}>
                  <FormLabel className="name">Confirm password</FormLabel>
                  <Input className="input-h48" form="novalidateform" {...field} placeholder='Confirm password' focusBorderColor='#3D5A80'  errorBorderColor='#E53E3E' />
                  <FormErrorMessage>{form.errors.confirm_password}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <div className="change-password-operate">
              <DefaultButton
                fontWeight="600"
                width="120px"
                name='Save Change'
                type='submit'
              ></DefaultButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
export default ChangePasswordContent