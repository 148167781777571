import React, {useState, useEffect} from "react";
import { useHistory } from 'react-router-dom';

import GeneralContent from './components/general'
import ServicePlanContent from './components/service-plan'
import ChangePasswordContent from './components/change-password'
import JoinContent from './components/join'

import './index.scss'

export default function Settings() {
  const [tab, setTabs] = useState(0);
  const history = useHistory();
  const tabActive = history.location.state?.val || '';
  useEffect(() => {
    if(tabActive === 'general') {
      setTabs(0)
    } else if(tabActive === 'change-password') {
      setTabs(1)
    } else if(tabActive === 'service-plan') {
      setTabs(2)
    } else if(tabActive === 'invitation') {
      setTabs(3)
    } else {
      setTabs(0)
    }
  }, [tabActive]);
  const navContent = [{
    title: 'General',
    component: <GeneralContent />,
    key: 'general'
  },{
    title: 'Change password',
    component: <ChangePasswordContent />,
    key: 'change-password'
  },{
    title: 'Service plan',
    component: <ServicePlanContent />,
    key: 'service-plan'
  },{
    title: 'Invitation',
    component: <JoinContent />,
    key: 'invitation'
  }]
  const onChangeTab = (item, index) => {
    history.replace({ ...window.location, state: {val: item.key} });
    setTabs(index);
  }
  return (
    <div className="setting-page">
      <div className="bg-line"></div>
      <div className="setting-content">
        <div className="setting-title">Account</div>
        <div className="content">
          <div className="tab">
            {
              navContent?.map((item, index) => {
                return <div className={['tab-item', index===tab?'active':null].join(' ')} key={index} onClick={() => onChangeTab(item, index)}>
                  {item.title}
                </div>
              })
            }
          </div>
          <div className="tab-content">
            {
              navContent[tab]?.component || <div>--</div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
