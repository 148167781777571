import React from "react";
import {
  Flex,
  Text,
} from "@chakra-ui/react";
import DeleteSvg from 'assets/img/account/delete.svg'
import DeleteHoverSvg from 'assets/img/account/delete_hover.svg'

// const yesOrNoMap = {
//   1: 'YES',
//   2: 'NO'
// }

// const sourceMap = {
//   1: 'Private',
//   2: 'Polymo'
// }

export const columns = (props) => {
  const {
    deleteDialog = () => { },
  } = props ?? {}
  return [
    {
      title: "NAME",
      dataIndex: "name",
      // isSort: true,
      render: (val, item, index) => {
        return !val ? `${item.first_name} ${item.last_name}` : item.name;
      }
    },
    {
      title: "PHONE",
      dataIndex: "phone_number",
      // isSort: true,
    },
    {
      title: "LAST ACTIVE DATE",
      dataIndex: "last_donation",
      isSort: true,
    },
    {
      title: "FREQUENCY",
      dataIndex: "frequency",
      isSort: true,
    },
    {
      title: "TOTAL AMOUNT",
      dataIndex: "total_donation",
      isSort: true,
    },
    {
      title: "RACE",
      dataIndex: "race",
      // isSort: true,
    },
    {
      title: "DONATED",
      dataIndex: "donated",
      // isSort: true,
      render: (val, item, index) => {
        return val === 1 ? <span className="tag-item tag-item-yes">YES</span> : <span className="tag-item tag-item-no">NO</span>;
      }
    },
    {
      title: "PLEDGE",
      dataIndex: "pledge",
      // isSort: true,
      render: (val, item, index) => {
        return val === 1 ? <span className="tag-item tag-item-yes">YES</span> : <span className="tag-item tag-item-no">NO</span>;
      }
    },
    {
      title: "CALLED",
      dataIndex: "called",
      // isSort: true,
      render: (val, item, index) => {
        return val === 1 ? <span className="tag-item tag-item-yes">YES</span> : <span className="tag-item tag-item-no">NO</span>;
      }
    },
    {
      title: "FOLLOW UP",
      dataIndex: "follow_up",
      // isSort: true,
      render: (val, item, index) => {
        return val === 1 ? <span className="tag-item tag-item-yes">YES</span> : <span className="tag-item tag-item-no">NO</span>;
      }
    },
    {
      title: "DO NOT CALL",
      dataIndex: "do_not_call",
      // isSort: true,
      render: (val, item, index) => {
        return val === 1 ? <span className="tag-item tag-item-no">YES</span> : <span className="tag-item tag-item-yes">NO</span>;
      }
    },
    {
      title: "PRECIENT",
      dataIndex: "precinct_desc",
    },
    {
      title: "MUNICIPALITY",
      dataIndex: "municipality_desc",
    },
    {
      title: "CONGRESS DIST",
      dataIndex: "cong_dist_abbrv",
    },
    {
      title: "NC HOUSE ABBRV",
      dataIndex: "nc_house_abbrv",
    },
    {
      title: "NC SENATE ABBRV",
      dataIndex: "nc_senate_abbrv",
    },
    {
      title: "SOURCE",
      dataIndex: "source",
      // isSort: true,
      render: (val, item, index) => {
        
        return val === 'polymo' 
        ? <span className="tag-item-source tag-item-source-polymo">Polymo</span> 
        : val === 'private'
        ? <span className="tag-item-source tag-item-source-private">Private</span> 
        : null
      }
    },
    {
      title: "ADD TIME",
      dataIndex: "create_time",
    },
    {
      title: "Operation",
      render(val, item, index) {
        return <>
          <Flex mw="180px">
            <Text cursor="pointer" onClick={(e) => {
              e?.stopPropagation()
              deleteDialog(item)
            }} className="operate-container" display="inline-block" ml="8px" fontSize='sm' fontWeight='700'>
              <img className="operate-delete" src={DeleteSvg} alt=""/>
              <img className="operate-hover-delete" src={DeleteHoverSvg} alt=""/>
            </Text>
          </Flex>
        </>
      }
    },
  ];
  
}

export const columnsDetail = (props) => {
  const {
    teamInfo
  } = props ?? {}
  return [
    {
      title: "Donated to",
      dataIndex: "committee_name",
      render: (val, item, index) => {
        if(teamInfo.committee_sboe_id !== item.committee_sboe_id) {
          return <>
            <span>{val}</span>
          </>
        } else {
          if(item.committee_party === 'DEM') {
            return <>
              <span style={{color:'#5096FF'}}>{val}</span>
            </>
          } else if(item.committee_party === 'REP') {
            return <>
              <span style={{color:'#FF7D85'}}>{val}</span>
            </>
          } else if(item.committee_party === 'UNA') {
            return <>
              <span style={{color:'#9B8BFF'}}>{val}</span>
            </>
          }
        }
      }
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (val, item, index) => {
        if(teamInfo.committee_sboe_id !== item.committee_sboe_id) {
          return <>
            <span>{parseInt(val)}</span>
          </>
        } else {
          if(item.committee_party === 'DEM') {
            return <>
              <span style={{color:'#5096FF'}}>{parseInt(val)}</span>
            </>
          } else if(item.committee_party === 'REP') {
            return <>
              <span style={{color:'#FF7D85'}}>{parseInt(val)}</span>
            </>
          } else if(item.committee_party === 'UNA') {
            return <>
              <span style={{color:'#9B8BFF'}}>{parseInt(val)}</span>
            </>
          }
        }
      }
    },
    {
      title: "Party",
      dataIndex: "committee_party",
      render: (val, item, index) => {
        if(teamInfo.committee_sboe_id !== item.committee_sboe_id) {
          return <>
            <span>{val}</span>
          </>
        } else {
          if(item.committee_party === 'DEM') {
            return <>
              <span style={{color:'#5096FF'}}>{val}</span>
            </>
          } else if(item.committee_party === 'REP') {
            return <>
              <span style={{color:'#FF7D85'}}>{val}</span>
            </>
          } else if(item.committee_party === 'UNA') {
            return <>
              <span style={{color:'#9B8BFF'}}>{val}</span>
            </>
          }
        }
      }
    },
    {
      title: "Date",
      dataIndex: "date_occured",
      render: (val, item, index) => {
        if(teamInfo.committee_sboe_id !== item.committee_sboe_id) {
          return <>
            <span>{val}</span>
          </>
        } else {
          if(item.committee_party === 'DEM') {
            return <>
              <span style={{color:'#5096FF'}}>{val}</span>
            </>
          } else if(item.committee_party === 'REP') {
            return <>
              <span style={{color:'#FF7D85'}}>{val}</span>
            </>
          } else if(item.committee_party === 'UNA') {
            return <>
              <span style={{color:'#9B8BFF'}}>{val}</span>
            </>
          }
        }
      }
    },
    // {
    //   title: "Form of payment",
    //   dataIndex: "form_of_payment",
    //   render: (val, item, index) => {
    //     if(teamInfo.committee_sboe_id !== item.committee_sboe_id) {
    //       return <>
    //         <span>{val}</span>
    //       </>
    //     } else {
    //       if(item.committee_party === 'DEM') {
    //         return <>
    //           <span style={{color:'#5096FF'}}>{val}</span>
    //         </>
    //       } else if(item.committee_party === 'REP') {
    //         return <>
    //           <span style={{color:'#FF7D85'}}>{val}</span>
    //         </>
    //       } else if(item.committee_party === 'UNA') {
    //         return <>
    //           <span style={{color:'#9B8BFF'}}>{val}</span>
    //         </>
    //       }
    //     }
    //   }
    // },
  ]
}