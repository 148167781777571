/* eslint-disable */
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { Field, Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DefaultButton from 'components/button'

import React, { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import CustomUpload from "@/components/upload/index.jsx"
import '../index.scss'
import { getMethod, postMethod } from 'api/index';

const DonorDataDialog = forwardRef((props, ref) => {
  const history = useHistory();
  const userInfo = useSelector((state) => state.userInfos)
  const uploadDialogRef = React.useRef();
  const [visiable, setVisiable] = useState(false);
  const [file, setFile] = useState({})
  const cancelRef = React.useRef();
  const borderRadius = '6px'
  useImperativeHandle(ref, () => ({
    show: () => {
      setVisiable(true);
    }
  }))

  const [optionList, setOptionList] = useState([]);
  // useEffect(() => {
    
  // }, [])

  const onClose = () => {
    setMatchColumn(false);
    setFile({});
    setVisiable(false);
  }

  // 选择完文件点击
  const [onMatchColumn, setMatchColumn] = useState(false);
  const onClickMatchColumn = () => {
    // // console.log(file)
    // setMatchColumn(true);
    const formData = new FormData();
    formData.append('file', file.files);
    // formData.append('header', 1)
    // // console.log(formData, 'formData');
    postMethod('/app/campaign/donor/upload/csv/columns', formData).then((res) => {
      if(res && res.code === 200) {
        setOptionList(res.data)
        setMatchColumn(true);
      }
    })
  }

  const validateFirstname = (value) => {
    let error
    if (!value) {
      error = 'First name is required'
    }
    return error
  }
  const validateLastname = (value) => {
    let error
    if (!value) {
      error = 'Last name is required'
    }
    return error
  }
  const validateCity = (value) => {
    let error
    if (!value) {
      error = 'City is required'
    }
    return error
  }
  const validateState = (value) => {
    let error
    if (!value) {
      error = 'State is required'
    }
    return error
  }
  const validateZipCode = (value) => {
    let error
    if (!value) {
      error = 'Zip code is required'
    }
    return error
  }
  return (
    <Modal size='6xl' isOpen={visiable} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        {
          !Object.keys(file).length ? <ModalHeader>Upload your Donor data</ModalHeader> : null
        }
        {
          Object.keys(file).length ? <ModalHeader>Match your csv data with our column</ModalHeader> : null
        }
        <ModalCloseButton _focus={{
          boxShadow: "none",
          border: "none"
        }} />
        <ModalBody>

          {
            !onMatchColumn
              ?
              <div className="upload-csv-content">
                <CustomUpload value={file}
                  onChange={(file) => {
                    // console.log(file)
                    setFile(file)
                  }}
                  onClear={() => {
                    setFile({})
                  }} />
              </div>
              : null
          }
          {
            onMatchColumn
              ?
              <div className="match-column-content">
                <div className="match-column-des">
                  Please select the column name in the drop down box to match the name on the left. All the non selected columns will NOT be imported into your workbook from your uploaded csv file.
                </div>
                <div>
                  <Formik
                    initialValues={{ email: '', phone_number: '', first_name: '', last_name: '', street1: '', street2: '', city: '', state: '', zip_code: '' }}
                    onSubmit={(values, actions) => {
                      try {
                        const formData = new FormData();
                        formData.append('file', file.files);
                        formData.append('email', values.email);
                        formData.append('phone_number', values.phone_number);
                        formData.append('first_name', values.first_name);
                        formData.append('last_name', values.last_name);
                        formData.append('street1', values.street1);
                        formData.append('street2', values.street2);
                        formData.append('state', values.state);
                        formData.append('zip_code', values.zip_code);
                        formData.append('city', values.city);
                        formData.append('account_name', userInfo.account_id);
                        formData.append('campaign_team_name', history.location.state.item.campaign_team_id);
                        postMethod('/app/campaign/donor/upload', formData).then((res) => {
                          if(res && res.code === 200) {
                            if(window.localStorage.getItem('from') === 'account') {
                              history.push('/polymo/account-management', { item: history.location.state.item })
                            } else if(window.localStorage.getItem('from') === 'switch') {
                              history.push('/polymo/switch-campaign', { item: history.location.state.item })
                            }
                            // setOptionList(res.data)
                            // setMatchColumn(true);
                          }
                        })
                        actions.setSubmitting(false)
                      } catch (error) {
                        // console.log(error, '22')
                      } finally {
                      }
                    }}
                  >
                    {(props) => (
                      <Form className="form-flex">
                        <Flex>
                          <Field name='first_name' validate={validateFirstname}>
                            {({ field, form }) => (
                              <FormControl className="form-control" isRequired isInvalid={form.errors.first_name && form.touched.first_name} mr="111px">
                                <div className="control">
                                  <FormLabel className="label">First Name</FormLabel>
                                  <Select className="input-h32" placeholder='Select your first name' form="novalidateform" {...field}>
                                    {
                                      optionList?.map((item, index) => {
                                        return <option key={index} value={item}>{item}</option>
                                      })
                                    }
                                  </Select>
                                </div>
                                <FormErrorMessage className="error-mes">{form.errors.first_name}</FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                          <Field name='last_name' validate={validateLastname}>
                            {({ field, form }) => (
                              <FormControl className="form-control" isRequired isInvalid={form.errors.last_name && form.touched.last_name} mr="36px">
                                <div className="control">
                                  <FormLabel className="label">Last Name</FormLabel>
                                  <Select className="input-h32" placeholder='Select your last name' form="novalidateform" {...field}>
                                    {
                                      optionList?.map((item, index) => {
                                        return <option key={index} value={item}>{item}</option>
                                      })
                                    }
                                  </Select>
                                </div>
                                <FormErrorMessage className="error-mes">{form.errors.last_name}</FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Flex>
                        <Flex>
                          <Field name='phone_number'>
                            {({ field, form }) => (
                              <FormControl className="form-control" isInvalid={form.errors.phone_number && form.touched.phone_number} mr="111px">
                                <div className="control">
                                  <FormLabel className="label">Phone</FormLabel>
                                  <Select className="input-h32" placeholder='Select your phone_number' form="novalidateform" {...field}>
                                    {
                                      optionList?.map((item, index) => {
                                        return <option key={index} value={item}>{item}</option>
                                      })
                                    }
                                  </Select>
                                </div>

                              </FormControl>
                            )}
                          </Field>
                          <Field name='email'>
                            {({ field, form }) => (
                              <FormControl className="form-control" isInvalid={form.errors.email && form.touched.email} mr="36px">
                                <div className="control">
                                  <FormLabel className="label">Email</FormLabel>
                                  <Select className="input-h32" placeholder='Select your email' form="novalidateform" {...field}>
                                    {
                                      optionList?.map((item, index) => {
                                        return <option key={index} value={item}>{item}</option>
                                      })
                                    }
                                  </Select>
                                </div>
                                <FormErrorMessage className="error-mes">{form.errors.email}</FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Flex>
                        <Flex>
                          <Field name='street1'>
                            {({ field, form }) => (
                              <FormControl className="form-control" isInvalid={form.errors.street1 && form.touched.street1} mr="111px">
                                <div className="control">
                                  <FormLabel className="label">Address1</FormLabel>
                                  <Select className="input-h32" placeholder='Select your street1' form="novalidateform" {...field}>
                                    {
                                      optionList?.map((item, index) => {
                                        return <option key={index} value={item}>{item}</option>
                                      })
                                    }
                                  </Select>
                                </div>
                              </FormControl>
                            )}
                          </Field>
                          <Field name='street2'>
                            {({ field, form }) => (
                              <FormControl className="form-control" isInvalid={form.errors.street2 && form.touched.street2} mr="36px">
                                <div className="control">
                                  <FormLabel className="label">Address2</FormLabel>
                                  <Select className="input-h32" placeholder='Select your street2' form="novalidateform" {...field}>
                                    {
                                      optionList?.map((item, index) => {
                                        return <option key={index} value={item}>{item}</option>
                                      })
                                    }
                                  </Select>
                                </div>
                              </FormControl>
                            )}
                          </Field>
                        </Flex>
                        <Flex>
                          <Field name='city' validate={validateCity}>
                            {({ field, form }) => (
                              <FormControl className="form-control" isRequired isInvalid={form.errors.city && form.touched.city} mr="111px">
                                <div className="control">
                                  <FormLabel className="label">City</FormLabel>
                                  <Select className="input-h32" placeholder='Select your city' form="novalidateform" {...field}>
                                    {
                                      optionList?.map((item, index) => {
                                        return <option key={index} value={item}>{item}</option>
                                      })
                                    }
                                  </Select>
                                </div>
                                <FormErrorMessage className="error-mes">{form.errors.city}</FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                          <Field name='state' validate={validateState}>
                            {({ field, form }) => (
                              <FormControl className="form-control" isRequired isInvalid={form.errors.state && form.touched.state} mr="36px">
                                <div className="control">
                                  <FormLabel className="label">State</FormLabel>
                                  <Select className="input-h32" placeholder='Select your state' form="novalidateform" {...field}>
                                    {
                                      optionList?.map((item, index) => {
                                        return <option key={index} value={item}>{item}</option>
                                      })
                                    }
                                  </Select>
                                </div>
                                <FormErrorMessage className="error-mes">{form.errors.state}</FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Flex>

                        <Flex>
                          <Field name='zip_code' validate={validateZipCode}>
                            {({ field, form }) => (
                              <FormControl className="form-control" isRequired isInvalid={form.errors.zip_code && form.touched.zip_code} mr="111px">
                                <div className="control">
                                  <FormLabel className="label">Zip code</FormLabel>
                                  <Select className="input-h32" placeholder='Select your zip code' form="novalidateform" {...field}>
                                    {
                                      optionList?.map((item, index) => {
                                        return <option key={index} value={item}>{item}</option>
                                      })
                                    }
                                  </Select>
                                </div>
                                <FormErrorMessage className="error-mes">{form.errors.zip_code}</FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                          <Field>
                            {({ field, form }) => (
                              <FormControl className="form-control" mr="36px">
                                <div className="control">
                                </div>
                              </FormControl>
                            )}
                          </Field>
                        </Flex>

                        <Flex justifyContent="flex-end" mt="80px">
                          {
                            onMatchColumn ?
                            <div>
                              {/* <Button onClick={onClose} background="#EDF2F7" colorScheme='#EDF2F7' color="#000" borderRadius={borderRadius} mr="12px" >Cancel</Button> */}
                              {/* <Button type='submit' background="#3D5A80" colorScheme='#3D5A80' color="#fff" borderRadius={borderRadius} >Load data</Button> */}
                              <DefaultButton
                                onClick={onClose}
                                name='Cancel'
                                width="109px"
                                color="#000"
                                background="#EDF2F7"
                                marginLeft="12px"
                              ></DefaultButton>
                              <DefaultButton
                                name='Add to workbook'
                                type='submit'
                                width="160px"
                                marginLeft="12px"
                              ></DefaultButton>
                            </div>
                              : null
                          }
                        </Flex>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
              : null
          }
        </ModalBody>

        {
          !onMatchColumn
            ?
            <ModalFooter>
              {/* <Button onClick={onClose} background="#EDF2F7" colorScheme='#EDF2F7' color="#000" borderRadius={borderRadius} mr="12px" >Cancel</Button> */}
              <DefaultButton
                onClick={onClose}
                name='Cancel'
                width="109px"
                color="#000"
                background="#EDF2F7"
                marginLeft="12px"
              ></DefaultButton>
              {
                Object.keys(file).length && !onMatchColumn
                  ?
                  <DefaultButton
                    name='Match data columns'
                    onClick={onClickMatchColumn}
                    width="180px"
                    marginLeft="12px"
                  ></DefaultButton>
                  // <Button background="#3D5A80" colorScheme='#3D5A80' color="#fff" borderRadius={borderRadius} onClick={onClickMatchColumn} >Match data columns</Button>
                  : null
              }
            </ModalFooter>
            : null
        }
      </ModalContent>
    </Modal>

  );
})

export default DonorDataDialog;
