import React, { useRef, useState, useImperativeHandle, forwardRef } from "react"
import {
  Progress
} from "@chakra-ui/react";
import { DeleteIcon } from '@chakra-ui/icons'
import './index.scss'
const CustomUpload = forwardRef((props, ref) => {
  const { value, onChange, onClear } = props;
  const [status, setStatus] = useState('')
  useImperativeHandle(ref, () => ({
  }))
  const fileRef = useRef()
  const [file, setFile] = React.useState({})
  const [progressValue, setProgressValue] = useState(0)
  const [timer, setTimer] = useState(null)
  const onTrigger = (e) => {
    e?.stopPropagation();
    fileRef?.current?.click()
  }
  const onFileChange = (e) => {
    setStatus('progress')
    setFile(e?.target.files[0])
    onChange({
      files: e?.target.files[0]
    })
    // 调接口 拿到百分比
    setProgressValue(50)
    setTimer(setTimeout(() => {
      // onChange({
      //   files: file
      // })
      setProgressValue(100)
      setStatus('')
    }, 0))
  }
  const onBlur = () => {
    setTimer(null)
    // setFile({})
    setStatus('')
    setProgressValue(0)
  }
  const onDeleteFile = () => {
    setTimer(null)
    setFile({})
    setStatus('')
    setProgressValue(0)
    onClear()
  }
  return <div>
    {
      !status && !Object.keys(value)?.length
        ?
        <div className="upload-file-content">
          <div className="upload-icon"></div>
          <div className="upload-assets">Upload .CSV file</div>
          <div className="upload-btn" onClick={(e) => {
            setFile({})
            e?.stopPropagation()
            onTrigger(e)
          }}>Browse file</div>
        </div> : null
    }
    {/* uploading上传中 */}
    {
      status == 'progress' ? <div className="upload-file-progess-content">
        <div className="uploading">Uploading...</div>
        <div className="upload-progess">
          <div className="file-icon"></div>
          <div className="file-progess">
            <span className="file-name">{file.name}</span>
            <Progress h="8px" borderRadius="0" background="#EDF2F7" className="progress" hasStripe value={progressValue} />
          </div>
        </div>
      </div> : null
    }
    {/* Complete上传完成 */}
    { 
      !status && Object.keys(value)?.length ? <div className="upload-file-progess-content">
        <div className="uploading">Complete</div>
        <div className="upload-progess">
          <div className="file-icon"></div>
          <div className="file-content">
            <span className="file-name">{file.name}</span>
            <DeleteIcon className="detele-file" onClick={onDeleteFile} />
          </div>
        </div>
      </div> : null
    }
    <input ref={fileRef} accept=".csv" type="file" onChange={onFileChange} style={{ display: 'none' }} />
  </div>
})

export default CustomUpload