import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import './index.scss'
import icon1 from 'assets/img/home-icon1.svg'
import icon2 from 'assets/img/home-icon2.svg'
import androidJpg from 'assets/img/android.jpg'
import iosJpg from 'assets/img/ios.jpg'


const PolymofundAbout = () => {
  const [title] = useState('Download')
  const [paymentVoterDate] = useState(
    [
      {
        title: 'Android',
        img: androidJpg,
        content: 'We collect personal information that you provide to us, such as your name, address, zip code, phone number, location data and etc. We use this information to provide our services and features within the app.'
      },
      {
        title: 'Ios',
        img: iosJpg,
        content: 'We do not sell or rent your personal information to third parties for marketing purposes without your explicit consent. However, we may share your information with trusted third-party service providers that perform services on our behalf, such as hosting providers, analytics providers, and payment processors. These third parties are prohibited from using your personal information except to provide these services, and they are required to maintain the confidentiality of your information.'
      },
      
    ]
  )
  return (
    <div className="polyfund-home-page">
      <div className="bg-line"></div>
      <div className="polyfund-home-main">
        <div className="polyfund-home-content">
          <div className="content-title">
            <span className="name">
              <img className="icon1" src={icon1} alt="" />
              <img className="icon2" src={icon2} alt="" />
              Polymo<span className="text">AI</span></span>
          </div>

          <div style={{ textAlign: 'center' }}>
            <div style={{ fontSize: 28, fontWeight: 'bold', marginTop: 10, marginBottom: 16 }}>{title}</div>
          </div>
          <div className="downloadList">
            {
              paymentVoterDate.map((item, index) => {
                return <div key={index} className="downloadItem">
                  {
                    item.title ?
                      <div className="title">{item.title}</div> : null
                  }
                  {
                    item.img ?
                    <img className="img" src={item.img} alt="" /> : null
                  }
                </div>
              })
            }
          </div>

        </div>

      </div>
    </div>
  );
}
export default PolymofundAbout
