import React, { useState } from "react";
import {
  Flex,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { ChevronDownIcon, ArrowLeftIcon } from '@chakra-ui/icons'

import DonorDataDialog from "./components/donor-data";
import UploadDialog from './components/upload';
import { useHistory } from 'react-router-dom';
import './index.scss'

const CreateBookHome = () => {
  const history = useHistory();

  const DonorDataDialogRef = React.useRef();
  const uploadDialogRef = React.useRef();

  const onBack = () => {
    // if(window.localStorage.getItem('from') === 'switch') {
    // }
    history.go(-1)
  }
  return (
    <div className="create-book-page">
      <div className="bg-line"></div>
      <div className="create-book-main">
        <div className="create-book-content">
          <div className="content-title">
            {
              window.localStorage.getItem('from') === 'switch' ? <span className="name">Add more donors</span> : <span className="name">Create your work book</span>
            }
            
          </div>
          <div className="operate-content">
            <Menu>
              <MenuButton
                as={Button}
                background="#283A53"
                color="#fff"
                colorScheme="#283A53"
                borderRadius="8px"
                p="12px 33px"
              >
                Add donor <ChevronDownIcon />
              </MenuButton>
              <MenuList>
                <MenuItem _hover={{ background: '#EAEAEA' }} onClick={() => {
                  DonorDataDialogRef.current.show({});
                }}>From polymo's donor database</MenuItem>
                <MenuItem _hover={{ background: '#EAEAEA' }} onClick={() => {
                  uploadDialogRef.current.show({});
                }}>Upload from CSV file</MenuItem>
              </MenuList>
            </Menu>
          </div>
          <Flex mt="20px" justifyContent="center">
            <div className="go-back" onClick={onBack}>
              <ArrowLeftIcon mr="8px" cursor="pointer" w={3} h={3} />
              <span >back</span>
            </div>
          </Flex>
        </div>
      </div>
      <UploadDialog ref={uploadDialogRef}></UploadDialog>
      <DonorDataDialog ref={DonorDataDialogRef}></DonorDataDialog>
    </div>
  );
}
export default CreateBookHome
