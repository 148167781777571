
import React, { useState, useEffect } from "react";
import CustomTable from "components/table";
import Pagination from "components/pagination";
import Card from "components/card/Card";
import Dialog from "./components/add-or-edit";
import DeleteDialog from "./components/delete";
import DefaultButton from 'components/button'
import AddDialog from '../../../polymo/polymofund/create-book/components/donor-data-cms'
import {
  columns,
} from "./config";

import { getMethod, postMethod } from 'api/index';

import {
  // Flex,
  Box,
  Input,
  Text,
  Button,
  SimpleGrid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
} from "@chakra-ui/react";
const queryParams = {
  campaign_team_id: '',
}
export default function Settings() {
  const [formQuery, setFormQuery] = useState({ ...queryParams })
  const [teamList, setTeamList] = useState([])
  const [rowData, setRowData] = useState({})
  // Chakra Color Mode
  const [query, setVal] = React.useState({
    name: '',
    email: ''
  });
  const [total, setTotal] = useState(0)
  /**
   * list 数据
   */
  const [dataList, setData] = React.useState([]);
  const [pagination, setPage] = React.useState({
    currentPage: 1,
    pageSize: 10,
  });

  const [visiable, setVisiable] = useState(false);
  const dialogRef = React.useRef();
  const deleteDialogRef = React.useRef();
  const DonorDataDialogRef = React.useRef();

  useEffect(() => {
    getList(pagination, {})
  }, [pagination.currentPage])

  const getList = (pagination, params = {}) => {
    let newParams = {}
    if (Object.keys(params)) {
      for (let i in params) {
        if (params[i]) {
          newParams[i] = params[i]
        }
      }
    }
    postMethod(`/manage/account/page/${pagination.currentPage}/${pagination.pageSize}`, newParams).then((res) => {
      if (res && res.code === 200) {
        res.data.list.forEach((item) => {
          item.isExpiration = getDataStr(item.expiration_date)
        })
        setData(res.data.list)
        setTotal(res.data.total_count);
      }
    })
  }
  const handleInputChange = (e, key) => {
    setVal({
      ...query,
      [key]: e.target.value
    })
  }

  const onRefresh = () => {
    getList(pagination, query)
  }

  const queryList = (searchQuery = {}) => {
    getList({
      currentPage: 1,
      pageSize: 10,
    }, query)
  }

  const getDataStr = (n) => {
    return new Date(n).getTime() > new Date().getTime()
  }
  const onSureDelete = (row) => {
    postMethod('/manage/account/delete', { aid: row.id }).then((res) => {
      if (res && res.code === 200) {
        if (dataList.length === 1 && pagination.currentPage > 1) {
          setPage({
            ...pagination,
            currentPage: pagination.currentPage - 1
          })
          getList({
            currentPage: pagination.currentPage - 1,
            pageSize: 10
          }, query)
        } else {
          getList(pagination, query);
        }
        // getList(pagination, query)
      }
    })
  }

  /**
   * 全选单选 选中数据
  */
  const emitSelectAll = (list) => {
    // // console.log(list)
  }
  const borderRadius = "8px"

  const onClose = () => {
    setVisiable(false);
  }
  const onSure = () => {
    setVisiable(false);
    DonorDataDialogRef.current.show({...formQuery, ...rowData});
  }
  const onChangeValue = (value, key, type) => {
    setFormQuery({
      ...formQuery,
      [key]: value
    })
  }

  const openAddDonor = (row) => {
    setRowData(row)
    setVisiable(true);
    getMethod(`/manage/account/teams/${row.id}`).then((res) => {
      if (res && res.code === 200) {
        setTeamList(res.data)
      }
    })
  }
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Box textAlign="right" mb="12px">
        <Text h="32px" display="inline-block" lineHeight="32px">Account name</Text><Input w="180px" ml="12px" value={query.name} onChange={(e) => handleInputChange(e, 'name')} />
        <Text h="32px" display="inline-block" lineHeight="32px" ml="12px">Email</Text><Input w="120px" ml="12px" value={query.email} onChange={(e) => handleInputChange(e, 'email')} />
        <Button borderRadius={borderRadius} ml="8px" onClick={() => {
          queryList()
        }}>search</Button>
        <Button borderRadius={borderRadius} ml="8px" onClick={() => {
          setVal({
            name: '',
          })
          setPage({
            currentPage: 1,
            pageSize: 10,
          })
          getList({
            currentPage: 1,
            pageSize: 10,
          }, {})
        }}>reset</Button>
        <Button borderRadius={borderRadius} ml="8px" onClick={() => {
          dialogRef.current.setRow({});
        }}>add</Button>
      </Box>

      <SimpleGrid
        mb='0px'
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}>
        <Card
          mt="12px"
          borderRadius={borderRadius}
          direction='column'
          w='100%'
          px='0px'
          p="0px"
          overflowX={{ sm: "scroll", lg: "scroll" }}>
          <CustomTable
            isChecked={false}
            columns={columns({
              openDialog: (row) => {
                dialogRef.current.setRow(row);
              },
              deleteDialog: (row) => {
                deleteDialogRef.current.delete(row);
              },
              addDialog: (row) => {
                openAddDonor(row)
              }
            })}
            data={dataList}
            emitSelectAll={emitSelectAll}
          />
          <Box mt="32px" mb="30px">
            <Pagination
              paginations={pagination}
              data={dataList}
              total={total}
              onChange={(pagination) => {
                setPage(pagination)
                getList(pagination)
              }}
            ></Pagination>

          </Box>
        </Card>
      </SimpleGrid>
      <Dialog ref={dialogRef} onRefresh={onRefresh}></Dialog>
      <DeleteDialog ref={deleteDialogRef} onDelete={onSureDelete} ></DeleteDialog>
      <AddDialog ref={DonorDataDialogRef}></AddDialog>

      <Modal size='xl' isOpen={visiable} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select Team</ModalHeader>
          <ModalCloseButton _focus={{
            boxShadow: "none",
            border: "none"
          }} />
          <ModalBody>
            <Box>
              <Select h="28px" placeholder='select team' value={formQuery.campaign_team_id} onChange={(e) => {
                onChangeValue(e.target.value, 'campaign_team_id')
              }} >
                {
                  teamList?.map((item, index) => {
                    return <option value={item.id} key={index}>{item.committee_name}</option>
                  })
                }
                
              </Select>
              </Box>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} colorScheme='blue' mr={3}>
              Close
            </Button>
            <Button disabled={!formQuery.campaign_team_id} onClick={onSure} type='submit' colorScheme='teal' >Add donor</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
