import React, { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import {
  Box,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack,
  CheckboxGroup,
  Checkbox,
  Image,
  Tooltip,
  Avatar
} from "@chakra-ui/react";
import { Switch } from 'antd';

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import CustomTable from "components/table";
import Pagination from "components/pagination";
import SendDialog from "./sms";
import EmailDialog from "./email";

import { columnsDetail } from "../config";
import sendNote from 'assets/img/send-note.svg'
import PieChartCustom from './pie.jsx'
import '../index.scss'
import serviceUn from 'assets/img/account/service_un.jpeg'
import { postMethod } from 'api/index';

const DonorDetailDialog = forwardRef((props, ref) => {
  const {onRefresh} = props;
  const history = useHistory();
  const userInfo = useSelector((state) => state.userInfos)
  const [visiable, setVisiable] = useState(false);
  const [rowData, setData] = useState({})
  const [teamInfo, setTeamInfo] = useState({})
  const [page, setPage] = React.useState({
    currentPage: 1,
    pageSize: 10
  })
  const [total, setTotal] = useState(0)
  const [checkedData, setCheckedData] = useState([])
  const [change, setChange] = useState(false)

  const [dataList, setDataList] = React.useState([]);
  const [polarityList, setPolarityList] = useState([]);
  const [noteList, setNoteList] = useState([]);
  const [donorDetail, setDonorDetail] = useState({});
  const cmsDialogRef = React.useRef();
  const emailDialogRef = React.useRef();

  useImperativeHandle(ref, () => ({
    detail: (record) => {
      setData(record)
      onChangeFormValue('', 'value')
      if (window.localStorage.getItem('user_item')) {
        setTeamInfo(JSON.parse(window.localStorage.getItem('user_item')))
      }
      setSource(record.source === 'polymo');
      setPieData([])
      // 获取到行数据 取数据
      setVisiable(true);
      let data = [];
        if(record.called === 1) {
          data.push('called');
        }
        if(record.follow_up === 1) {
          data.push('follow_up');
        }
        if(record.do_not_call === 1) {
          data.push('do_not_call');
        }
        setCheckedData(data);
    }
  }))

  const pieMap = {
    'Court': 'cat_court_proportion',
    'Education': 'cat_education_proportion',
    'Governor': 'cat_governor_proportion',
    'Municipal': 'cat_municipal_proportion',
    'NC House': 'cat_nc_house_proportion',
    'NC Senate': 'cat_nc_senate_proportion',
    'Other': 'cat_other_proportion',
    'Sheriff': 'cat_sheriff_proportion'
  }
  const [pieLabels, setPieLabels] = useState(['Court', 'Education', 'Governor', 'Municipal', 'NC House', 'NC Senate', 'Other', 'Sheriff'])
  const [pieData, setPieData] = useState([])
  useEffect(() => {
    if (teamInfo.campaign_team_id && rowData.id) {
      if(rowData.source === 'polymo') {
        getNodelist();
        getdonorHistorylist(page);
      }
      getDonorDetail();
    }
  }, [teamInfo.campaign_team_id, rowData.id])

  const compare =(property) => {
    return function (a, b) {
      var value1 = a[property];
      var value2 = b[property];
      return value2 - value1;
    }
  }
 
  const getDonorDetail = () => {
    const params = {
      did: rowData.id,
      account_id: userInfo.account_id,
      campaign_team_id: teamInfo.campaign_team_id
    }
    postMethod(`/app/workbook/donor/detail`, params).then((res) => {
      if (res && res.code === 200) {
        res.data.donated = res.data.donated === 1;
        res.data.pledge = res.data.pledge === 1
        setDonorDetail(res.data)
        if (res.data.category_proportion_dict) {
          let list = []
          pieLabels?.forEach((item) => {
            list.push(res.data.category_proportion_dict[pieMap[item]])
          })
          let datas = []
          pieLabels.forEach((item, index) => {
            datas.push({
              name: item,
              value: Number(list[index])
            })
          })
          const sortDatas = datas.sort(compare("value"))
          let newKeys = [];
          let newValues = [];
          sortDatas.forEach((item) => {
            newKeys.push(item.name);
            if(item.value != 0) {
              let num = 0;
              if(item.value === 1) {
                num = item.value * 100;
              } else {
                num = item.value.toFixed(2).split('.')[1];
              }
              newValues.push(Number(num))
            } else {
              newValues.push(item.value)
            }
          })
          setPieLabels(newKeys)
          setPieData(newValues)
        }
        setPolarityList([
          {
            name: 'DEM',
            value: res.data.polarity_proportion_dict.dem_proportion * 100
          },
          {
            name: 'REP',
            value: res.data.polarity_proportion_dict.rep_proportion * 100
          },
          {
            name: 'UNA',
            value: res.data.polarity_proportion_dict.una_proportion * 100
          },
        ])
      }
    })
  }

  const getdonorHistorylist = (pagination) => {
    let params = {
      page_number: pagination.currentPage,
      page_size: pagination.pageSize,
      campaign_id: teamInfo.campaign_team_id,
      donor_name: rowData.name,
      donor_id: rowData.id,
    }
    postMethod('/app/donor/transaction/page', params).then((res) => {
      if (res && res.code === 200) {
        setDataList(res.data.list)
        setTotal(res.data.total_count)
      }
    })
  }

  const onChangeFormValue = (value, key) => {
    setFormQuery({
      ...formQuery,
      [key]: value,
    })
  }
  const onSendNote = () => {
    if (!formQuery.value) {
      return;
    }
    const params = {
      note: formQuery.value,
      campaign_team_id: teamInfo.campaign_team_id,
      campaign_team_name: teamInfo.campaign_team_name,
      donor_id: rowData.id,
      donor_name: rowData.name
    }

    // 
    postMethod(`/app/workbook/donor/notes/add`, params).then((res) => {
      if (res && res.code === 200) {
        onChangeFormValue('', 'value')
        getNodelist()
      }
    })
  }
  const getNodelist = () => {
    const params = {
      did: rowData.id,
      // account_id: userInfo.account_id,
      tid: teamInfo.campaign_team_id
    }
    postMethod(`/app/workbook/donor/notes/1/20`, params).then((res) => {
      if (res && res.code === 200) {
        setNoteList(res.data.list)
      }
    })
  }

  const [source, setSource] = useState(false)

  const [formQuery, setFormQuery] = useState({
    value: ''
  })

  const onChangeValue = (value, key) => {
    setChange(true)
    setDonorDetail({
      ...donorDetail,
      [key]: value,
    })
  }
  const onChangeCheckValue = (e, key) => {
    setChange(true)
    setCheckedData(e)
  }

  const onClose = () => {
    setData({});
    setPieData([]);
    setDonorDetail({});
    setNoteList([]);
    setDataList([]);
    setPolarityList([]);
    setChange(false);
    setTeamInfo({});
    setPage({
      currentPage: 1,
      pageSize: 10
    })
    setTotal(0);
    if(change) {
      const params = {
        "account_id": userInfo.account_id,
        "campaign_team_id": history.location.state.item.campaign_team_id,
        "did": donorDetail.id,
        "donated": donorDetail.donated ? 1 : -1,
        "pledge": donorDetail.pledge ? 1 : -1,
        "called": checkedData.includes('called') ? 1 : -1,
        "do_not_call": checkedData.includes('do_not_call') ? 1 : -1,
        "follow_up": checkedData.includes('follow_up') ? 1 : -1
      }
      postMethod('/app/workbook/donor/detail/edit', params).then((res) => {
        if(res && res.code === 200) {
          onRefresh()
          setVisiable(false);
        }
      })
    } else {
      setVisiable(false);
    }
  }

  const onSendMessage = () => {
    cmsDialogRef.current.setRow(donorDetail, teamInfo);
  }

  const onSendEmail = () => {
    emailDialogRef.current.setRow(donorDetail);
  }

  const colorList = [
    '#5096FF', '#FF7D85', '#9B8BFF', '#FFB950', '#7DFFE0', '#FF7DDB'
  ]
  const getWitdh = (item) => {
    return ((item.value / 100) * 100).toFixed(2) + '%'
  }

  const getLabelWitdh = (item) => {
    return item.name + ': ' + ((item.value / 100) * 100).toFixed(2) + '%'
  }

  return (
    <div>
      <Modal className="donor-detail-dialog" size='6xl' isOpen={visiable} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Donor details</ModalHeader>
          <ModalCloseButton _focus={{
            boxShadow: "none",
            border: "none"
          }} />
          <ModalBody>
            <div className="donor-detail-content">
              <div className="detail-left">
                <div className="detail-left-top">
                  {
                    source ?
                      <div className="detail-left-top-data">
                        <p>Name: <span>{donorDetail.name ? donorDetail.name : `${donorDetail.first_name} ${donorDetail.last_name}`}</span></p>
                        <p>Phone: <span>{donorDetail.phone_number}</span></p>
                        <p>Total amount: <span>{donorDetail.total_donation}</span></p>
                        <p>Frequency: <span>{donorDetail.frequency}</span></p>
                        <p>Average donation: <span>{donorDetail.mean_donation ? Math.round(donorDetail.mean_donation) : ''}</span></p>
                        <p>Email: <span>{donorDetail.email}</span></p>
                        <p>
                          Address:
                          <span>
                            {donorDetail.street1}
                            {donorDetail.street2 ? `, ${donorDetail.street2}` : ''}
                            {donorDetail.city ? `, ${donorDetail.city}` : ''}
                            {donorDetail.state ? `, ${donorDetail.state}` : ''}
                            {donorDetail.zip_code ? ` ${donorDetail.zip_code}` : ''}
                          </span>
                        </p>
                      </div> :
                      <div className="detail-left-top-data">
                        <p>Name: <span>{donorDetail.name ? donorDetail.name : `${donorDetail.first_name} ${donorDetail.last_name}`}</span></p>
                        <p>Phone: <span>{donorDetail.phone_number}</span></p>
                        <p>Email: <span>{donorDetail.email}</span></p>
                        <p>Address: 
                          <span>
                            {donorDetail.street1}
                            {donorDetail.street2 ? `, ${donorDetail.street2}` : ''}
                            {donorDetail.city ? `, ${donorDetail.city}` : ''}
                            {donorDetail.state ? `, ${donorDetail.state}` : ''}
                            {donorDetail.zip_code ? ` ${donorDetail.zip_code}` : ''}
                          </span>  
                        </p>
                      </div>
                  }
                  {
                    source ?
                      <div className="detail-left-top-operate custon-switch">
                        <div>
                          <span className="custon-switch-name">Donated:
                          </span><Switch size="small" checked={donorDetail.donated} onChange={(e) => onChangeValue(e, 'donated')} />
                        </div>
                        <div>
                          <span className="custon-switch-name">Pledge:
                          </span><Switch size="small" checked={donorDetail.pledge} onChange={(e) => onChangeValue(e, 'pledge')} />
                        </div>
                        <CheckboxGroup colorScheme='green' defaultValue={checkedData} onChange={(e) => onChangeCheckValue(e)}>
                          <Stack>
                            <Checkbox size="sm" value='called'>called</Checkbox>
                            <Checkbox size="sm" value='follow_up'>follow_up</Checkbox>
                            <Checkbox size="sm" value='do_not_call'>do_not_call</Checkbox>
                          </Stack>
                        </CheckboxGroup>
                       
                        <div>
                          <button 
                            disabled={!donorDetail.phone_number}
                            className={!donorDetail.phone_number ? "disabledBtn operate-send-btn": "operate-send-btn"}
                            onClick={onSendMessage}>Send Message</button>
                        </div>
                        <div>
                          <button disabled={!donorDetail.email} className={!donorDetail.email ? "disabledBtn operate-send-btn": "operate-send-btn"} onClick={onSendEmail}>Send Email</button>
                        </div>
                      </div> : null
                  }
                </div>
                <div className="detail-left-bot">
                  <div className="table-content">
                    <div className="table-title">Donor history</div>
                    {
                      source ?
                        <CustomTable
                          isChecked={false}
                          columns={columnsDetail(
                            {
                              teamInfo: teamInfo
                            }
                          )}
                          data={dataList}
                        /> : 
                        <CustomTable
                          isChecked={false}
                          columns={columnsDetail()}
                          data={dataList}
                          emptytext='service unavaliable for private donor'
                      />
                    }
                  </div>
                  <div className="donor-detail-pagination">
                    {
                      source ?
                        <Pagination
                          onChange={(pagination) => {
                            setPage(pagination)
                            getdonorHistorylist(pagination)
                          }}
                          paginations={page}
                          data={dataList}
                          total={total}
                        ></Pagination>
                        : <Pagination
                        data={[]}
                        total={0}
                      ></Pagination>
                    }
                  </div>
                </div>
              </div>
              <div className="detail-right">
                <div className="card detail-right-polarity">
                  <div className="title">Polarity</div>
                  {
                    source ? 
                    <div className="polarity-content">
                      {
                        polarityList?.map((item, index) => {
                          return <Tooltip label={getLabelWitdh(item)} key={index}>
                            <Box w={getWitdh(item)} background={colorList[index % colorList.length]}></Box>
                          </Tooltip>
                        })
                      }
                    </div> : <div className="service-unavaliable">service unavaliable for private donor</div>

                  }
                </div>
                <div className="card detail-right-category">
                  <div className="title">Category</div>
                  {
                    source ? null : 
                    <div className="service-unavaliable">
                      service unavaliable for private donor
                      {/* <img className="service-un-img" src={serviceUn} alt=""/> */}
                    </div>
                  }
                  {
                    (pieLabels.length && pieData.length && source) ? <PieChartCustom data={pieData} labels={pieLabels} /> : null
                  }
                </div>
                <div className="donor-note">
                    {
                      source 
                      ? <div>
                          <div className="add-note">
                            <Input h="40px" placeholder='Add a note...' background="#fff" value={formQuery.value} onChange={(e) => onChangeFormValue(e.target.value, 'value')} />
                            <img className="send-note-btn" src={sendNote} alt="" onClick={onSendNote} />
                          </div>
                          <div className="note-content">

                            {
                              noteList?.map((item, index) => {
                                return <div key={index} className="note-item">
                                  <div className="note-userinfo">
                                    <div className="info-pic">
                                      <div className="user-name-default">
                                        {item.creater_full_name ? item.creater_full_name.split(' ')[0].substr(0, 1) : 'f'}
                                        {item.creater_full_name ? item.creater_full_name.split(' ')[1].substr(0, 1) : 'l'}
                                      </div>
                                    </div>
                                    <div className="userinfo">
                                      <div className="name">{item.creater_name} {item.creater_full_name}</div>
                                      <div className="time">{item.create_time}</div>
                                    </div>
                                  </div>
                                  <div className="note-des">{item.note}</div>
                                </div>
                              })
                            }
                          </div> 
                        </div>
                      : 
                      <div>
                        <div className="add-note">
                          <Input h="40px" disabled placeholder='Add a note...' background="#fff" value={formQuery.value} onChange={(e) => onChangeFormValue(e.target.value, 'value')} />
                          <img className="send-note-btn" src={sendNote} alt="" />
                          
                        </div>
                        <div className="service-unavaliable">
                          service unavaliable for private donor
                        </div>
                      </div>
                    }
                  
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <SendDialog ref={cmsDialogRef}></SendDialog>
      <EmailDialog ref={emailDialogRef}></EmailDialog>

    </div>

  );
})

export default DonorDetailDialog;
