import React from "react";
import {
  // Avatar,
  // Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import {cloneDeep} from 'lodash';

import DefaultButton from 'components/button'
import { setUserInfo } from 'store/action/userInfos.js'
import { useDispatch } from 'react-redux';

import './index.scss'
import { successMessage } from '@utils/message'
import { updateUserInfo } from 'api/setting'
import { getMethod } from 'api/index'


const GeneralContent = () => {
  const userInfo = useSelector((state) => state.userInfos)
  const dispatch = useDispatch();
  // const validateName = (value) => {
  //   let error
  //   if (!value) {
  //     error = 'Name is required'
  //   }
  //   return error
  // }
  const validateConfirmEmail = (value) => {
    let error
    if (!value) {
      error = 'Email is required'
    }
    return error
  }
  const validatePhone = (value) => {
    let error
    if (!value) {
      error = 'Phone is required'
    }
    return error
  }

  const validateFirstname = (value) => {
    let error
    if (!value) {
      error = 'First name is required'
    }
    return error
  }
  const validateLastname = (value) => {
    let error
    if (!value) {
      error = 'Last name is required'
    }
    return error
  }
  return (
    <div className="general-page">
      <div className="general-name-pic">
        <div className="user-name-default">
          {userInfo.first_name ? userInfo.first_name.substr(0, 1) : 'f'}
          {userInfo.last_name ? userInfo.last_name.substr(0, 1) : 'l'}
        </div>
        <span className="name"></span>
      </div>
      {
        userInfo.uid ? 
        <Formik
          initialValues={{ 
            email: userInfo.email || '',
            phone_number: window.localStorage.getItem('general_phone') || userInfo.phone_number  || '',
            // name: userInfo.name || '',
            first_name: userInfo.first_name || '',
            last_name: userInfo.last_name || ''
          }}
          onSubmit={(values, actions) => {
            try {
              const params = {
                id: userInfo?.uid || '',
                ...values
              }
              updateUserInfo('/app/fb/user/update', params).then((res)=> {
                if(res && res.code === 200) {
                  successMessage('success');
                  getMethod(`/app/user/${userInfo?.uid || ''}`).then((res) => {
                    if(res && res.code === 200) {
                      window.localStorage.setItem('general_phone', res.data.phone_number || "")
                      // const cloneData = cloneDeep(userInfo);
                      // dispatch(setUserInfo({...cloneData.userInfos, phone_number: res.data.phone_number || ""}))
                    }
                  })
                }
              })
              actions.setSubmitting(false)
            } catch (error) {
              // console.log(error, '22')
            } finally {
            }
          }}
        >
          {(props) => (
            <Form className="general-form">
              <Flex>
                <Field name='first_name' validate={validateFirstname}>
                  {({ field, form }) => (
                    <FormControl isRequired isInvalid={form.errors.first_name && form.touched.first_name} mr="26px">
                      <FormLabel className="name">First Name</FormLabel>
                      <Input disabled className="input-h48" form="novalidateform" {...field} placeholder='First name' focusBorderColor='#3D5A80'  errorBorderColor='#E53E3E' />
                      <FormErrorMessage>{form.errors.first_name}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name='last_name' validate={validateLastname}>
                  {({ field, form }) => (
                    <FormControl isRequired isInvalid={form.errors.last_name && form.touched.last_name}>
                      <FormLabel className="name">Last Name</FormLabel>
                      <Input disabled className="input-h48" form="novalidateform" {...field} placeholder='Last name' focusBorderColor='#3D5A80'  errorBorderColor='#E53E3E' />
                      <FormErrorMessage>{form.errors.last_name}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Flex>
              <Field name='phone_number'>
                {({ field, form }) => (
                  <FormControl>
                    <FormLabel className="name">Phone</FormLabel>
                    <Input className="input-h48" form="novalidateform" {...field} placeholder='Phone' focusBorderColor='#3D5A80'  errorBorderColor='#E53E3E' />
                    {/* <FormErrorMessage>{form.errors.phone_number}</FormErrorMessage> */}
                  </FormControl>
                )}
              </Field>
              <Field name='email' validate={validateConfirmEmail}>
                {({ field, form }) => (
                  <FormControl isRequired isInvalid={form.errors.email && form.touched.email}>
                    <FormLabel className="name">Email</FormLabel>
                    <Input disabled className="input-h48" form="novalidateform" {...field} placeholder='Email' focusBorderColor='#3D5A80'  errorBorderColor='#E53E3E' />
                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field>
                {({ field, form }) => (
                  <div className="general-operate">
                    <DefaultButton
                      disabled={userInfo.phone_number === form.values.phone_number}
                      fontWeight="600"
                      width="120px"
                      name='Save Change'
                      type='submit'
                    ></DefaultButton>
                  </div>
                )}
              </Field>
            </Form>
          )}
        </Formik> : null
      }
    </div>
  );
}
export default GeneralContent