/* eslint-disable */
import {
  // Flex,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import React, { forwardRef, useImperativeHandle, useState } from "react";

const DevelopmentDeleteDialog = forwardRef((props, ref) => {
  const { onDelete } = props;
  const [visiable, setVisiable] = useState(false);
  const cancelRef = React.useRef();
  const [rowData, setData] = useState({})
  useImperativeHandle(ref, () => ({
    delete: (record) => {
      setData(record)
      setVisiable(true);
    }
  }))
  const onClose = () => {
    setVisiable(false);
  }
  const onSure = () => {
    setVisiable(false);
    // 调取删除接口然后刷新列表
    onDelete(rowData);
  }
  return (
    <Modal size='xl' isOpen={visiable} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>TruncateDelete</ModalHeader>
        <ModalCloseButton _focus={{
          boxShadow: "none",
          border: "none"
        }} />
        <ModalBody>
          <Box>
            Are you sure to truncate delete {rowData.name}'s voter?
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            _hover={{
              background: '#fff',
              border: '2px solid #5BA4FA'
            }}
            background="#fff"
            color="rgb(40, 58, 83)"
            border="1px solid rgb(40, 58, 83)"
            borderRadius="6px"
            w='109px'
            marginRight="24px"
            onClick={onClose}
            fontFamily="Inter"
          >
            Cancel
            </Button>
          <Button
            borderRadius="6px"
            _hover={{
              background: 'rgb(40, 58, 83);',
              border: '2px solid #5BA4FA'
            }}
            _click={{
              background: 'rgb(40, 58, 83);',
            }}
            background="rgb(40, 58, 83);"
            color="#fff"
            // type='submit'
            w='109px'
            fontFamily="Inter"
            onClick={onSure}
          >
            Sure
            </Button>
          {/* <Button ref={cancelRef} onClick={onClose} colorScheme='blue' mr={3}>
          Close
        </Button>
        <Button onClick={onSure} type='submit' colorScheme='teal' >Sure</Button> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
})

export default DevelopmentDeleteDialog;
