// Chakra Imports
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
	Box,
	Flex,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import AdminNavbarLinks from 'components/navbar/NavbarLinksAdmin';
import '@/assets/css/navbar.scss'
import { navigatorSource } from '@utils/navigator'

import routes from "../../routes.js";

export default function AdminNavbar(props) {
	const userInfo = useSelector((state) => state.userInfos)

	let menuBg = useColorModeValue('white', 'navy.800');
	const shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
	);
	const [scrolled, setScrolled] = useState(false);

	useEffect(() => {
		window.addEventListener('scroll', changeNavbar);
		return () => {
			window.removeEventListener('scroll', changeNavbar);
		};
	});
	let navbarPosition = 'fixed';
	let gap = '0px';
	const changeNavbar = () => {
		if (window.scrollY > 1) {
			setScrolled(true);
		} else {
			setScrolled(false);
		}
	};

	const navbarList = [
		{
			title: 'PolymoFund',
			url: '/polymo/all-teams'
		}, 
		// {
		// 	title: 'Account management',
		// 	url: '/polymo/check-team'
		// }, 
		// {
		// 	title: 'Contact us',
		// 	url: '/polymo/contact-us'
		// },
		// {
		// 	title: 'Pricing',
		// 	url: '/polymo/pricing'
		// },
	]

	const history = useHistory();
	const urlRoute = history.location.pathname;
	const itemRoute = routes.find((item) => {
		return urlRoute.includes(item.path)
	})
	const onGoDashboard = () => {
		history.push(`/polymo/`)
	}
	const onGopage = (item) => {
		history.push(item.url)
	}
	const onGoSwitchCampaign = (item, i) => {
		history.push(`/polymo/switch-campaign/${i.id}`)
	}
	const onGoPolymofund = () => {
		if(navigatorSource() === 'PC') {
			history.push('/polymo/home')
		}
	}
	return (
		<Box
			position={{ sm: 'auto', lg: navbarPosition }}
			h={{ sm: '60px', lg: '93px' }}
			background="white"
			pl={{ sm: '16px', lg: '60px' }}
			pr={{ sm: '16px', lg: '60px' }}
			className="nav-bar-t"
			zIndex="1000"
			w='100%'>
			<Flex
				w='100%'
				justifyContent="space-between"
				mb={gap}>
				<Box className="navbar-content" lineHeight={{ sm: '60px', lg: '93px' }} fontSize="24px" fontWeight="700" color='#283A53'>
					<div className="page-name" onClick={onGoPolymofund}>
						<span>Polymo</span><span style={{ color: '#FFBC0A' }}>AI</span>
					</div>
					{
						itemRoute?.showNav && window.localStorage.getItem('account')
							? navbarList?.map((item, index) => {
								return item.title === 'Switch Campaign' ?
									<Box className="navbar-item" key={index}>
										<Menu>
											<MenuButton p="0px">{item.title}</MenuButton>
											<MenuList zIndex="100" boxShadow={shadow} position="relative" top="-30px" p="0px" bg={menuBg} border="none">
												<Flex borderTop="4px solid rgb(252, 163, 17)" flexDirection="column" p="14px 10px 0px 10px">
													{
														item.children?.map((i, ind) => {
															return <MenuItem onClick={() => onGoSwitchCampaign(item, i)} key={ind} _hover={{ bg: 'rgba(233, 233, 233, 0.5)', color: 'rgb(252, 163, 17)' }} _focus={{ bg: 'none' }} px="14px">
															<Text h="31px" lineHeight="31px" fontSize="sm">{i.name}</Text>
														</MenuItem>
														})
													}
												</Flex>
											</MenuList>
										</Menu>
									</Box>
									:
									<Box _hover={{color: '#FCA311'}} onClick={() => { onGopage(item) }} className="navbar-item" key={index} >{item.title}</Box>
							})
							: null
					}
					{
						itemRoute?.showProduct
						? 
						<div style={{display: 'inline-block'}}>
							<Menu>
								<MenuButton _hover={{color: '#FCA311'}} p="0px" className="navbar-item">
									Products
								</MenuButton>
								<MenuList position="relative" top="-35px" boxShadow={shadow} p="0px" bg={menuBg} border="none">
									<Flex borderTop="4px solid rgb(252, 163, 17)" flexDirection="column" p="14px 10px 14px 10px">
										<MenuItem onClick={onGoDashboard} _hover={{ bg: 'rgba(233, 233, 233, 0.5)', color:'rgb(252, 163, 17)' }} _focus={{ bg: 'none' }} px="14px">
											<Text h="31px" lineHeight="31px" fontSize="sm">PolymoFund</Text>
										</MenuItem>
										<MenuItem onClick={onGoDashboard} _hover={{ bg: 'rgba(233, 233, 233, 0.5)', color:'rgb(252, 163, 17)' }} _focus={{ bg: 'none' }} px="14px">
											<Text h="31px" lineHeight="31px" fontSize="sm">PolymoCampaign</Text>
										</MenuItem>
									</Flex>
								</MenuList>
							</Menu>
						</div>
						// <div className="navbar-item" onClick={onGoDashboard}>Products</div>
						: null
					}
					{/* {
						window.location.href.includes('/polymo/learn-more') ? 
						<div onClick={() => { onGopage({url: '/polymo/pricing'}) }} className="navbar-item" >Pricing</div>
						: null
					} */}
					{
						!itemRoute?.commonRoute ?
						<Box _hover={{color: '#FCA311'}} onClick={() => { onGopage({url: '/polymo/contact-us'}) }} className="navbar-item" >Contact us</Box>
						: null
					}
					{
						!itemRoute?.commonRoute ?
						<Box _hover={{color: '#FCA311'}} onClick={() => { onGopage({url: '/polymo/pricing'}) }} className="navbar-item" >Pricing</Box>
						: null
					}
				</Box>
				<AdminNavbarLinks
					onOpen={props.onOpen}
					logoText={props.logoText}
					secondary={props.secondary}
					fixed={props.fixed}
					scrolled={scrolled}
				/>
			</Flex>
		</Box>
	);
}

AdminNavbar.propTypes = {
	brandText: PropTypes.string,
	variant: PropTypes.string,
	secondary: PropTypes.bool,
	fixed: PropTypes.bool,
	onOpen: PropTypes.func
};
